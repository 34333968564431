import React from "react";
import * as moment from "moment";

const PaymentStatus = ({ shipment }) => {
  if (
    shipment.payments !== undefined &&
    shipment.payments !== null &&
    shipment.payments.length
  ) {
    let paymentDate = shipment.payments[0].checkDate.slice(0, 10)
    let carrierClientID = localStorage.getItem("carrierClientId")
    return (
      <div>
        Paid!
        <br />
        {carrierClientID === "kwiktrip" ? "Reference #" : "Check #"}{" "}: {shipment.payments[0].checkNumber}
        <br />
        Date: {moment(paymentDate).format("MM/DD/YYYY")}
        <br />
        Amount: {shipment.payments[0].checkAmount}
        <br />
        <br />
      </div>
    );
  } else if (
    shipment.settlementId != undefined &&
    shipment.settlementId != null
  ) {
    return (
      <div>
        Payment Scheduled
        <br />
        <br />
      </div>
    );
  } else if (
    shipment.charges.billedCost != undefined &&
    shipment.charges.billedCost != null &&
    shipment.charges.billedCost != 0
  ) {
    return (
      <div>
        Invoice Received
        <br />
        <br />
      </div>
    );
  } else {
    return (
      <div>
        Awaiting Invoice
        <br />
        <br />
      </div>
    );
  }
};

export default PaymentStatus;
