import React from 'react'

const DDockType = ({ show, shipment }) => {

    if (show && shipment) {
        if (shipment.consignee?.dockType === 'dock') {
            return (
                <div>Business with Dock/Forklift</div>
            )
        }
        if (shipment.consignee?.dockType === 'nodock') {
            return (
                <div>Business without Dock/Forklift</div>
            )
        }
        return null
    }
    return null
}

export default DDockType