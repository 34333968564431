// ./Components/WelcomeMessages/GenricCollectionMessage.js

import Alert from "react-bootstrap/Alert";
import React from "react";

const GenricCollectionMessage = props => (
  <Alert variant="primary">
    {" "}
    <span
      onClick={props.GenricCollectionMessageClose}
      className="d-flex justify-content-end"
    >
      <i className="far fa-times-circle" />
    </span>
    <p>{props.tipDescrption}</p>
  </Alert>
);

export default GenricCollectionMessage;
