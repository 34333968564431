const axios = require('axios');

const SubmitConsolidationResponse = {

  async postConsolidation(data) {
    console.log('data', data)
    const config = {
      headers: {
        'x-api-key': 'vdhy8wwjUJrwhxFpfuECTiAW',
        'Content-Type': 'application/json'
      }
    };
    return axios.post('https://api.shippersedge.com/consolidation', data[0], config)
      .then((response) => {
        console.log('ahh', response.data);
        return response.data
      })
      .catch((error) => {
        console.log('yo error consolidating')
        console.log('response', error.response)
        console.error(error);
        if (error.response.data) {
          return error.response.data
        }
        return error
      });
  }
};

export default SubmitConsolidationResponse;
