import React, { useState } from 'react'
import * as moment from "moment";

const UnreadMessageBox = ({ shipment, modalOpenMessaging, markMessageReadOnOpen }) => {

    const [markedAsRead, setMarkedAsRead] = useState(false)


    let urMessages = shipment.log.filter((message) => {
        if (message.type === "Message" && message.read === 0) {
            return (message)
        }
    })

    let message = () => {
        if (!markedAsRead) {
            return (
                <div className="alert alert-warning d-inline-block" role="alert">
                    This shipment has unread messages.
                </div>
            )
        } else {
            return (
                <div className="alert alert-secondary d-inline-block" role="alert">
                    Messages marked as read.
                </div>
            )
        }
    }

    let markMessagesAsRead = () => {
        markMessageReadOnOpen()
        setMarkedAsRead(true)
    }

    return (
        <div className="card mt-3 mb-3 thinSolidBorder">
            <h5 className="card-header">
                Unread Messages
            </h5>
            <div className="card-body ">

                {message()}
                <div>
                    Messages ({urMessages.length}):<br />
                    <br />
                    {urMessages.map((message, index) => {
                        return (
                            <React.Fragment key={index}>
                                <div className="card">
                                    <div className="card-body">
                                        {message.entryDetails}
                                        <br />
                                        <span className="lastUpdatedDashboard">Sent: {moment(message.enteredDate).format("LL")}</span>
                                    </div>
                                </div>
                                <br />
                            </React.Fragment>)
                    })}
                </div>
                <div className="d-flex">
                    <div>
                        <button type="button" class="btn btn-outline-dark btn-sm" onClick={(event) => modalOpenMessaging(event)}>Reply</button>
                    </div>
                    <div className="ml-auto">
                        <div>
                            <button type="button" class="btn btn-outline-dark btn-sm" onClick={() => markMessagesAsRead()} >Mark all messages as read.</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UnreadMessageBox
