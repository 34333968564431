import React from "react";

const SettlementID = ({ render, shipment }) => {

  let settlementId = !!shipment.settlementId ? shipment.customerId === 'halliburton' ? 'SC0' + shipment.settlementId : shipment.settlementId : 'not yet assigned'

  if (render === "hide") {
    return null
  }
  if (render === "show" && shipment) return (
    <div>Remittance ID: {settlementId}</div>
  )
  else {
    return null;
  }
};

export default SettlementID;
