import React from "react";
import Form from "react-bootstrap/Form";
import * as moment from "moment";

const InvoiceDateFormField = ({ render, value, onChange }) => {
  if (render === "hide") {
    return null;
  }
  if (render === "show")
    return (
      <>
        <Form.Label>Invoice Date:</Form.Label>
        <Form.Control
          name="invoiceDate"
          onChange={onChange}
          type="date"
          size="sm"
          value={
            value === null || value === "0001-01-01T00:00:00"
              ? ""
              : moment(value).format("YYYY-MM-DD")
          }
        />
      </>
    );
  else {
    return null;
  }
};

export default InvoiceDateFormField;
