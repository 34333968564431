import React, { useState } from "react"
import ValidatedShipmentCard from "./ValidatedShipmentCard"
import pretendConsolidation from "./pretendConsolidation.json"
import SubmitConsolidationResponse from "../../../APIs/SubmitConsolidation"
import { getShipmentV4 } from "../../../APIs/Helpers"

const ValidatedShipmentsUI = ({
  validatedShipments,
  setMode,
  setResultingConsolidation,
  resetModeSearch,
  resultingConsolidation
}) => {

  const [consolidationStatus, setConsolidationStatus] = useState('')
  const [consolidationResultText, setConsolidationResultText] = useState('test')

  const consolidateShipments = async () => {
    setConsolidationStatus('consolidating')
    // Call the api to consolidate the shipments
    let consolidationResult = await SubmitConsolidationResponse.postConsolidation(resultingConsolidation)

    // Happy path / consolidation all goes well the response looks like this
    const testing = false

    let testHappyPath = {
      webquotecontrol: 887250551,
      result: "Created"
    }

    if (!consolidationResult) {
      setConsolidationStatus('fail')
      setConsolidationResultText('Error consolidating shipments')
      return
    }

    if (consolidationResult?.message === "The upstream server is timing out") {
      setConsolidationStatus('fail')
      setConsolidationResultText('Error (timeout) consolidating shipments, please try again.')
      return
    }

    if (consolidationResult.result === "Shipment Exists") {
      setConsolidationStatus('fail')
      setConsolidationResultText('Consolidated shipment already exists ' + consolidationResult.id)
      return
    }

    if (testing && testHappyPath.result === "Created") {
      // get the consolidated shipment object
      const consolidatedShipment = await getShipmentV4(testHappyPath.webquotecontrol)
      setResultingConsolidation(consolidatedShipment)
      setMode("consolidated")
      return
    }

    if (!testing && consolidationResult.result === "Created") {
      // get the consolidated shipment object
      const consolidatedShipment = await getShipmentV4(consolidationResult.webquotecontrol)
      setMode("consolidated")
      setResultingConsolidation(consolidatedShipment)
      return
    } else {
      // error somehow
    }
  }

  const ConsolidateStatusUI = () => {
    if (consolidationStatus === 'consolidating') {
      return (
        <div>
          <div className="spinner-border spinner-border text-primary ml-3 mt-3" role="status" >
            <span className="sr-only">Consolidating...</span>
          </div>
        </div>
      )
    }

    if (consolidationStatus === 'success') {
      return (
        <div style={{ height: '10px' }}>
          <div className="alert alert-success ml-3 mt-3" role="alert" >
            {consolidationResultText}
          </div>
        </div>
      )
    }
    if (consolidationStatus === 'fail') {
      return (
        <div>
          <div className="alert alert-danger ml-3 mt-3" role="alert" >
            {consolidationResultText}
          </div>
        </div>
      )
    }
    return null
  }

  if (!validatedShipments.length) {
    return null
  }
  if (validatedShipments.length) {
    return (
      <div>
        <h3 className="mt-4">Shipments</h3>

        {validatedShipments.map((shipment, index) => {
          return (
            // a card that represents a shipment

            <ValidatedShipmentCard shipment={shipment} key={index} setResultingConsolidation={setResultingConsolidation} />
          )
        })}

        {/* button to consolidate the shipments */}
        <div className="d-flex ">
          <div className="p-2 flex-grow-1 d-flex flex-row">
            <div>
              <button
                type="button"
                className="btn btn-primary mt-3"
                onClick={() => consolidateShipments()}
              >
                {" "}
                Consolidate
              </button>
            </div>
            <ConsolidateStatusUI />
          </div>
          <div className="p-2 ">
            <button
              type="button"
              className="btn btn-secondary mt-3"
              onClick={() => resetModeSearch()}
            >
              Reset Shipments
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default ValidatedShipmentsUI
