import React from 'react'
import Form from "react-bootstrap/Form";

const CarrierName = ({ carrierName, shipmentObjUpdateEvent, showOrHide }) => {

    if (showOrHide === "show" || showOrHide === "hilight") {
        return (

            <div className={`d-inline-block ${showOrHide}`}>
                {" "}
                Carrier Name:
                <br />
                <Form.Control
                    size="sm"
                    type="text"
                    name="carrierName"
                    value={
                        carrierName === null
                            ? ""
                            : carrierName
                    }
                    onChange={(event) => shipmentObjUpdateEvent(event)}
                    placeholder="If Different From Existing Carrier Name"
                />
            </div>

        )
    } else {
        return null
    }
}

export default CarrierName
