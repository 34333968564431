import React, { Component } from "react";
import CopyToClipboard from "../../Components/CopyToClipboard/CopyToClipboard";
import Authentication from "../../APIs/Authentication";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            errorMessage: "",
            errorDetail: ""
        };
    }

    static getDerivedStateFromError(error, errorInfo) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, errorMessage: error.toString() };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        //   logErrorToMyService(error, errorInfo);
        console.log('ERROR', error)
        console.log('ERROR 2', errorInfo.componentStack)
        this.setState({ error: error, errorDetail: errorInfo.componentStack })
    }

    RefreshPage() {
        window.location.reload();
    }

    render() {
        // console.log('ERROR 3', this.state.error)
        if (this.state.hasError) {
            let copytext = this.state.error + this.state.errorDetail
            return (
                <div className="container">
                    <div className="mt-5"><h2>The ShippersEdge Carrier Portal has encounterd an error.</h2></div>
                    <div className="mt-3">
                        Please <span className="pointer underline" style={{ color: "blue" }} onClick={this.RefreshPage}>refresh the page</span>, if the problem is not resolved with a refresh please try <span className="pointer underline" style={{ color: "blue" }} onClick={Authentication.logOut}>logging out</span>.
                        <br />
                        <br />
                        If the issue is still not resovled please copy the below text and contact support.
                    </div>
                    <div className="mt-3 d-flex alert alert-secondary" role="alert"> <div>{this.state.errorMessage} <br /> <br />{this.state.errorDetail}</div> &nbsp; <CopyToClipboard text={copytext} color={"#000000"} message={'Error Message copied to clipboard!'} /></div>

                </div >
            )
        }

        return this.props.children;
    }
}

export default ErrorBoundary