import React, { useState } from "react";

const ValidatedShipmentCard = ({
  shipment,
  setResultingConsolidation,
}) => {

  if (!shipment) {
    return null;
  }

  // This adds the billedWeight property onto our resultingConsolidation array, which is the array of shipments that we are consolidating
  const onBillWeightHandler = (e, shipmentId) => {
    const newWeight = Number(e.target.value);
    setResultingConsolidation((prevState) => {
      return prevState.map((consolidation, index) => {
        if (index === 0) {
          return { 
            ...consolidation, 
            orders: consolidation.orders.map((order) => {
              if (order.id === shipmentId) {
                return { ...order, billedWeight: newWeight };
              } else {
                return order;
              }
            })
          };
        } else {
          return consolidation;
        }
      });
    });
  };

  // const UpdateWeightSpinnerAndStatusUI = () => {
  //   if (updateWeightStatusIcon === "") {
  //     return null;
  //   }
  //   if (updateWeightStatusIcon === "spinner") {
  //     return (
  //       <div class="d-flex align-items-center">
  //         <div
  //           className="spinner-border spinner-border text-primary ml-3"
  //           role="status"
  //           style={{ width: "27px", height: "27px" }}
  //         >
  //           <span className="sr-only">Loading...</span>
  //         </div>
  //       </div>
  //     );
  //   }
  //   if (updateWeightStatusIcon === "check") {
  //     return (
  //       // div with the contents vertically and horizontally centered
  //       <div class="d-flex align-items-center">
  //         <svg
  //           xmlns="http://www.w3.org/2000/svg"
  //           width="32"
  //           height="32"
  //           fill="currentColor"
  //           class="bi bi-check-circle ml-3"
  //           viewBox="0 0 16 16"
  //         >
  //           <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
  //           <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
  //         </svg>
  //       </div>
  //     );
  //   }
  //   if (updateWeightStatusIcon === "error") {
  //     return (
  //       <div class="d-flex align-items-center">
  //         <svg
  //           xmlns="http://www.w3.org/2000/svg"
  //           width="32"
  //           height="32"
  //           fill="currentColor"
  //           class="bi bi-exclamation-octagon ml-3"
  //           viewBox="0 0 16 16"
  //         >
  //           <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1z" />
  //           <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
  //         </svg>
  //       </div>
  //     );
  //   }
  // };

  if (shipment) {
    return (
      <div className="card mt-3" key={shipment.id}>
        <div className="card-header">
          <h5 className="card-title">BOL Number: {shipment.id}</h5>
        </div>
        <div className="card-body">
          <h6 className="card-subtitle mb-2 text-muted">
            Carrier: {shipment.carrier?.name}
          </h6>
          <p className="card-text">
            Origin: {shipment.shipper.address.line1},{" "}
            {shipment.shipper.address.city}, {shipment.shipper.address.state},{" "}
            {shipment.shipper.address.zip}
          </p>
          <p className="card-text">
            Destination: {shipment.consignee?.address.line1},{" "}
            {shipment.consignee?.address.city},{" "}
            {shipment.consignee?.address?.state},{" "}
            {shipment.consignee?.address.zip}
          </p>
          {/* Input for billing weight with a submit button appended to each input */}
          <div className="input-group mb-3 w-50">
            <input
              type="text"
              className="form-control"
              placeholder="Billing Weight"
              aria-label="Billing Weight"
              aria-describedby="button-addon2"
              onChange={(e) => onBillWeightHandler(e, shipment.id)}
            />
            {/* <div className="input-group-append">
              <UpdateWeightSpinnerAndStatusUI />
            </div> */}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default ValidatedShipmentCard;
