const axios = require('axios');

const SubmitRateQuoteResponse = {
  async Respond(updateData, timeOutValue) {
    console.log('form data', updateData)
    let controller = new AbortController();
    let signal = controller.signal;
    let url =
      "https://www2.shippersedge.com/public/carrier/save_rate_request_portal.cfm?wsdl";
    let request = new Request(url, {
      method: "POST",
      signal,
      body: updateData
    });

    setTimeout(() => controller.abort(), timeOutValue);

    let fetchResponse = await fetch(request)
      .then(response => {
        // console.log("SubmitRateQuoteResponse response", response);
        if (!response.ok) {
          console.error(
            "SubmitRateQuoteResponse.js error",
            response.statusText,
            response
          );
          throw Error(response.statusText);
        }
        console.log("fetch response", response);
        // The current API response is a bunch of objects / not valid JSON and throws errors if you try to parse it, just reutrning text for now.
        return response.text();
      })
      .then(data => {
        console.error("SubmitRateQuoteResponse data", data);
        return data;
      })
      .catch(err => {
        console.error("SubmitRateQuoteResponse failed", err);
        if ((err = 'DOMException: "The operation was aborted. "')) {
          return "Network Timeout Controller Aborted";
        } else return "SubmitRateQuoteResponse Fetch Error Unknown Reason";
      });

    return fetchResponse;
  },

  async postRateRequest(updateData) {
    const config = {
      headers: {
        'x-api-key': 'vdhy8wwjUJrwhxFpfuECTiAW',
        'Content-Type': 'application/json'
      }
    };
    return axios.post('https://api.shippersedge.com/v2/rates/international', updateData, config)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.error(error);
      });
  }
};

export default SubmitRateQuoteResponse;
