import React, { useContext } from "react";
import "./ReferenceNums.css";
import { useSnackbar } from 'react-simple-snackbar'
import ReferenceNumbersCustomFields from "./ReferenceNumbersCustomFields";
import UserContext from "../../../UserDataContext";


const ReferenceNums = ({ render, shipment }) => {
  

  // set context
  const context = useContext(UserContext);
  const [openSnackbar] = useSnackbar()

  const copyToClipboard = event => {
    if (navigator.clipboard) {
      const text = event.target.innerText;
      navigator.clipboard.writeText(text);
      openSnackbar('Copied to Clipboard!')
      // event.target.innerText = "Copied to Clipboard!";
    } else {
      console.log("No navigator.clipboard");
    }
  };

  let tracking = shipment.charges.trackingNumber
  let bol = shipment.id
  let load = shipment.ucNum

  let shipperReferenceNumers = []
  let consigneeReferenceNumbers = []
  if (shipment.shipper?.referenceNumber && shipment.shipper.referenceNumber !== "") {
    shipperReferenceNumers.push(shipment.shipper.referenceNumber)
  }
  if (shipment.consignee?.referenceNumber && shipment.consignee.referenceNumber !== "") {
    consigneeReferenceNumbers.push(shipment.consignee.referenceNumber)
  }
  if (shipment.stops && shipment.stops.length) {
    shipperReferenceNumers = []
    consigneeReferenceNumbers = []
    shipment.stops.forEach((stop) => {
      if (!stop) return
      if (stop.type === 'Pickup' && stop.orders?.length) {
        stop.orders.forEach((order) => {
          if (order.referenceNumbers?.length) {
            order.referenceNumbers.forEach((refNum) => {
              shipperReferenceNumers.push(refNum)
            })
          }
        })
      } else if (stop.type === 'Delivery' && stop.orders?.length) {
        stop.orders.forEach((order) => {
          if (order.referenceNumbers?.length) {
            order.referenceNumbers.forEach((refNum) => {
              consigneeReferenceNumbers.push(refNum)
            })
          }
        })
      }
    })
  }

  // const style = {};
  if (render === "hide") {
    return null;
  }
  if (render === "show" && shipment)
    return (
      <div>
        {tracking ? (
          <>
          Tracking:{" "}
          <span
            className="linkText noExpand"
            title="Tracking number, click to copy to clipboard."
            onClick={copyToClipboard}
          >
            {tracking}
          </span>{" "}
          <br />
          </>
        ) : null}
        {context.config?.labels?.webquotecontrol ?? "BOL"}{": "}
        <span
          className="linkText noExpand"
          title="BOL number, click to copy to clipboard."
          onClick={copyToClipboard}
        >
          {shipment.customerId.includes('halliburton') ? "SC" : ""}{bol}
        </span>
        <br />
        {context.config?.labels?.usercontrol ?? "Load"}{": "}{" "}
        <span
          className="linkText noExpand"
          title="Load number, click to copy to clipboard."
          onClick={copyToClipboard}
        >
          {load}
        </span>
        {shipperReferenceNumers?.length ?
          <>
            <br />
            Ship Order:{" "}
            <span
              className="linkText noExpand"
              title="Ship Order, click to copy to clipboard."
              onClick={copyToClipboard}
            >
              {shipperReferenceNumers.join(', ')}
            </span>
          </>

          : null}
        {consigneeReferenceNumbers?.length ?
          <>
            <br />
            Cons Order:{" "}
            <span
              className="linkText noExpand"
              title="Cons Order, click to copy to clipboard."
              onClick={copyToClipboard}
            >
              {consigneeReferenceNumbers.join(', ')}
            </span>
          </>

          : null}
        <ReferenceNumbersCustomFields shipment={shipment} copyToClipboard={copyToClipboard} />
      </div>
    );
  else {
    return null;
  }
};

export default ReferenceNums;
