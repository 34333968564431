// Retries here are for bad internet connections and timeouts when updates are sent.   


export const SendDeclineShipmentEmail = async (shipment, userData) => {

    let email = {
        webquotecontrol: shipment.id,
        name: "Tender Declined Notice",
        subject: "Tender Declined by  " + shipment.carrierName + ". For Shipment: " + shipment.ucNum + ".",
        to: [
            {
                email: "Logistics@schuff.com ",
                name: "Schuff Logistics"
            }
        ],
        from: {
            email: "donoteply@shippersedge.com",
            name: "Shippers Edge"
        },
        body: "<p>Tender Declined by:  " + shipment.carrierName + "</p><p>For Shipment: <a href='https://www2.shippersedge.com/index.cfm?shipmentId=" + shipment.id + "'>" + shipment.ucNum + "</a>.</p><p>Declined by: " + userData.name + " <a href='mailto:" + userData.email + "'>" + userData.email + "</a> </p>"
    }

    let success = true

    // Submit Requests

    let firstTry = await sendUpdate(email, 10000)
    if (firstTry === "success") {
        success = true
    } else {
        let secondTry = await sendUpdate(email, 20000)
        if (secondTry.Response.statusText === "success") {
            success = true
        } else {
            let thridTry = await sendUpdate(email, 45000)
            if (thridTry.Response.statusText === "success") {
                success = true
            } else {
                console.warn("SendDeclineShipmentEmail saw an api call fail 3x")
                success = false
            }
        }
    }


    async function sendUpdate(email, timeOutValue) {

        return new Promise(function (resolve, reject) {
            let controller = new AbortController();
            var signal = controller.signal;
            let url =
                "https://api.shippersedge.com/email"

            let myHeaders = new Headers();
            myHeaders.append("content-type", "application/json");
            myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW");

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                redirect: "follow",
                signal: signal,
                body: JSON.stringify(email)
            };

            let request = new Request(url, requestOptions);

            if (timeOutValue) {
                setTimeout(() => controller.abort(), timeOutValue);
            }
            fetch(request)
                .then((response) => {
                    // console.log(
                    //   "debug SendDeclineShipmentEmail.js response",
                    //   response
                    // );
                    if (!response.ok) {
                        console.error(
                            "SendDeclineShipmentEmail.js error",
                            response.statusText,
                            response
                        );
                        throw Error(response.statusText);
                    }


                    return "success";


                })
                .then((response) => {

                    resolve(response);
                })
                .catch((err) => {
                    console.error("SendDeclineShipmentEmail API Request failed", err);
                    resolve(err);
                });
        })
    }

    return success
};

export default SendDeclineShipmentEmail