// ./APIs/PasswordRecovery.js
// Password Recovery options for Login.js

// import { promised } from "q";
import axios from "axios";

const PasswordRecovery = {
  async getPasswordRecoveryCode(email) {
    const payload = {
      email,
    };
    const config = {
      headers: {
        Authorization: "Basic am9zZXBoQHNoaXBwZXJzZWRnZS5jb206UzFsYW1tZXIh",
        "Content-Type": "application/json",
      },
    };
    try {
      return await axios
        .post(
          "https://staging.api.shippersedge.com/v2/authenticate/carrier/reset-password/",
          payload,
          config
        )
        .then((response) => {
          return response.data;
        });
    } catch (error) {
      console.error(error);
      return false;
    }
  },
  async submitPasswordRecoveryCodeAndPassword(email, code, newPassword) {
    const payload = {
      email,
      resetCode: code,
      "533a0de3": newPassword,
    };

    const config = {
      headers: {
        Authorization: "Basic am9zZXBoQHNoaXBwZXJzZWRnZS5jb206UzFsYW1tZXIh",
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.post(
        "https://staging.api.shippersedge.com/v2/authenticate/carrier/reset-password/",
        payload,
        config
      );

      return response.data;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
};

export default PasswordRecovery;
