import React from 'react'
import { Form, Col } from 'react-bootstrap'

// This component was created for Foremost Farms, it's a limited date picker for the appointment pickup date

const PUApptDateLimited = ({ render, apptPUDate, shipmentObjUpdateEvent, availableDate1, inputFieldsReadOnly }) => {
    if (!render) {
        return null
    }
    let readOnly = false
    if (!availableDate1 || inputFieldsReadOnly) {
        readOnly = true
    }
    let minDateStr = ''
    let maxDateStr = ''

    if (!readOnly) {
        const minDate = new Date(availableDate1)
        minDate.setDate(minDate.getDate() - 3)
        const maxDate = new Date(availableDate1)
        maxDate.setDate(maxDate.getDate() + 3)
        // console.log('minDate', minDate, 'maxDate', maxDate)
        minDateStr = minDate.toISOString().split('T')[0]
        maxDateStr = maxDate.toISOString().split('T')[0]
    }

    if (render && apptPUDate && shipmentObjUpdateEvent) {
        return (
            <Form.Group
                as={Col}
                xs
                lg="2"

            >
                <Form.Label>Appt. Pickup:</Form.Label>
                <Form.Control
                    name="dates.appointmentPickupDate"
                    onChange={(event) => shipmentObjUpdateEvent(event)}
                    type="date"
                    size="sm"
                    value={apptPUDate}
                    min={minDateStr}
                    max={maxDateStr}
                    readOnly={readOnly}
                />
            </Form.Group>
        )
    } else {
        return null
    }
}

export default PUApptDateLimited