import * as moment from "moment";

export const DateFormatter = date => {
  if (date === null || date === "0001-01-01T00:00:00") {
    return "";
  } else {
    date = moment(date).format("YYYY-MM-DD");
    return date;
  }
};

export default DateFormatter;
