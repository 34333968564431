// Gould wants to be sure that if there is a billed cost entered then there must be a pro/tracking number

const CheckBilledCostReqPro = {
  CheckBilledCostReqPro(carrierClientId, billedCost, trackingNumber) {
    // console.log(
    //   "CheckBilledCostReqPro",
    //   carrierClientId,
    //   billedCost,
    //   trackingNumber
    // );
    return new Promise(function(resolve, reject) {
      function check() {
        if (carrierClientId === "gould") {
          if (billedCost !== "" && trackingNumber === "") {
            resolve("needTrackingNumber");
          }
        } else {
          resolve(true);
        }
      }
      check();
    });
  }
};

export default CheckBilledCostReqPro;
