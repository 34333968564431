// Retries here are for bad internet connections and timeouts when updates are sent.   


export const SendEmail = async (shipment, userData) => {
    console.log('SendEmail shipment ', shipment, userData)

    let email = {
        webquotecontrol: shipment.id,
        name: "Pickup Request Sent",
        subject: "Pickup Request from " + shipment.customer,
        to: [
            {
                email: userData.email,
                name: userData.name
            }
        ],
        from: {
            email: "donoteply@shippersedge.com",
            name: shipment.customer
        },
        body: "<P>" + shipment.carrierName + " thank you for accepting our shipment, " + shipment.ucNum + ".</p><p>Please follow this link to the Pickup Request:</p><p>https://www2.shippersedge.com/includes/cfm/static_other.cfm?d=pickuprequest&i=" + shipment.id + "&print&wsdl</p><p>-" + shipment.customer + "</p><p>ref: " + shipment.id + "</p>"

    }

    // Foremost specifics
    //const foremost = shipment.customerId === 'foremost'
    const foremost = shipment.customerId === 'foremost'
    //console.log(foremost, shipment.loadDetails.equipment, shipment.loadDetails.temperature)
    if (shipment.loadDetails.equipment !== '' && foremost) {
        email.body += "<p>Equipment Type: " + shipment.loadDetails.equipment + "</p>"
    }

    if ((shipment.loadDetails.temperature !== '' || shipment.loadDetails.temperature !== '0') && foremost) {
        email.body += "<p>Temp: " + shipment.loadDetails.temperature + " degrees</p>"
    }
    // Set referTemp to 34 if not set
    let referTemp = ''
    if ((shipment.loadDetails.temperature !== '' && shipment.loadDetails.temperature !== '0') && foremost) {
        referTemp = shipment.loadDetails.temperature
    }
    else {
        referTemp = '34'
    }

    if (foremost && (shipment.loadDetails.equipment === 'refrigerated' || shipment.loadDetails.equipment === 'fresh' || shipment.loadDetails.equipment === 'multi-temp')) {
        email.body += "<p>Pre-cool trailers to" + referTemp + "</p>"
    }

    if (foremost && shipment.loadDetails.equipment === 'frozen') {
        email.body += "<p>Pre-cool trailers to zero degrees</p>"
    }

    if (foremost && shipment.loadDetails.equipment === 'refrigerated') {
        email.body += 
            "<p>Pickup/Delivery Requirements: Contact the shipper within 24 hrs for a pick-up appointment after a load has been accepted. Carrier must come in with a clean and odor free food grade trailer. All carriers including LTL carriers hauling FFUSA loads must have at least 1-2 load locks or straps. Load must be sealed by the shipper and removed by the customer/warehouse. AT NO TIME WILL THE TRUCK DRIVER PUT ON, REMOVE OR REPLACE AN ASSIGNED SEAL UNLESS DIRECTED BY FFUSA. Pad locks should also be used if available. Reefer trailers must be Pre-cooled to 34 degrees & Maintain Refrigeration between 33 and 38 degrees Fahrenheit and set at temp of 34 degrees unless stated differently on the Bill of Lading. Reefer unit must also be running in continuous mode from 06/01 to 10/01. Lastly, all information on the BOL must be checked and verified by the driver before leaving the shipper.</p>" +
            "<p>Food Safety laws - All carriers must comply with its legal obligations concerning the safe and secure transportation of food that will ultimately be consumed by humans or animals (the 'Goods'), including those required by local, provincial, state and federal laws, regulations, ordinances and rules including, but not limited to, the Food Safety Modernization Act (21 U.S.C. 2201, et seq.), the Federal Food, Drug and Cosmetic Act (21 U.S.C. 341, et seq.) (FD&C Act'), the Sanitary Food Transportation Act (49 U.S.C. 5701 et seq.), and the U.S. Food and Drug Administration's Final Rule on the Sanitary Transportation of Human and Animal Food (21 C.F.R. 1.900 et seq.), collectively (the 'Food Safety laws').</p>"
    }
    
    if (foremost && shipment.loadDetails.equipment === 'van') {
        email.body += 
            "<p>Pickup/Delivery Requirements: Contact the shipper within 24 hrs for a pick-up appointment after a load has been accepted. Carrier must come in with a clean and odor free food grade trailer. All carriers including LTL carriers hauling FFUSA loads must have at least 1-2 load locks or straps. Load must be sealed by the shipper and removed by the customer/warehouse. AT NO TIME WILL THE TRUCK DRIVER PUT ON, REMOVE OR REPLACE AN ASSIGNED SEAL UNLESS DIRECTED BY FFUSA. Pad locks should also be used if available. Lastly, all information on the BOL must be checked and verified by the driver before leaving the shipper.</p>" +
            "<p>Food Safety laws - All carriers must comply with its legal obligations concerning the safe and secure transportation of food that will ultimately be consumed by humans or animals (the 'Goods'), including those required by local, provincial, state and federal laws, regulations, ordinances and rules including, but not limited to, the Food Safety Modernization Act (21 U.S.C. 2201, et seq.), the Federal Food, Drug and Cosmetic Act (21 U.S.C. 341, et seq.) (FD&C Act'), the Sanitary Food Transportation Act (49 U.S.C. 5701 et seq.), and the U.S. Food and Drug Administration's Final Rule on the Sanitary Transportation of Human and Animal Food (21 C.F.R. 1.900 et seq.), collectively (the 'Food Safety laws').</p>"
    }

    let success = true

    console.log('yo i am submitting an email ', email)

    // Submit Requests

    let firstTry = await sendUpdate(email, 10000)
    console.log('SendEmail first', firstTry)
    if (firstTry === "success") {
        success = true
    } else {
        let secondTry = await sendUpdate(email, 20000)
        console.log('SendEmail second', secondTry)
        if (secondTry.Response.statusText === "success") {
            success = true
        } else {
            let thridTry = await sendUpdate(email, 45000)
            console.log('SendEmail third', thridTry)
            if (thridTry.Response.statusText === "success") {
                success = true
            } else {
                console.warn("SendEmail saw an api call fail 3x")
                success = false
            }
        }
    }


    async function sendUpdate(email, timeOutValue) {
        // console.log('SendEmail sendUpdate', email, timeOutValue)
        return new Promise(function (resolve, reject) {
            let controller = new AbortController();
            var signal = controller.signal;
            let url =
                "https://api.shippersedge.com/email"

            let myHeaders = new Headers();
            myHeaders.append("content-type", "application/json");
            myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW");

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                redirect: "follow",
                signal: signal,
                body: JSON.stringify(email)
            };

            let request = new Request(url, requestOptions);

            if (timeOutValue) {
                setTimeout(() => controller.abort(), timeOutValue);
            }
            fetch(request)
                .then((response) => {
                    // console.log(
                    //   "debug SendEmail.js response",
                    //   response
                    // );
                    if (!response.ok) {
                        console.error(
                            "SendEmail.js error",
                            response.statusText,
                            response
                        );
                        throw Error(response.statusText);
                    }


                    return "success";


                })
                .then((response) => {

                    resolve(response);
                })
                .catch((err) => {
                    console.error("SendEmail API Request failed", err);
                    resolve(err);
                });
        })
    }

    return success
};

export default SendEmail