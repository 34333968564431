

import React, { Component } from "react";


class FourOFour extends Component {
    render() {
        return (
            <div className="FourOFour">
                Page Not Found
            </div>
        )
    }
}

export default FourOFour;