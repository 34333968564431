import React, { Component } from "react";
import * as moment from "moment";
import "./PickupDelDatesTimesStatic01.css";
import DateChecker from "../../../CustLogicAndFilters/DateChecker";

class PickupDelDatesTimesStatic01 extends Component {



  constructor() {
    super();
    this.state = {};
  }

  condRenderDatesTimes() {

    //Pickup mode and appointmentpickupdate exists
    if (this.props.shipment) {
      if (
        this.props.mode === "pickup" &&
        this.props.shipment.shipper.dates.appointment &&
        DateChecker(this.props.shipment.shipper.dates.appointment) &&
        this.props.shipment.shipper.dates.appointment !== null &&
        this.props.shipment.shipper.dates.appointment !== ""
      ) {
        return (
          <span>
            <span className="appointment">Appointment:</span>
            <br />
            {moment(this.props.shipment.shipper.dates.appointment).format("MMM Do YYYY")}
            <br />

            {this.props.shipment.shipper.dates.appointmentTimeIn === null || this.props.shipment.shipper.dates.appointmentTimeIn === "00:00"
              ? ""
              : this.props.shipment.shipper.dates.appointmentTimeIn}
          </span>
        );
      }

      //Pickup mode and appointmentpickupdate does not exist, use availabledate 1 and maybe 2
      else if (this.props.mode === "pickup") {
        return (
          <span>
            {this.props.shipment.shipper.dates.available1 === null
              ? ""
              : moment(this.props.shipment.shipper.dates.available1).format("MMM Do YYYY")}
            {/* Sometimes availpickupdate 1 and 2 are the same, no need to double dispaly them, only rendering 2 if 1 and 2 are different */}
            {this.props.shipment.customerId !== 'ussalt' && 
              (
                this.props.shipment.shipper.dates.available2 === null ||
                this.props.shipment.shipper.dates.available2 === this.props.shipment.dates.availableDate1
              )
              ? ""
              : " - " + moment(this.props.shipment.shipper.dates.available2).format("MMM Do YYYY")}
            <br />
            {this.props.shipment.shipper.dates.appointmentTimeIn !== null && this.props.shipment.shipper.dates.appointmentTimeIn !== "00:00"
              ? (this.props.shipment.shipper.dates.appointmentTimeIn)
              : ""}
          </span>
        );
      }
      // 
      // 
      // 
      // 
      // 
      // 
      // 
      //Delivery mode and there is an appointment delivery date
      else if (
        this.props.mode === "delivery" &&
        this.props.shipment.consignee.dates.appointment &&
        DateChecker(this.props.shipment.consignee.dates.appointment) &&
        this.props.shipment.consignee.dates.appointment !== null &&
        this.props.shipment.consignee.dates.appointment !== ""
      ) {

        return (
          <span>
            <span className="appointment">Appointment:</span>
            <br />
            {moment(this.props.shipment.consignee.dates.appointment).format("MMM Do YYYY")}
            <br />
            {this.props.shipment.consignee.dates.appointmentTimeIn === null
              ? ""
              : this.props.shipment.consignee.dates.appointmentTimeIn}
          </span>
        );
      }
      else if (
        this.props.mode === "delivery" &&
        DateChecker(this.props.shipment.consignee.dates.requestedDeliveryDate) &&
        this.props.shipment.consignee.dates.requestedDeliveryDate !== null &&
        this.props.shipment.consignee.dates.requestedDeliveryDate !== ""
      ) {
        return (
          <span>
            {moment(this.props.shipment.consignee.dates.requestedDeliveryDate).format("MMM Do YYYY")}
          </span>
        );
        // Schuff uses a custom field for some (not all) consolidation "delivery by" dates, accounting for that here.
      } else if (
        this.props.mode === "delivery" &&
        (localStorage.getItem("carrierId").includes("schuff") || localStorage.getItem("carrierId").includes("graywolf")) &&
        this.props.customFields.filter(
          (customFieldObj) => customFieldObj.fieldId === 117
        )
      ) {
        return this.props.customFields
          .filter((customFieldObj) => customFieldObj.fieldId === 117)
          .map((customFieldObj) => {
            return (
              <span>{moment(customFieldObj.value).format("MMM Do YYYY")}</span>
            );
          });
      }
      //Delivery mode and there is not an appointment delivery date try just deliveryDate
      // else if (
      //   this.props.mode === "delivery" &&
      //   DateChecker(this.props.shipment.dates.deliveryDate) &&
      //   this.props.shipment.dates.deliveryDate !== null &&
      //   this.props.shipment.dates.deliveryDate !== ""
      // ) {
      //   return (

      //     <span>{moment(this.props.shipment.dates.deliveryDate).format("MMM Do YYYY")}  HERE1</span>
      //   );

      // } 
      // Delivery mode destAvailableDate1 and 2
      else if (
        this.props.mode === "delivery" &&
        DateChecker(this.props.shipment.consignee.dates.available1) &&
        this.props.shipment.consignee.dates.available1 !== null &&
        this.props.shipment.consignee.dates.available1 !== ""
      ) {
        return (
          <>
            <span>{moment(this.props.shipment.consignee.dates.available1).format("MMM Do YYYY")}</span><br />
            {moment(this.props.shipment.consignee.dates.available1).format("MMM Do YYYY") !== moment(this.props.shipment.consignee.dates.available2).format("MMM Do YYYY") ? <span>{moment(this.props.shipment.consignee.dates.available2).format("MMM Do YYYY")} HERE2</span> : null}
          </>
        )
      } else {
        return null;
      }
    }
  }

  render() {

    return <span>{this.condRenderDatesTimes()}</span>;
  }
}
export default PickupDelDatesTimesStatic01;
