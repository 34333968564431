import React from 'react'

const Arrow = ({ shipment }) => {
    if (shipment) {
        return (
            <div>
                {shipment.stops && shipment.stops.length > 2 ? (
                    <div>
                        <div>
                            <i className="cr-icon fa fa-arrow-right" />{" "}
                            <i className="fas fa-map-marker-alt" />{" "}
                            <i className="fas fa-map-marker-alt" />{" "}
                            <i className="cr-icon fa fa-arrow-right" />{" "}
                        </div>
                        <div>Multiple Stops</div>
                    </div>
                ) : (
                    <span>
                        <i className="cr-icon fa fa-arrow-right" />
                    </span>
                )}
            </div>
        )
    } else {
        return null
    }
}

export default Arrow
