// Helper functions that I might use multiple places

export const arrayObjDeDuper = arrArg => {
  return arrArg.filter((elem, pos, arr) => {
    return arr.indexOf(elem) === pos;
  });
};

export const basicDateTimeValidation = (formData, shipment, puTimeIn, puOutTime, delInTime, delOutTime) => {
  // console.log('shipment', shipment)

  // Get Dates
  let today = new Date()
  let pudate
  let deldate
  for (var pair of formData.entries()) {
    if (pair[0] === 'pudate') {
      pudate = pair[1];
      pudate = new Date(pudate + 'T00:00:00')
    }



    if (pair[0] === 'deldate') {
      deldate = pair[1];
      deldate = new Date(deldate + 'T00:00:00')
    }
  }

  // console.log('pudate', pudate)
  // console.log('deldate', deldate)

  // Set pudate's puInTime if it exists
  if (pudate && puTimeIn) {
    console.log('pudate', pudate)
    console.log('puInTime', puTimeIn)
    // // break up puInTime into hours minutes and seconds
    let puInTimeSplit = puTimeIn.split(':')
    let puInTimeHours = puInTimeSplit[0]
    let puInTimeMinutes = puInTimeSplit[1]

    pudate.setHours(puInTimeHours, puInTimeMinutes)
  }
  // Set delInTime if it exists
  if (deldate && delInTime) {
    console.log('deldate', deldate)
    console.log('delInTime', delInTime)
    // break up delInTime into hours minutes and seconds
    let delInTimeSplit = delInTime.split(':')
    let delInTimeHours = delInTimeSplit[0]
    let delInTimeMinutes = delInTimeSplit[1]
    deldate.setHours(delInTimeHours, delInTimeMinutes)
  }

  // if pudate is after deldate
  if (pudate && deldate && pudate.getTime() > deldate.getTime()) {
    return {
      validation: false,
      reason: 'Actual delivery date entered is before pickup date.'
    }
  }

  // if pudate is before today
  if (pudate && pudate.getTime() > today.getTime()) {
    return {
      validation: false,
      reason: 'Actual pickup date is in the future.'
    }
  }

  // if deldate is before today
  if (deldate && deldate.getTime() > today.getTime()) {
    return {
      validation: false,
      reason: 'Actual delivery date is in the future.'
    }
  }




  return {
    validation: true,
    reason: ''
  }
}

export const getShipmentV4 = async (shipmentId) => {
  var myHeaders = new Headers();
  myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW");
  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  let shipment = await fetch("https://api.shippersedge.com/v4/shipment/" + shipmentId + '/?include=all,documents', requestOptions)
    .then(response => response.text())
    .then(result => {
      let ship = JSON.parse(result)
      return ship

    })
    .catch(error => {
      console.log('error', error)
      return null
    });
  return shipment
}

export const getShipmentV4IncludesWebQSearch = async (shipmentId, includes, carrierId) => {
  var myHeaders = new Headers();
  myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW");
  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  let url = 'https://api.shippersedge.com/v4/shipment/?include=' + includes + '&carrier=' + carrierId + '&category1=m.webquotecontrol&operator1=equals&search11=' + shipmentId

  // console.log('Consolidation', url)

  let shipment = await fetch(url, requestOptions)
    .then(response => response.text())
    .then(result => {
      let ship = JSON.parse(result)
      return ship

    })
    .catch(error => {
      console.log('error', error)
      return null
    });
  return shipment
}


export const returnValid_YYYYMMDD_DateOrEmptyString = (date) => {
  if (!date) {
    return '';
  }
  const moment = require('moment');
  const formattedDate = moment(date);
  return formattedDate.isValid() ? formattedDate.format("YYYY-MM-DD") : '';
}