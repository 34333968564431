// ./Components/WelcomeMessages/DashboardWelcomeTaskViewMessage.js

import Alert from "react-bootstrap/Alert";
import React from "react";

const MultiCarrierWelcomeMessage = props => (
  <Alert variant="primary">
    {" "}
    <span
      onClick={props.multiCarrierWelcomeShowHideAction}
      className="d-flex justify-content-end"
    >
      <i className="far fa-times-circle" data-cy="multi-carrier-alert"/>
    </span>
    <p>
        It looks like your account is connected to more than one carrier on the Carrier Portal.
    </p>
    <p>
        You can switch between carriers by clicking on the drop down menu next to the logo at the top of this page.
    </p>
    <p>
      If you have any questions please contact support@shippersedge.com and we
      will be happy to help.
    </p>
  </Alert>
);

export default MultiCarrierWelcomeMessage;
