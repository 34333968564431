import React from 'react'

const LoggingIn = ({ loginstatus }) => {
    if (loginstatus === 'loggingin') {
        return (
            <div className="mt-4 d-flex align-items-center">
                <div className="spinner-border text-dark" role="status" /> &nbsp; Logging In...
            </div>
        )
    } else {
        return null
    }
}

export default LoggingIn