// ./Components/ProfileModal.js

import React, { Component } from "react";
import Authentication from "../APIs/Authentication";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

class ProfileModal extends Component {
  constructor() {
    super();
    this.handleChangeGeneric = this.handleChangeGeneric.bind(this);
    this.submitProfileUpdate = this.submitProfileUpdate.bind(this);
    this.state = { stats: "" };
  }

  componentDidMount() {
    // Authentication.isLoggedIn();
    this.setState({
      name: Authentication.getName(),
      emailAddress: Authentication.getEmailAddress(),
      phone: Authentication.getPhone(),
    });
    if (navigator.connection) {
      this.setState({ stats: navigator.connection });
    }
  }

  componentDidUpdate() {
    // Profile modal actually mounts inside the dashboard with the dashboard so setting the authetnication check on update (modal updates when opened)
    Authentication.isLoggedIn();
  }

  handleChangeGeneric(event) {
    event.preventDefault();
    this.setState({ [event.target.name]: event.target.value });
    // console.log(event.target.name);
    // console.log(event.target.value);
  }

  submitProfileUpdate(event) {
    event.preventDefault();
    const profileUpdate = new FormData();
    profileUpdate.append("id", Authentication.getID());
    profileUpdate.append("name", this.state.name);
    profileUpdate.append("email", this.state.emailAddress);
    profileUpdate.append("phone", this.state.phone);
    if (this.state.password !== undefined) {
      // console.log("profileModal.js, password found setting profileUpdate with state")
      profileUpdate.append("password", this.state.password);
    } else {
      // console.log("profileModal.js, no password found setting profileUpdate empty")
      profileUpdate.append("password", "");
    }
    Authentication.profileUpdate(profileUpdate); // call back check
  }



  render() {
    const currentVersion = localStorage.getItem("version");
    return (
      <div>
        <Modal {...this.props} size="lg">
          <Form onSubmit={this.submitProfileUpdate}>
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                Profile
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {/* <h4>{this.state.name}</h4> */}
              <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  name="name"
                  size="sm"
                  type="text"
                  value={this.state.name}
                  onChange={this.handleChangeGeneric}
                />
              </Form.Group>
              <Form.Group controlId="phone">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  name="phone"
                  size="sm"
                  type="text"
                  value={this.state.phone}
                  onChange={this.handleChangeGeneric}
                />
              </Form.Group>
              <Form.Group controlId="emailAddress">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  name="emailAddress"
                  size="sm"
                  type="email"
                  value={this.state.emailAddress}
                  onChange={this.handleChangeGeneric}
                />
              </Form.Group>
              {/* <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  name="password"
                  size="sm"
                  type="password"
                  placeholder="Password"
                  onChange={this.handleChangeGeneric}
                />
              </Form.Group> */}
              <span style={{ fontSize: "10px" }}>
                {currentVersion}
                {this.state.stats === "" ? null : (
                  <span>
                    , Speed: {this.state.stats.downlink}, RTT:{" "}
                    {this.state.stats.rtt}
                  </span>
                )}
              </span>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="outline-primary"
                className="allBtn"
                onClick={this.props.onHide}
              >
                Close
              </Button>
              {/* <Button variant="outline-success" type="submit">
                {" "}
                Save{" "}
              </Button> */}
              <Button
                variant="outline-danger"
                value="Submit"
                onClick={Authentication.logOut}
              >
                Logout
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default ProfileModal;
