import CheckPortalVersion from "../../APIs/CheckPortalVersion";
import history from "../../APIs/History";

export const VersionCheck = async () => {
  const version = await CheckPortalVersion.Check("CarrierPortal");
    const currentVersion = localStorage.getItem("version");
    if (!currentVersion) {
      localStorage.setItem("version", version);
    } else {
      if (currentVersion !== version) {
        localStorage.setItem(
          "forceLogoutReason",
          "Your login session has expired, please login again."
        );
        localStorage.clear();
        history.push("/Login");
      }
    }

  return null
};

export default VersionCheck;
