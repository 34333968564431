import React from 'react'

const ReferenceNumbersCustomFields = ({ shipment, copyToClipboard }) => {
    // Add custom field ids here to quickly add customer custom fields to the reference numbers section
    const customFieldsToDisplay = [212,10319]

    // console.log('shipment ReferenceNumbersCustomFields', shipment)
    let render = false

    if (shipment.customFields) {
        shipment.customFields.forEach(customField => {
            if (customFieldsToDisplay.includes(customField.fieldId)) {
                render = true
            }
        })
    }

    let usercode = shipment.customerId

    if (render) {
        return (
            <div>
                {shipment.customFields.map((customField, index) => {
                    if (usercode === "aurora" && customField.fieldId === 10319) {
                        return (
                            <React.Fragment key={index} >
                                Customer #: <span className="linkText noExpand" title="Click to copy to clipboard." onClick={copyToClipboard}>{customField.value}</span><br />
                            </React.Fragment>
                        )
                    } else {
                        if (customFieldsToDisplay.includes(customField.fieldId)) {
                            return (
                                <React.Fragment key={index} >
                                    Ref {index - 1}: <span className="linkText noExpand" title="Click to copy to clipboard." onClick={copyToClipboard}>{customField.value}</span><br />
                                </React.Fragment>
                            )
                        }
                    }
                })}
            </div>
        )
    } else {
        return null
    }
}

export default ReferenceNumbersCustomFields