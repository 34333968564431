import React, { useState } from "react"
import pretendConsolidation from "./pretendConsolidation.json"
import history from "../../../APIs/History"
import UploadFileUI from "./UploadFileUI"

const ConsolidatedShipment = ({ resultingConsolidation, setMode, setValidatedShipments, onHide }) => {
  let shipment = resultingConsolidation

  // if (!shipment) {
  //   // Manually set the shipment for development
  //   shipment = pretendConsolidation
  //   // return <p>Loading</p>
  // }

  // <li>Shipment info for consolidated shipment</li>
  // <li>Upload docs</li>
  // <li>Set total invoice amount</li>
  // <li>Done button to go back</li>

  const [billedCost, setBilledCost] = useState('')
  const [submitBilledCostStatus, setSubmitBilledCostStatus] = useState('')


  if (!shipment) return null

  // const {
  //   id,
  //   status,
  //   shipper,
  //   consignee,
  //   details: { totalHandlingUnits, totalPieces },
  //   stops,
  // } = shipment







  // function ShipmentCardX({ id, totalHandlingUnits, totalPieces, stops }) {
  //   return (
  //     <div className="card mt-3">
  //       <div className="card-header">
  //         <h5 className="card-title">BOL # {id}</h5>
  //       </div>
  //       <div className="card-body">
  //         <p className="card-text">
  //           {totalHandlingUnits ? <span><strong>Total Handling Units:</strong> {totalHandlingUnits}</span> : null}

  //         </p>
  //         <p className="card-text">
  //           <strong>Total Pieces:</strong> {totalPieces}
  //         </p>
  //         <StopsList stops={stops} />
  //       </div>
  //     </div>
  //   )
  // }


  const ShipmentCard = ({ shipment }) => {
    if (!shipment) return null

    return (
      <div className="card mt-3">
        <div className="card-header">
          <h5 className="card-title">BOL # {shipment.id}</h5>
        </div>
        <div className="card-body">
          <p className="card-text">
            {shipment?.details?.totalHandlingUnits ? <span><strong>Total Handling Units:</strong> {shipment.details.totalHandlingUnits}</span> : null}

          </p>
          <p className="card-text">
            {shipment?.details?.totalPieces ? <span><strong>Total Pieces:</strong> {shipment.details.totalPieces}</span> : null}

          </p>
          {shipment?.stops ? <StopsList stops={shipment.stops} /> : null}

        </div>
      </div>
    )
  }


  function StopsList({ stops }) {
    if (!stops || stops.length === 0) return <span>no stops</span>

    return (
      <div>
        <h6 className="mt-3">Stops:</h6>
        <ul className="list-group">
          {stops.map((stop) => (
            <StopItem key={stop.stopNumber} stop={stop} />
          ))}
        </ul>
      </div>
    )
  }

  function StopItem({ stop }) {
    return (
      <li className="list-group-item">
        <strong>Stop {stop.stopNumber}:</strong> {stop.type} - {stop.name}
        <ul>
          <li>
            Address: {stop.address.line1}, {stop.address.city},{" "}
            {stop.address.state} {stop.address.zip}
          </li>
          <li>Contact: {stop.contact.name}</li>
        </ul>
      </li>
    )
  }

  function submitBilledCost() {

    if (billedCost === '') {
      alert('Please enter a total invoice amount.')
      return
    }
    // amount has to be numeric
    if (isNaN(billedCost)) {
      alert('Total invoice amount must be numeric.')
      return
    }

    if (!parseInt(billedCost)) {
      alert('Total invoice amount must be greater than 0.')
      return
    }

    let shipmentObjectUpdatedBilledCost = {
      id: shipment.id,
      billing: {
        cost: {
          billed: {
            total: parseInt(billedCost),
          }
        }
      }
    }

    // PUT to https://api.shippersedge.com/v4/shipment/ + shipment.id with an x-api-key vdhy8wwjUJrwhxFpfuECTiAW header and a json body  of shipmentObjectUpdatedBilledCost


    fetch('https://api.shippersedge.com/v4/shipment/' + shipment.id, {
      method: 'PUT',
      headers: {
        'x-api-key': 'vdhy8wwjUJrwhxFpfuECTiAW',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(shipmentObjectUpdatedBilledCost)
    })
      .then(response => response.json())
      .then(data => {
        setSubmitBilledCostStatus('success')
        setTimeout(() => {
          setSubmitBilledCostStatus('')
        }, 10000)

      })
      .catch((error) => {
        console.error('Error:', error);
        setSubmitBilledCostStatus('fail')
        setTimeout(() => {
          setSubmitBilledCostStatus('')
        }, 10000)
      });

    return


  }

  const SubmitBilledCostStatusUI = () => {
    if (submitBilledCostStatus === 'updating') {
      return (
        <div className="spinner-border spinner-border text-primary ml-3" role="status" >
          <span className="sr-only">Updating...</span>
        </div>
      )
    }
    if (submitBilledCostStatus === 'success') {
      return (

        <div className="alert alert-success m-0 p-1 ml-3" role="alert" >
          Total invoice amount successfully updated.
        </div>

      )
    }
    if (submitBilledCostStatus === 'fail') {
      return (

        <div className="alert alert-danger m-0 p-1 ml-3" role="alert" >
          Error updating total invoice amount.
        </div>

      )
    }


    return null

  }


  function NavigationButtons() {
    return (
      <div className="d-flex ">
        <div className="p-2 flex-grow-1">

          <button
            type="button"
            className="btn btn-primary mt-3"
            onClick={() => {
              setMode("search")
              setValidatedShipments([])
            }}
          >
            Consolidate Another Shipment
          </button>
        </div>
        <div className="p-2">
          <button
            type="button"
            className="btn btn-secondary mt-3"
            onClick={() => {
              onHide()

            }}
          >
            Done
          </button>
        </div>
      </div>
    )
  }

  return (
    <>
      <ShipmentCard
        shipment={shipment}

      />
      <UploadFileUI shipment={shipment} />
      <div className="d-flex flex-row">
        <div className="input-group mt-3 w-50">
          <input
            type="text"
            className="form-control"
            placeholder="Total Invoice Amount"
            aria-label="Total Invoice Amount"
            aria-describedby="button-addon2"
            onChange={(e) => setBilledCost(e.target.value)}
            value={billedCost}
          />
          <div className="input-group-append">
            <button
              className="btn btn-primary"
              type="button"
              id="button-addon2"
              onClick={(e) => submitBilledCost(e)}
            >
              Submit
            </button>

          </div>
        </div>

        {/* horizontal and vertical centered div */}
        <div className="d-flex align-items-center mt-3">
          <SubmitBilledCostStatusUI />
        </div>

      </div>
      <NavigationButtons />
    </>
  )
}

export default ConsolidatedShipment
