import React, { useState, useEffect } from 'react'
import UploadFile from '../../../APIs/UploadFile'
import Authentication from '../../../APIs/Authentication'
import { getShipmentV4 } from '../../../APIs/Helpers'



export const UploadFileUI = ({ shipment }) => {


  const [fileType, setFileType] = useState('')
  const [documentUploadFile, setDocumentUploadFile] = useState(null)
  const [uploadBtnDisabled, setUploadBtnDisabled] = useState(true)
  const [existingFiles, setExistingFiles] = useState([])
  const [refreshingExistingFiles, setRefreshingExistingFiles] = useState(false)
  const [uploadStatus, setUploadStatus] = useState('')

  // function that utilizes the UploadFile API

  // if documentUploadFile && fileType !== '' then setUploadBtnDisabled(false)

  useEffect(() => {
    if (documentUploadFile && fileType !== '') {
      setUploadBtnDisabled(false)
    } else {
      setUploadBtnDisabled(true)
    }
  }, [documentUploadFile, fileType])

  useEffect(() => {
    if (shipment?.docs?.length > 0) {
      setExistingFiles(shipment.docs)
    }
  }, [shipment])


  const reset = () => {
    setFileType('')
    setDocumentUploadFile(null)
    setUploadBtnDisabled(true)
  }


  const handleFileChange = (e) => {
    // Check if files were selected
    if (e.target.files.length > 0) {
      setDocumentUploadFile(e.target.files[0]);

      // Clear the input value to allow selecting the same file again
      e.target.value = null;
    } else {
      // If no files were selected, reset the state
      setDocumentUploadFile(null);
    }
  };



  const formSubmitUploadFile = async () => {
    setUploadStatus('uploading')
    // Build form data
    const uploadData = new FormData();
    uploadData.append("shipmentId", shipment.id.toString());
    uploadData.append("fileType", fileType);
    uploadData.append(
      "file",
      documentUploadFile,
      fileType
    );
    uploadData.append("contactEmail", Authentication.getEmailAddress());

    // // UploadFile API
    const response = await UploadFile.UploadNew(uploadData, 10000)
    if (response === 'Aborted') {
      setUploadStatus('fail')
      reset()
    } else {
      setUploadStatus('success')
      refreshDocs()
      reset()
    }
  }
  const UploadStatus = () => {
    if (uploadStatus === 'uploading') {
      return (
        <div className="spinner-border spinner-border text-primary ml-3 mt-3" role="status" style={{ width: '20px', height: '20px' }}>
          <span className="sr-only">Uploading...</span>
        </div>
      )
    }

    if (uploadStatus === 'success') {
      return (
        <div className="alert alert-success mt-3" role="alert" style={{ width: '170px' }}>
          Upload Success
        </div>
      )
    }
    if (uploadStatus === 'fail') {
      return (
        <div className="alert alert-danger mt-3" role="alert" style={{ width: '170px' }}>
          Upload Failed
        </div>
      )
    }
    return null
  }

  const refreshDocs = async () => {
    setRefreshingExistingFiles(true)
    const shipmentWithDocs = await getShipmentV4(shipment.id)
    setExistingFiles(shipmentWithDocs.docs)
    setRefreshingExistingFiles(false)
  }




  return (
    <div className='mt-3 card p-4'>


      {/* UI for uploading a single file one at a time and can set the document type with a drop down select named fileType */}
      <form onSubmit={(e) => {
        e.preventDefault()
        formSubmitUploadFile()
      }}>
        <div className="d-flex flew-row ">
          <div className="form-group">
            <label htmlFor="fileType">Select Document Type:</label>
            <select className="form-control" id="fileType" onChange={(e) => setFileType(e.target.value)}>
              <option value=''>Select a document type</option>
              <option value="b3">B3</option>
              <option value="BOL">BOL</option>
              <option value="invoice">Invoice</option>
              <option value="Signed-BOL"> Signed Bill of Lading</option>
              <option value="Signed-BOLandInvoice">Signed BOL &amp; Invoice</option>
            </select>
          </div>
          <div>
            <div className="form-group bg-light border border-dark p-2 rounded ml-3 mt-4" style={{ cursor: 'pointer' }}>
              <label htmlFor="documentUploadFile" style={{ cursor: 'pointer' }}>Add File
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-file-earmark-plus ml-3" viewBox="0 0 16 16">
                <path d="M8 6.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 .5-.5" />
                <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z" />
              </svg> */}

              </label>


              <input type="file" className="form-control-file" id="documentUploadFile" onChange={handleFileChange} />
            </div>
          </div>
          {existingFiles?.length > 0 ? (
            <div className='ml-3' style={{ maxWidth: '300px' }}>
              <span className='mt-3'>Documents ({existingFiles.length})</span>


              <div className='d-flex flex-wrap'>
                {refreshingExistingFiles ?

                  <div className="spinner-border spinner-border text-primary ml-3 mt-3" role="status" style={{ width: '20px', height: '20px' }}>
                    <span className="sr-only">Uploading...</span>
                  </div>
                  : <>
                    {existingFiles.map((file) => (
                      <div key={file.id} className="border border-dark rounded p-2 ml-2 mt-1">
                        <a href={file.url} target='_blank' rel="noopener noreferrer">{file.name}.{file.extension}</a>
                      </div>
                    ))}

                  </>}
              </div>
            </div>
          ) : null}




        </div>
        <button type="submit" className="btn btn-primary" disabled={uploadBtnDisabled}>
          Upload
        </button>
        {/* reset button */}
        <button type="button" className="btn btn-secondary ml-3" onClick={() => reset()}>
          Cancel
        </button>
        <UploadStatus />

      </form>
    </div>
  )
}

export default UploadFileUI