// ./Components/RateRequestCard/RateRequestCard.js
// Writing this using the React Hooks, plan transition to hooks throughout the application.
// For testing to return a quote from the API: check the rates_history table and set rate_declined to N OR carrier_finalprice to null & in masterquotes set statusedi to "AwaitingQuotes"

import React, { useState, useEffect, useContext } from "react";
import Input01 from "../../SmallComponents/Input01";
import Input03 from "../../SmallComponents/Input03";
import SubmitQuoteResponse from "../../APIs/SubmitRateQuoteResponse";
import PickupDelDatesTimesStatic01 from "../ShipmentCard/Components/PickupDelDatesTimesStatic01_RR";
import GuaranteedDeliveryIcon from "../ShipmentCard/Components/GuaranteedDeliveryIcon";
import RateRequestSubmitAndStatus from "./Components/RateRequestSubmitAndStatus";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import Details from "./Components/Details";
import HandlingUnits from "./Components/HandlingUnits";
import Stops from "./Components/Stops";
import Arrow from "./Components/Arrow"
import ODockType from "../ShipmentCard/Components/ODockType";
import DDockType from "../ShipmentCard/Components/DDockType";
import UserContext from "../../UserDataContext";


const RateRequestCard = (props) => {

  const { data, showFields } = props;

  const userData = useContext(UserContext);

  const [billedCost, setBilledCost] = React.useState("");
  const [fuelSurcharge, setFuelSurcharge] = React.useState("");
  const [taxes, setTaxes] = React.useState("");
  const [transitDays, setTransitDays] = React.useState("");
  const [name, setName] = React.useState(userData.name);
  const [phone, setPhone] = React.useState(userData.phone);
  const [email, setEmail] = React.useState(userData.email);
  const [rateRequestID, setRateRequestID] = React.useState("");
  const [secureID, setSecureId] = React.useState("");
  const [alertStatus, setAlertStatus] = React.useState("hide");
  const [renderForm, setRenderForm] = React.useState(false);
  const [rateExpiresDate, setRateExpiresDate] = React.useState("");
  const [oceanFreight, setOceanFreight] = useState("");
  const [drayage, setDrayage] = useState("");
  const [rail, setRail] = useState("");
  const [waitingTime, setWaitingTime] = useState("");
  const [transitTime, setTransitTime] = useState("");
  const [effectiveDate, setEffectiveDate] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [accessorialTotalForOcean, setAccessorialTotalForOcean] = useState(0);
  const [notes, setNotes] = useState("");
  const [emailError, setEmailError] = useState("");
  const [touched, setTouched] = useState(false);

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    if (!validateEmail(value)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };

  const handleEmailBlur = () => {
    setTouched(true);
  };


  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);


  // console.log('RateRequestCard', data)
  const style = {
    cursor: "pointer",
  };

  //Card header onClick
  const headerClick = () => {
    if (renderForm) {
      setRenderForm(false);
    } else {
      setRenderForm(true);
    }
  };

  const checkEmptyString = (number) => {
    if (number === "" || isNaN(number)) {
      return 0;
    } else {
      return parseFloat(number);
    }
  };


  // console.log('expiresdate ', props.showFields.includes("rateexpiresdate"))


  // Accessorials moved to state and hook + change handler for the input fields
  // Because the fields aren't static (the number of accessorials is unknown) it has to be handled differently than a static input

  let accessorials = [];
  if (data.details.accessorials) {
    const accessorialDetailsMode = true
    if (accessorialDetailsMode) {
      if (data.details?.accessorialDetails) {
        accessorials = data.details.accessorialDetails
      }
    } else {
      accessorials = data.details.accessorials
    }
  }
  const [accessorialState, setAccessorialState] = useState(accessorials);

  const handleAccessorialChange = (e) => {
    const updatedAccessorials = [...accessorialState];
    updatedAccessorials[e.target.dataset.idx][e.target.name] = e.target.value;
    setAccessorialState(updatedAccessorials);
  };




  //Add up all the accessorial costs
  const accessorialTotal = (accessorialState) => {
    let accessorialTotalVal = 0;
    accessorialState.forEach((accessorialItem) => {
      if (isNaN(parseFloat(accessorialItem.price))) {
      } else {
        accessorialTotalVal =
          parseFloat(accessorialItem.price) + accessorialTotalVal;
      }
    });
    return accessorialTotalVal;
  };

  const changeBilledCost = (value) => {
    setBilledCost(value);
    if (data.client.id.includes('halliburton')) {
      calculateFuelSurcharge(value, data.details.miles, data.rateRequestDetails.brokerCarrierId, data.consignee.address.state, data.details.totalWeight)
    }
  }

  const calculateFuelSurcharge = (total, miles, brokerCarrierId, state, billableWeight) => {
    const url = "https://api.shippersedge.com/v2/rates/fuelSurcharge";
    const key = "vdhy8wwjUJrwhxFpfuECTiAW";
    const body = {
      "total": total,
      "date": "2021-06-01",
      "carrierId": brokerCarrierId,
      "miles": miles,
      "billableWeight": billableWeight,
      "state": state
    }

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': key
      },
      body: JSON.stringify(body)
    };
    fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => {
        // console.log('FuelSurcharge', data)
        setFuelSurcharge(data.amount)
      });
  }


  // Total Cost
  let totalCost = (
    checkEmptyString(billedCost) +
    checkEmptyString(fuelSurcharge) +
    accessorialTotal(accessorialState) +
    checkEmptyString(taxes)
  ).toFixed(2);

  let totalOceanCost = (
    checkEmptyString(oceanFreight) +
    checkEmptyString(drayage) +
    checkEmptyString(rail) +
    checkEmptyString(accessorialTotalForOcean)
  ).toFixed(2);

  //Shipment updated Alert
  // const shipmentUpdatedAlert = () => {
  //   setAlertStatus("loading");
  //   setTimeout(() => setAlertStatus("show"), 2000);
  // };

  const buildFormData = (submitRateOrDeclineShipment) => {
    if (submitRateOrDeclineShipment === "declineShipment") {
      // console.log("RateRequestCard.js, fn respondToRateRequest, DECLINED!!!");
      const updateData = new FormData();
      updateData.append("rq", rateRequestID);
      updateData.append("secid", secureID);
      updateData.append("decline", "yes");
      // Debug FormData
      // console.log("RateRequest Decline FormData:");
      // for (var pair of updateData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }
      return updateData;
    } else if (isOceanInt) {
      const updateData = {};
      // Charges
      updateData.oceanFreight = oceanFreight;
      updateData.drayage = drayage;
      updateData.rail = rail;
      updateData.waitingTime = waitingTime;
      updateData.transitTime = transitTime;
      updateData.effectiveDate = effectiveDate;
      updateData.expirationDate = expirationDate;
      updateData.totalOceanCost = totalOceanCost;

      // Accessorials
      updateData.accessorialCharges = accessorialTotalForOcean

      // Completed By Input Data
      updateData.name = name;
      updateData.phone = phone;
      updateData.email = email;
      updateData.notes = notes;
      // shipment data
      updateData.rateHistId = rateRequestID;
      updateData.laneId = data.lane.id;
      updateData.carId = data.rateRequestDetails.carid;
      updateData.service_type = data.international.type;
      updateData.container_type = data.international.container;
      updateData.incoterms = data.international.incoterms;

      return updateData;
    } else {
      const updateData = new FormData();
      updateData.append("rq", rateRequestID);
      updateData.append("secid", secureID);
      updateData.append("basecost", billedCost);
      updateData.append("fuelsurcharge", fuelSurcharge);
      updateData.append("taxes", taxes);
      updateData.append("totalcost", totalCost);
      updateData.append("transtime", transitDays);
      updateData.append("tenderacceptance_name", name);
      updateData.append("tenderacceptance_phone", phone);
      updateData.append("tenderacceptance_email", email);
      if (rateExpiresDate && rateExpiresDate !== "") {
        updateData.append("rateexpiresdate", rateExpiresDate);
      }
      // Add accessorials to formData
      // updateData.append("accessorial"+ 6, 11); <-- For reference
      if (accessorialState.length) {
        accessorialState.forEach((accessorialItem) => {
          if (isNaN(parseFloat(accessorialItem.price))) {
          } else {
            updateData.append(
              "accessorial" + accessorialItem.id,
              accessorialItem.price
            );
          }
        });
      }
      // Debug FormData
      // console.log("RateRequest Accept FormData:");
      // for (var pair of updateData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }

      return updateData;
    }
  };

  useEffect(() => {
    setRateRequestID(props.data.rateRequestDetails.rateRequestId);
  }, [props.data.rateRequestDetails]);
  useEffect(() => {
    setSecureId(props.data.rateRequestDetails.rateRequestBrokerSecid);
  }, [props.data.rateRequestDetails]);

  // Set rateexpiresdate default to 1 year from today
  useEffect(() => {
    // DO NOT CALCULATE FUEL FOR KWIKTRIP PER MEGHAN 8/8/23 (this could be useful for future clients)
    if (data.client.id.includes('halliburton')) {
      calculateFuelSurcharge(data.details.totalCost, data.details.miles, data.rateRequestDetails.brokerCarrierId, data.consignee.address.state, data.details.totalWeight)
    }
    if (showFields.includes("rateexpiresdate")) {
      const today = new Date();
      const oneYearFromToday = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());
      setRateExpiresDate(oneYearFromToday.toISOString().split('T')[0]);
    }
  }, []);

  const copyToClipboard = (event) => {
    if (navigator.clipboard) {
      const text = event.target.innerText;
      navigator.clipboard.writeText(text);
      // event.target.innerText = "Copied to Clipboard!";
    } else {
      console.warn("No navigator.clipboard")
    }
  };
  const isOceanInt = data.details.mode.includes('INT_OCEAN')
  let isFormValid = true;
  if (isOceanInt) {
    isFormValid = oceanFreight !== "" && effectiveDate !== "" && expirationDate !== "" && email !== "" && phone !== "";
  }

  const serviceType = {
    'pp': 'Port to Port',
    'pd': 'Port to Door',
    'dp': 'Door to Port',
    'dd': 'Door to Door',
  }
  return (
    <div className="rate-quote-cards">
      <div className="card" style={style}>
        <div className="card-header" onClick={headerClick}>
          <div className="row">
            <div className="col-sm-2">
              {data.ucNum ?
                <>
                  <span className="font-weight-bold">{userData.config?.labels?.usercontrol ?? "Load"}:</span>
                  <br />
                  {data.ucNum}
                  <br />
                </>
                : null
              }

              <span className="font-weight-bold">{userData.config?.labels?.webquotecontrol ?? "BOL"}:</span>
              <br />
              <span
                className="linkText"
                title="BOL number, click to copy to clipboard."
                onClick={copyToClipboard}
              >
                {data.id}
              </span>
              <br />
              {isOceanInt && (
                <>
                  <span className="font-weight-bold">Service Type:</span> <br />
                  {data.international?.type ? serviceType[data.international.type] : null}
                  <br />
                  <span className="font-weight-bold">Container Type:</span> <br />
                  {data.international?.container ? data.international.container : null}
                </>
              )}

            </div>
            <div className="col-sm-3">
              <span className="font-weight-bold">Inital Pickup:</span> <br />
              {data.shipper.name !== "" ? data.shipper.name : null}
              {data.shipper.name !== null ? <br /> : null}
              {data.shipper.address.line1 !== ""
                ? data.shipper.address.line1
                : null}
              {data.shipper.address.line1 !== null ? <br /> : null}
              {data.shipper.address.line2 !== ""
                ? data.shipper.address.line2
                : null}
              {data.shipper.address.line2 !== null ? <br /> : null}
              {data.shipper.address.city}, {data.shipper.address.state}{" "}
              {data.shipper.address.zip}<br /><br />
              {isOceanInt && (
                <>
                  <span className="font-weight-bold">Origin Port:</span>
                  <br />
                  {data.international?.originPort?.port ? data.international.originPort?.port : null}
                  <br />
                  <span className="font-weight-bold">Des Port:</span>
                  <br />
                  {data.international?.destinationPort?.port ? data.international?.destinationPort?.port : null}
                </>
              )}
              <br />
              <ODockType show={showFields.includes("ODockType")} shipment={data} />
              {/* <span className="font-weight-bold"> */}
              <PickupDelDatesTimesStatic01
                mode={"pickup"}
                shipment={data} />
              {/* </span> */}

            </div>
            <div className="col-sm-2 textAlignCenter">
              <Arrow shipment={data} />
            </div>
            <div className="col-sm-3">
              <span className="font-weight-bold">Final Delivery:</span>
              <br />
              {data.consignee.name !== "" ? data.consignee.name : null}
              {data.consignee.name !== null ? <br /> : null}
              {data.consignee.address.line1 !== ""
                ? data.consignee.address.line1
                : null}
              {data.consignee.address.line1 !== null ? <br /> : null}
              {data.consignee.address.line2 !== ""
                ? data.consignee.address.line2
                : null}
              {data.consignee.address.line2 !== null ? <br /> : null}
              {data.consignee.address.city}, {data.consignee.address.state}{" "}
              {data.consignee.address.zip}<br /><br />
              {isOceanInt && (<>
                <span className="font-weight-bold">Incoterms:</span> <br />
                {data.international?.incoterms ? data.international.incoterms : null} <br />
              </>)}
              <br />
              <DDockType show={showFields.includes("DDockType")} shipment={data} />
              <PickupDelDatesTimesStatic01
                mode={"delivery"}
                shipment={data}

              />
              {showFields.includes("guaranteedDelivery") ? (
                <GuaranteedDeliveryIcon
                  render="show"
                  guaranteed={data.details.guaranteed}
                />
              ) : null}
              {/* </span> */}
            </div>
            <div className="col-sm-2">
              {/* <span className="font-weight-bold">Load Details:</span>
              <br /> */}
              <Details data={data} />

            </div>
          </div>
          {/* Notes Row */}
          {data.shipper.bolNotes || data.consignee.bolNotes ? (
            <>
              <div className="row">
                <div className="col-sm-3">
                  <span className="font-weight-bold">Notes:</span>
                </div>
                <div className="col-sm-9"> {data.shipper.bolNotes} {data.consignee.bolNotes}</div>
              </div>
              <br />
            </>
          ) : null}

        </div>
        {/* FORM */}
        {!renderForm ? (
          <span className="condRenderDetailBodyAndFormText">
            Click the shipment to see more details / submit rates.
          </span>
        ) : (
          <div className="card-body">

            {/* Stops Row */}
            <div className="row mb-3">
              <div className="col-sm-12">
                <Stops shipment={data} />
              </div>
            </div>
            {/* Dimensions Row */}
            <div className="row mb-3">
              <div className="col-sm-12">
                <HandlingUnits shipment={data} />
              </div>
              <div className="col-sm-6">

                {/* <Items shipment={data} /> */}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <hr />
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="col-sm-3">
                <span className="font-weight-bold"> Charges:</span>
                <br />
                {!isOceanInt &&
                  <>
                    {(data.client.id === 'kwiktrip') ? "All-In Rate" : "Base Charge"}:{" "}
                    <Input01
                      name="billedCost"
                      showHide="show"
                      symbol="$"
                      type="number"
                      step="0.01"
                      size="small"
                      min="0"
                      placeholder="0.00"
                      value={billedCost}
                      onChange={(e) => changeBilledCost(e.target.value)}
                    />
                    {(data.client.id === 'kwiktrip') ? "" : "Fuel Surcharge:"}{" "}
                    <Input01
                      name="fuelSurcharge"
                      showHide={(data.client.id === 'kwiktrip') ? "hide" : "show"}
                      symbol="$"
                      type="number"
                      step="0.01"
                      min="0"
                      placeholder="0.00"
                      size="small"
                      value={fuelSurcharge}
                      readOnly={(data.client.id === 'kwiktrip' || data.client.id.includes('halliburton')) ? true : false}
                      onChange={(e) => setFuelSurcharge(e.target.value)}
                    />
                    {(data.client.id === 'kwiktrip' || data.client.id.includes('halliburton')) ? "" : "Taxes:"}{" "}
                    <Input01
                      name="taxes"
                      showHide={(data.client.id === 'kwiktrip' || data.client.id.includes('halliburton')) ? "hide" : "show"}
                      symbol="$"
                      type="number"
                      step="0.01"
                      min="0"
                      placeholder="0.00"
                      size="small"
                      value={taxes}
                      onChange={(e) => setTaxes(e.target.value)}
                    />
                    {(data.client.id === 'kwiktrip') ? "" : "Transit Days:"}{" "}
                    <Input01
                      name="transitDays"
                      showHide={(data.client.id === 'kwiktrip') ? "hide" : "show"}
                      type="number"
                      step="1"
                      size="small"
                      value={transitDays}
                      onChange={(e) => setTransitDays(e.target.value)}
                    />
                  </>
                }
                {isOceanInt && (
                  <>
                    Ocean Freight<span style={{ color: "red" }}>*</span>:
                    <Input01
                      name="oceanFreight"
                      showHide={"show"}
                      symbol="$"
                      type="number"
                      step="1"
                      size="small"
                      value={oceanFreight}
                      isRequired={true}
                      onChange={(e) => setOceanFreight(e.target.value)}
                    />
                    Drayage:
                    <Input01
                      name="drayage"
                      showHide={"show"}
                      symbol="$"
                      type="number"
                      step="1"
                      size="small"
                      value={drayage}
                      onChange={(e) => setDrayage(e.target.value)}
                    />
                    Rail:
                    <Input01
                      name="rail"
                      showHide={"show"}
                      symbol="$"
                      type="number"
                      step="1"
                      size="small"
                      value={rail}
                      onChange={(e) => setRail(e.target.value)}
                    />
                    Waiting Time:
                    <Input01
                      name="waitingTime"
                      showHide={"show"}
                      type="number"
                      placeholder="0 hours"
                      step="1"
                      size="small"
                      value={waitingTime}
                      onChange={(e) => setWaitingTime(e.target.value)}
                    />
                    Transit Time:
                    <Input01
                      name="transitTime"
                      showHide={"show"}
                      type="number"
                      placeholder="0 hours"
                      step="1"
                      size="small"
                      value={transitTime}
                      onChange={(e) => setTransitTime(e.target.value)}
                    />
                    Effective Date<span style={{ color: "red" }}>*</span>:
                    <Input01
                      name="effectiveDate"
                      showHide={"show"}
                      type="date"
                      step="1"
                      size="small"
                      value={effectiveDate}
                      isRequired={true}
                      onChange={(e) => setEffectiveDate(e.target.value)}
                    />
                    Expiration Date<span style={{ color: "red" }}>*</span>:
                    <Input01
                      name="expirationDate"
                      showHide={"show"}
                      type="date"
                      step="1"
                      size="small"
                      value={expirationDate}
                      isRequired={true}
                      onChange={(e) => setExpirationDate(e.target.value)}
                    />
                  </>
                )}
                {showFields.includes("rateexpiresdate") ? (
                  <div>
                    Rate Expires On:{" "}
                    <Input01
                      name="rateexpiresdate"
                      showHide="show"
                      type="date"
                      size="small"
                      value={rateExpiresDate}

                      onChange={(e) => {
                        // console.log('e.target.value', e.target.value)
                        setRateExpiresDate(e.target.value)
                      }
                      }
                    />
                  </div>) : null}
                Total Charge: ${!isOceanInt ? totalCost : totalOceanCost}
              </div>


              {data.client.id !== 'kwiktrip' && !isOceanInt && accessorialState.length ? (
                <div className="col-sm-3">
                  <span className="font-weight-bold"> Accessorials:</span>
                  <br />
                  {accessorialState.map((val, idx) => {
                    return (
                      <span key={`accessorial-${idx}`}>
                        {val.formDisplay}
                        <Input03
                          key={`accessorial-${idx}`}
                          idx={idx}
                          accessorialState={accessorialState}
                          handleAccessorialChange={handleAccessorialChange}
                          accessorialName={val.accessorialName}
                          symbol="$"
                        />
                      </span>
                    );
                  })}
                </div>
              ) : null}
              {isOceanInt && (
                <div className="col-sm-3">
                  <span className="font-weight-bold">Accessorials:</span>
                  <br />
                  Accessorial Total:
                  <Input01
                    name="accessorialTotal"
                    showHide="show"
                    symbol="$"
                    type="number"
                    size="small"
                    value={accessorialTotalForOcean}
                    onChange={(e) => setAccessorialTotalForOcean(e.target.value)}
                  />
                  <span className="font-weight-bold">Notes:</span>
                  <br />
                  Carrier Notes:
                  <Input01
                    name="notes"
                    showHide="show"
                    type="text"
                    size="small"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                  {/* 
                  Handling:
                   <Input01
                    name="handling"
                    showHide="show"
                    symbol="$"
                    type="number"
                    size="small"
                    value={phone}
                    onChange={(e) => console.log(e.target.value)}
                  />
                  Pre-Pull:
                  <Input01
                    name="prePull"
                    showHide="show"
                    symbol="$"
                    type="number"
                    size="small"
                    value={phone}
                    onChange={(e) => console.log(e.target.value)}
                  />
                  Rail Free Time:
                  <Input01
                    name="railFreeTime"
                    showHide="show"
                    type="number"
                    placeholder="0 hours"
                    size="small"
                    value={phone}
                    onChange={(e) => setRailFreeTime(e.target.value)}
                  />
                  Container Free Time:
                  <Input01
                    name="containerFreeTime"
                    showHide="show"
                    type="number"
                    placeholder="0 hours"
                    size="small"
                    value={phone}
                    onChange={(e) => setContainerFreeTime(e.target.value)}
                  /> */}
                </div>
              )}

              <div className="col-sm-3">
                <span className="font-weight-bold"> Completed By:</span>
                <br />
                Name<span style={{ color: "red" }}>*</span>:
                <Input01
                  name="name"
                  showHide="show"
                  type="text"
                  extraclassName="input-group-sm"
                  size="small"
                  value={name}
                  isRequired={true}
                  onChange={(e) => setName(e.target.value)}
                />
                Phone<span style={{ color: "red" }}>*</span>:
                <Input01
                  name="phone"
                  showHide="show"
                  type="tel"
                  extraclassName="input-group-sm"
                  size="small"
                  value={phone}
                  isRequired={true}
                  onChange={(e) => setPhone(e.target.value)}
                  onBlur={(e) => setPhone(e.target.value)}
                />
                <>
                  E-mail<span style={{ color: "red" }}>*</span>:
                  <Input01
                    name="email"
                    showHide="show"
                    type="email"
                    extraclassName="input-group-sm"
                    size="small"
                    value={email}
                    isRequired={true}
                    onChange={handleEmailChange}
                    onBlur={handleEmailBlur}
                  />
                  <div style={{ height: !touched && !emailError ? "2em" : "0" }} />
                  {touched && emailError && (
                    <div style={{ color: "red" }}>{emailError}</div>
                  )}
                </>
                <RateRequestSubmitAndStatus
                  Action={"submitRate"}
                  SubmitQuoteResponse={SubmitQuoteResponse}
                  buildFormData={buildFormData}
                  isOceanInt={isOceanInt}
                  isDisabled={!isFormValid}
                />
                <RateRequestSubmitAndStatus
                  Action={"declineShipment"}
                  SubmitQuoteResponse={SubmitQuoteResponse}
                  buildFormData={buildFormData}
                  isOceanInt={isOceanInt}
                />
                {isOceanInt && (
                  <>
                    <Modal show={showModal} onHide={handleCloseModal}>
                      <Modal.Header closeButton>
                        <Modal.Title>Quote Requirements</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <ul>
                          <li>Equipment type: 40'HQ</li>
                          <li>Incoterm: FCA On Board Vessel</li>
                          <li>Montreal, Brampton, Calgary and Vancouver are all 24-hour chassis drop and Belleville is 4 hours’ free unload</li>
                          <li>Please list all the accessorials/additional charges (i.e. GRI, PSS, EIS, local port/rail surcharges) in the notes and specify if it is included in ocean freight</li>
                          <li>Any charges prior to "onboard" are to the account of the seller and need not be included. (i.e. E-Man/ACI to the account of shipper)</li>
                          <li>We do not pay handling fees from Asia and South East Asia</li>
                          <li>Fuel must be included in drayage and ocean freight</li>
                          <li>All Canadian services should be quoted in Canadian funds and if converted to USD please advise the exchange rate</li>
                          <li>Our payment will be issued based on the quotation and no additional charges will be accepted once the rate is confirmed unless previous approved in writing by SCC</li>
                          <li>Please noted the service provider will be excluded from the bid list if they do not complete the entire above matrix</li>
                          <li>We will not accept emails/phone calls until full submission has been received</li>
                        </ul>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    <button className="btn btn-outline-info" style={{ marginBottom: '10px' }} onClick={handleShowModal}>
                      Quote Requirements
                    </button>
                  </>
                )}
              </div>
              {data?.rateRequestDetails?.brokerRemarks && data.rateRequestDetails.brokerRemarks !== "" ?
                <div className="col-sm-6">
                  <div class="card">
                    <div class="card-header">
                      <strong>Important Notes</strong>
                    </div>
                    <div class="card-body">
                      <p class="card-text">{data.rateRequestDetails.brokerRemarks}</p>
                    </div>
                  </div>
                </div>
                : null}

            </div>
          </div>
        )}
      </div>
      <br />
    </div>
  );
};

export default RateRequestCard;
