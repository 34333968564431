import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'

const FSCHistory = () => {

    const [fschistoryArray, setFschistoryArray] = useState([])
    const [fscType, setFscType] = useState(null)
    const [show, setShow] = useState(false)

    useEffect(() => {
        const carrierid = localStorage.getItem('carrierId');

        if (carrierid) {
            const url = `https://api.shippersedge.com/carrierportal/fschistory/${carrierid}`
            let xapikey = 'vdhy8wwjUJrwhxFpfuECTiAW'
            fetch(url, {
                method: 'GET',
                headers: {
                    'x-api-key': xapikey
                }
            })
                .then(response => response.json())
                .then(data => {
                    setFscType(data.fscType)
                    setFschistoryArray(data.fscHistory)
                })
        }
    }, [])

    // on click of FSCHistory button, open FSCHistoryModal

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const openFSCHistory = () => {
        if (setFschistoryArray.length === 0) {
            return
        }
        handleShow()
    }

    const pointerAndLinkStyle = {
        cursor: 'pointer',
        color: 'blue',
        textDecoration: 'underline'
    }

    if (fschistoryArray.length === 0) {
        return null
    } else {
        return (

            <>
                <div>
                    <span style={pointerAndLinkStyle} onClick={() => handleShow()}>Historical FSC</span>
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Fuel Surcharge History</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Price</th>
                                        <th>FSC</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fschistoryArray.map((item, index) => {
                                        const humanReadableDate = new Date(item.date).toLocaleDateString()
                                        return (

                                            <tr key={index}>

                                                <td>{humanReadableDate}</td>
                                                <td>{item.price}</td>
                                                <td>{fscType === 'CPM' ? '$' : ''}{item.fuel_surcharge}{fscType === 'POR' ? '%' : ''}</td>
                                            </tr>

                                        )
                                    })}
                                </tbody>
                            </table>

                        </Modal.Body>
                    </Modal>
                </div>

            </>
        )
    }
}

export default FSCHistory