import React, { useEffect, useState } from "react"
import { getShipmentV4IncludesWebQSearch } from "../../APIs/Helpers"
import ValidatedShipmentsUI from "./Components/ValidatedShipmentsUI"
import Authentication from "../../APIs/Authentication"
import history from "../../APIs/History"
import ConsolidatedShipment from "./Components/ConsolidatedShipment"
import Modal from "react-bootstrap/Modal"
const ConsolidationModal = ({ show, onHide }) => {
  // default start is 'search'
  const [mode, setMode] = useState("search")
  // Setting '215296915,564034389' for development, otherwise bolNumbersInput would be just an empty string
  const [bolNumbersInput, setBolNumbersInput] = useState('')
  const [validationErrorMsg, setValidationErrorMsg] = useState("")
  const [validationBtnSpinnerRunning, setValidationBtnSpinnerRunning] =
    useState(false)
  const [validatedShipments, setValidatedShipments] = useState([])
  const [resultingConsolidation, setResultingConsolidation] = useState([])


  // first time this loads resetModeSearch if mode is not 'search'
  useEffect(() => {
    if (mode !== "search") {
      resetModeSearch()
    }
  }, [])

  Authentication.isLoggedIn()
  // If you have searched for a consolidation and then click the back button, it will reset the mode to 'search'
  useEffect(() => {
    setValidatedShipments([])
  }, [show, onHide])

  const validateBolNumbers = async (e) => {
    e.preventDefault()
    Authentication.isLoggedIn()
    setValidationErrorMsg("")
    setValidationBtnSpinnerRunning(true)
    setValidatedShipments([])

    // Check if bolNumbersInput is empty
    if (bolNumbersInput === "") {
      setValidationErrorMsg("Please enter at least one BOL number")
      return
    }
    // Check if bolNumbersInput does not contain a comma
    if (!bolNumbersInput.includes(",")) {
      setValidationErrorMsg(
        "Please enter at least two BOL numbers separated by a comma"
      )
      return
    }
    // Remove spaces from bolNumbersInput and split bolNumbersInput into an array of strings
    const bolNumbersArray = bolNumbersInput.replace(/\s/g, "").split(",")


    // Validate if bolNumbersArray contains any duplicates and error if it does
    const bolNumbersArrayUnique = [...new Set(bolNumbersArray)]
    if (bolNumbersArrayUnique.length !== bolNumbersArray.length) {
      setValidationErrorMsg("Please remove any duplicate BOL numbers")
      return
    }

    // itrate through bolNumbersArray and call await getShipmentV4Includes for each bolNumber
    const carrierId = localStorage.getItem("carrierId")
    let shipments = []

    for (const bolNumber of bolNumbersArray) {
      const searchReturn = await getShipmentV4IncludesWebQSearch(
        bolNumber,
        "all",
        carrierId
      )


      // if searchReturn.data array has any object that contains a message property that includes "timeout", then set validationErrorMsg and return
      if (searchReturn?.data?.some(obj => obj.message?.includes("timeout"))) {
        setValidationErrorMsg(
          `Timeout validating BOL number(s): ${bolNumber}. Please try again.`
        )
        return
      }

      if (!searchReturn) {
        setValidationErrorMsg(
          `Error validating BOL number: ${bolNumber}. Please enter valid BOL numbers.`
        )
        return
      }

      if (searchReturn.metadata.total === 0) {
        setValidationErrorMsg(
          `Invalid BOL number: ${bolNumber}.   This may be because the BOL number is not in the system, or the shipment is not assigned to you.`
        )
        return
      }

      if (searchReturn?.message?.includes("timeout")) {
        setValidationErrorMsg(
          `Timeout validating BOL number: ${bolNumber}. Please enter valid BOL numbers.`
        )
        return
      }

      if (searchReturn.data[0].details?.stopOrder?.length) {
        setValidationErrorMsg(
          `BOL number: ${bolNumber} is already a consolidation.`
        )
        return
      }

      shipments.push(searchReturn.data[0])
    }
    setValidationBtnSpinnerRunning(false)
    if (validationErrorMsg !== "") {
      return
    }
    setValidatedShipments(shipments)
    const orders = shipments.map(shipment => ({ id: shipment.id }))
    setResultingConsolidation([{ orders, carrierId: shipments[0].carrier.id }])
  }

  const resetModeSearch = () => {
    setMode("search")
    setValidatedShipments([])
    setValidationErrorMsg("")
    setValidationBtnSpinnerRunning(false)
    setBolNumbersInput("")
  }

  const ValidationUI = () => {
    if (!validationErrorMsg) {
      return null
    }
    return (
      <div className="alert alert-danger mt-3" role="alert">
        {validationErrorMsg}
      </div>
    )
  }

  const ValidateBtnSpinnerUI = () => {
    if (!validationBtnSpinnerRunning || validationErrorMsg) {
      return null
    }

    return (
      <div
        className="spinner-border spinner-border text-primary ml-3"
        role="status"
        style={{ width: "27px", height: "27px" }}
      >
        <span className="sr-only">Loading...</span>
      </div>
    )
  }

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Consolidate Shipments</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(() => {
          switch (mode) {
            case "search":
              return (
                <>
                  <form>
                    <div className="form-group">
                      <label htmlFor="consolidation">Consolidation</label>
                      <input
                        type="text"
                        name="bolNumbersInput"
                        className="form-control"
                        id="consolidation"
                        placeholder="Enter BOL numbers"
                        onChange={(e) => setBolNumbersInput(e.target.value)}
                        value={bolNumbersInput}
                      />
                      <small
                        id="consolidationHelp"
                        className="form-text text-muted"
                      >
                        Enter Bill of Lading (BOL) numbers separated by commas.
                        Example: 1234,2356,3467
                      </small>
                    </div>
                    <div className="d-flex bd-highlight">
                      <div className="p-2 flex-grow-1">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={(e) => validateBolNumbers(e)}
                        >
                          Validate
                        </button>
                        <ValidateBtnSpinnerUI />
                      </div>
                      <div className="p-2 "></div>
                    </div>
                  </form>
                  <ValidationUI />
                  <ValidatedShipmentsUI
                    validatedShipments={validatedShipments}
                    setMode={setMode}
                    setResultingConsolidation={setResultingConsolidation}
                    resultingConsolidation={resultingConsolidation}
                    resetModeSearch={resetModeSearch}
                  />


                </>
              )
            case "consolidated":
              return (
                <ConsolidatedShipment
                  resultingConsolidation={resultingConsolidation}
                  setMode={setMode}
                  setValidatedShipments={setValidatedShipments}
                  onHide={onHide}
                />
              )
            default:
              return null
          }
        })()}
      </Modal.Body>
    </Modal>
  )
}

export default ConsolidationModal
