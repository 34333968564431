// ./APIs/UpdateStatus.js

import axios from "axios";
import { promised } from "q";

const UpdateStatus = {
  UpdateStatus(updateData) {
    return new Promise(function(resolve, reject) {
      // console.log("UpdateStatus.js, fired");
      axios
        .post(
          "https://www2.shippersedge.com/public/carrier/updateStatusV2.cfm?wsdl",
          updateData
        )
        .then(response => {
          console.log("debug UpdateStatus.js, response:", response);
          resolve(response);
        })
        .catch(error => {
          if (error) {
            reject("UpdateStatus.js, error:", error);
          }
        });
      return promised;
    });
  }
};

export default UpdateStatus;
