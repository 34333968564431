import React, { useState } from 'react'

const Items = ({ shipment }) => {
    const [showAllItems, setShowAllItems] = useState(true)
    const [itemCount, setItemCount] = useState(0)
    const showHideItems = () => {
        if (!showAllItems) {
            setShowAllItems(true)
        } else {
            setShowAllItems(false)
        }
    }



    if (shipment && shipment.handlingUnits && shipment.handlingUnits.length) {

        return (
            <div>
                {/* <button
                    className="mt-2 mb-2"
                    onClick={() => { showHideItems() }}>
                    Show Items
                </button> */}
                {
                    showAllItems ?
                        <div className="ml-5">
                            <h4>Items</h4>
                            <table className="table-sm table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">Description</th>
                                        <th scope="col">Quantity</th>
                                        <th scope="col">Weight</th>
                                        <th scope="col">NMFC</th>
                                        <th scope="col">Class</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {shipment.handlingUnits.map((handlingUnit) => {
                                        if (handlingUnit.items.length) {
                                            return (
                                                handlingUnit.items.map((item, index) => {
                                                    // console.log(item.description)
                                                    return (<React.Fragment key={index}>
                                                        <tr>
                                                            <td>{item.description}</td>
                                                            <td>{item.quantity}</td>
                                                            <td>{Math.round(item.weight)}</td>
                                                            <td>{item.nmfc}</td>
                                                            <td>{item.freightClass}</td>
                                                        </tr>
                                                    </React.Fragment>)
                                                })
                                            )
                                        }
                                    })}
                                </tbody>
                            </table>
                        </div>
                        : null}
            </div>
        )
    } else {
        return null
    }
}

export default Items
