// ./Components/MessagesModal.js

import React, { Component } from "react";
import Authentication from "../APIs/Authentication";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as moment from "moment";
import SendMessage from "../APIs/SendMessage";
import Container from "react-bootstrap/Container";
import "./MessagesModal.css";

class MessagesModal extends Component {
  constructor() {
    super();
    this.sendMessage = this.sendMessage.bind(this);
    this.handleChangeGeneric = this.handleChangeGeneric.bind(this);
    this.sendMessageBtn = this.sendMessageBtn.bind(this);
    this.startUpAndUpdateLogicMessages = this.startUpAndUpdateLogicMessages.bind(
      this
    );
    this.resetSentStatus = this.resetSentStatus.bind(this);
    this.closeButton = this.closeButton.bind(this);

    this.state = {
      reversed: [],
      messageSentColor: "normal",
      messageSent: false,
    };
  }

  componentDidMount() {
    this.startUpAndUpdateLogicMessages();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.log !== prevProps.log) {
      this.startUpAndUpdateLogicMessages();
    }
  }

  startUpAndUpdateLogicMessages() {
    Authentication.isLoggedIn();
    this.setState({ email: Authentication.getEmailAddress() });
    this.setState({ reversed: this.props.log.reverse() });
  }

  handleChangeGeneric(event) {
    // console.log(event.target.value);
    event.preventDefault();
    this.setState({ [event.target.name]: event.target.value });
  }

  sendMessage(event) {
    event.preventDefault();
    // console.log("sendMessage() fired");
    this.setState({ messageSent: true, messageSentColor: "messageSent" });
    const messageData = new FormData();
    messageData.append("shipmentId", this.props.idmessages.toString());
    messageData.append("usercode", "Carrier");
    messageData.append("contactEmail", this.state.email);
    messageData.append("message", this.state.messageTextBox);
    SendMessage.Send(messageData); // Check for response here
  }

  sendMessageBtn(event) {
    event.preventDefault();
  }

  resetSentStatus() {
    this.setState({ messageSent: false, messageSentColor: "normal" });
  }

  closeButton(event) {
    // console.log("newclose")
    event.preventDefault();
    this.props.onHide();
    this.resetSentStatus();
    this.props.markMessagesReadLocally();
  }

  //848534275
  //242657438

  render() {
    return (
      <div>
        <Modal {...this.props} size="lg" scrollable="true">
          <Form onSubmit={this.submitProfileUpdate}>
            <Modal.Header className={this.state.messageSentColor}>
              <Modal.Title id="contained-modal-title-vcenter">
                {this.state.messageSent ? "Message Sent!" : "Messages"} for BOL:{" "}
                {this.props.idmessages}
              </Modal.Title>
              <Button
                variant="outline-primary"
                className="allBtn"
                onClick={this.closeButton}
              >
                Close
              </Button>
            </Modal.Header>

            <Modal.Body>
              <Container>
                <Row>
                  <Col>Send New Message:</Col>
                </Row>
                <Form onSubmit={this.sendMessage}>
                  <Row>
                    <Col xs lg="9">
                      <Form.Control
                        type="text"
                        name="messageTextBox"
                        onChange={this.handleChangeGeneric}
                      />
                    </Col>
                    <Col xs lg="3">
                      <Button
                        variant="outline-success"
                        type="submit"
                        onClick={this.sendMessage}
                      >
                        {" "}
                        Send Message{" "}
                      </Button>
                    </Col>
                  </Row>
                </Form>
                <Row>
                  <Col>Recieved Messages:</Col>
                </Row>

                {this.state.reversed.map((item) => {
                  if (item.type === "Message") {
                    return (
                      <Row key={item.id}>
                        <div key={item.id} className="messageDiv">
                          {item.entryDetails} <br />{" "}
                          <span className="nameDate">
                            - {item.contactName}
                            {item.contactEmail} (
                            {moment(item.enteredDate).format(
                              "LL"
                            )}
                            )
                          </span>
                        </div>
                      </Row>
                    );
                  } else {
                    return null;
                  }
                })}
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="outline-primary"
                className="allBtn"
                onClick={this.closeButton}
              >
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default MessagesModal;
