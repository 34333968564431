import Alert from "react-bootstrap/Alert";
import React, { useState, useEffect } from "react";
import DOMPurify from "dompurify";

const DashboardWelcome = ({ dashboardWelcome }) => {

  let [show, setShow] = useState(true)

  useEffect(() => {
    let localStorageShow = localStorage.getItem("dashboardWelcomeTaskViewShowHide")
    if (localStorageShow) {
      if (localStorageShow === "hide") {
        setShow(false)
      }
      if (localStorageShow === "show") {
        setShow(true)
      }
    }
  }, [])

  const HideWelcomeMessage = () => {
    localStorage.setItem("dashboardWelcomeTaskViewShowHide", "hide")
    setShow(false)
  }

  const sanitizedHTML = DOMPurify.sanitize(dashboardWelcome || "");

  const DefaultText = () => {
    return (
      <Alert variant="primary">
        {" "}
        <span
          onClick={() => HideWelcomeMessage()}
          className="d-flex justify-content-end"
        >
          <i className="far fa-times-circle" data-cy="dashboard-welcome-alert" />
        </span>
        <p>Welcome to the  Carrier Portal. </p>
        <p>
          Here you can update your shipments. Below are some tasks that may need to
          be completed. If the task has a "+" next to it click on it to see the
          shipments and further instructions. If the task is greyed out then there
          are no shipments for that task.
        </p>
        <p>
          The same shipment may appear under multiple tasks if more than one task needs to be done for that shipment. You can always search all shipments in the search bar
          above to find a shipment and update it.
        </p>
        <p>
          If you have any questions please contact support@shippersedge.com and we
          will be happy to help.
        </p>
        <p>
          Video tutorials are available on <a href="https://www.youtube.com/playlist?list=PLIZk-HGiRViR8SstYjNFk52NEzbiuPOfP" target="_blank" data-cy="youtube-link">Youtube</a>
        </p>
      </Alert>
    )
  }
  if (show && !dashboardWelcome && dashboardWelcome !== false) {
    return (
      <DefaultText />
    )


  }

  if (show && dashboardWelcome && dashboardWelcome.length > 0) {
    return (
      <Alert variant="primary">
        {" "}
        <span
          onClick={() => HideWelcomeMessage()}
          className="d-flex justify-content-end"
        >
          <i className="far fa-times-circle" data-cy="dashboard-welcome-alert" />
        </span>
        <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
      </Alert>
    )
  }
  else {
    return null
  }
}



export default DashboardWelcome;
