import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import history from "./APIs/History";
import Login from "./Pages/Login/Login";
import Dashboard from "./Pages/Dashboard/Dashboard";
import FourOFour from "./Pages/FourOFour/FourOFour";
import Container from "react-bootstrap/Container";
import SnackbarProvider from 'react-simple-snackbar'
import ErrorBoundry from "./Helpers/ErrorBoundry/ErrorBoundry"
import RatesHistory from "./Pages/RatesHistory/RatesHistory";
import "./App.css";

class App extends Component {
  constructor() {
    super();
    this.state = {};
  }
  // localStorage.getItem("userData")
  //This is passed to login who will set the userData state with the data returned from the authentication api then passed on via context to any component that needs it

  render() {
    return (

      <SnackbarProvider>
        <ErrorBoundry>
          <div className="App">
            <Container>
              <Router history={history}>
                <Switch>
                  <Route exact path="/" render={() => <Login />} />

                  <Route exact path="/Login" render={() => <Login />} />

                  <Route exact path="/Dashboard" render={() => <Dashboard />} />

                  <Route exact path="/History" render={() => <RatesHistory />} />

                  {/* <Route exact path="/Error" render={() => <ErrorBoundry />} /> */}

                  <Route component={FourOFour} />
                </Switch>
              </Router>
            </Container>
          </div>
        </ErrorBoundry>
      </SnackbarProvider>

    );
  }
}

export default App;
