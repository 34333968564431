import React from 'react'

const ChargesV2 = ({ shipment }) => {
    if (shipment && shipment.chargesList && shipment.chargesList.length) {
        return (
            <div>
                <span className='cardBodyRow1'>Charges:</span><br />
                {shipment.chargesList.map((charge, index) => {
                    return (
                        <div key={index}>
                            {charge.type === "estimatedCost" ?
                                <div>
                                    Estimated Cost: 
                                    &nbsp;
                                    {charge.total}
                                </div>
                                : null}
                            {charge.type === "billedCost" ?
                                <div>
                                    Billed Cost:
                                    &nbsp;
                                    {charge.total}
                                </div>
                                : null}
                        </div>
                    )
                })}
            </div>
        )
    } else {
        // console.warn('ChargesV2 saw no shipment.chargesList or no shipment.chargesList.length')
        return null
    }
}

export default ChargesV2
