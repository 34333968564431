import React from "react";

export const MailIcon = ({
  render,
  titleText,
  size,
  onClick,
  numMessages,
  numUnreadMessages,
  showHide,
}) => {
  if (showHide === "hide") {
    return null;
  }
  if (render === "unreadMessagesCollection") {
    return (
      <span>
        <i
          className={`far fa-envelope noExpand  ${size}`}
          id="Mailicon"
          title={titleText}
          style={{ backgroundColor: "#FFFF33" }}
          onClick={onClick}
        ></i>
      </span>
    );
  }
  if (render === "unreadMessages") {
    return (
      <span className="ml-2">
        <i
          className={`far fa-envelope noExpand ${size}`}
          id="Mailicon"
          title={`${numUnreadMessages} Unread Message(s)`}
          style={{ backgroundColor: "#FFFF33" }}
          onClick={onClick}
        ></i>
      </span>
    );
  }
  if (render === "messagesNoUnread") {
    return (
      <span className="ml-2">
        {" "}
        <i
          className={`far fa-envelope noExpand fa-lg`}
          id="Mailicon"
          title={`${numMessages} Message(s). 0 Unread.`}
          onClick={onClick}
        ></i>
      </span>
    );
  }
  if (render === "noMessages") {
    return (
      <span className="ml-2">
        {" "}
        <font color="#AAAFB4">
          <i
            className={`far fa-envelope noExpand fa-lg`}
            id="Mailicon"
            title="0 Messages."
            onClick={onClick}
          ></i>
        </font>
      </span>
    );
  } else {
    return null;
  }
};

export default MailIcon;
