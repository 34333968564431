import React from 'react'

// https://developers.google.com/maps/documentation/urls/get-started
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent

export const GoogleMapsLink = ({ address }) => {
    if (address) {

        let urlAddress = encodeURIComponent(address)

        let url = "https://www.google.com/maps/search/?api=1&query=" + urlAddress
        return (
            <div>
                <a href={url} target='_blank' rel='noopener noreferrer' title='Open In Google Maps'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-map" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M15.817.113A.5.5 0 0 1 16 .5v14a.5.5 0 0 1-.402.49l-5 1a.502.502 0 0 1-.196 0L5.5 15.01l-4.902.98A.5.5 0 0 1 0 15.5v-14a.5.5 0 0 1 .402-.49l5-1a.5.5 0 0 1 .196 0L10.5.99l4.902-.98a.5.5 0 0 1 .415.103zM10 1.91l-4-.8v12.98l4 .8V1.91zm1 12.98 4-.8V1.11l-4 .8v12.98zm-6-.8V1.11l-4 .8v12.98l4-.8z" />
                    </svg>
                </a>
            </div>
        )
    } else { return null }
}
