// ./Components/GenericModal.js

import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

class GenericModal extends Component {
  constructor() {
    super();

    this.state = {};
  }
  render() {
    return (
      <div>
        <Modal {...this.props} size="lg" scrollable="true">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              {this.props.title}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>{this.props.body}</Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-primary"
              className="allBtn"
              onClick={this.props.genericModalHide}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default GenericModal;
