import React from "react";
import * as moment from "moment";

const SettlementDate = ({ render, shipment }) => {
  let settleDate
  if (shipment) {
    settleDate = shipment.charges.settlementDate
  }
  if (
    render === "hide" ||
    settleDate === null ||
    settleDate === "0001-01-01T00:00:00" ||
    settleDate === "" ||
    !shipment
  ) {
    return null;
  }
  if (render === "show" && settleDate)
    return <div>Remittance Date: {moment(settleDate).format("YYYY-MM-DD")}</div>;
  else {
    return null;
  }
};

export default SettlementDate;
