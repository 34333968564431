import React from "react";

const InvoiceNumber = ({ render, shipment }) => {

  let invoiceNumber = null

  if (shipment) {
    invoiceNumber = shipment.charges.invoiceNumber
  }

  if (render === "hide" || invoiceNumber === null || invoiceNumber === "") {
    return null;
  }

  if (render === "show" && invoiceNumber && invoiceNumber !== "Enter Pro Number") {
    return <div data-cy="invoice">Invoice: {invoiceNumber}</div>
  } else {
    return null;
  }
};

export default InvoiceNumber;
