import React from "react";

const CarrierQuoteId = ({ render, shipment }) => {

  if (render === "hide" || shipment === null || shipment === "" || shipment === "null") {
    return null;
  }

  let quoteId
  if (shipment) {
    quoteId = shipment.charges.carrierQuoteId
  }

  const copyToClipboard = (event) => {
    if (navigator.clipboard) {
      const text = event.target.innerText;
      navigator.clipboard.writeText(text);
      // event.target.innerText = "Copied to Clipboard!";
    } else {
      console.warn("No navigator.clipboard")
    }
  };

  if (render === "show")
    return (
      <div>
        Quote Id:{" "}
        <span className="linkText" onClick={copyToClipboard}>
          {quoteId}
        </span>
      </div>
    );
  else {
    return null;
  }
};

export default CarrierQuoteId;
