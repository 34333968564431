import React, { useState } from 'react'
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/esm/Button';
import "./customModalFullSize.css";

const BilledChargeEditInDetailModal = ({ shipment, modalShow, setModalShow, accessoralRequestFeatureExists }) => {

    let style = {
        border: "none",
        width: "110%",
        height: "600px"
    }

    if (shipment.loadDetails.secureId) {

        let url = "https://www2.shippersedge.com/shipment/modals/chargeDetails.cfm?field=billedCost&wsdl"

        let secureId = shipment.loadDetails.secureId

        url += "&secid=" + secureId

        let shipmentId = shipment.id

        url += "&i=" + shipmentId

        if (accessoralRequestFeatureExists === "show") {
            url += "&accessoralRequestFeatureExists=true"
        }


        // console.log('accessoralRequestFeatureExists', accessoralRequestFeatureExists)
        // console.log('BilledChargeEditInDetailModal', url)

        return (
            <Modal
                show={modalShow}
                size="md"
                onHide={() => { setModalShow(false) }}
                onClose={() => { setModalShow(false) }}

            >
                {/* <Modal.Header closeButton>
                <Modal.Title>Billed Charge</Modal.Title>
            </Modal.Header> */}
                <Modal.Body>
                    <div className="d-flex justify-content-center">
                        <div style={{ width: "100%", padding: "0" }}>
                            {/* This iframe may not render in local dev environemnts outside localhost:3000, but accessing the url directly will, and in production (carriers.shippersedge.com) it works.  This is dude to content settings on www2.shippersedge.com */}
                            <iframe id="inlineFrame"
                                title="ShipperesEdge Billed Cost Edit In Detail"

                                style={style}
                                src={url}
                            >
                            </iframe>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setModalShow(false) }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
    return null

}

export default BilledChargeEditInDetailModal