// ./Components/GenericModal.js

import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

class SchuffGPSModal extends Component {
    constructor() {
        super();
        this.handleChangePhone = this.handleChangePhone.bind(this);
        this.SubmitPhoneNumber = this.SubmitPhoneNumber.bind(this);
        this.StatusSetter = this.StatusSetter.bind(this);
        this.updatePhoneNumber = this.updatePhoneNumber.bind(this);


        this.state = {
            alertText: "GPS Information Not Yet Submitted",
            alertType: "alert-secondary"
        };
    }



    async updatePhoneNumber(shipmentid, driverCell, truckNumber, timeOutValue) {
        let controller = new AbortController();
        let signal = controller.signal;
        var myHeaders = new Headers();
        myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW");
        myHeaders.append("Content-Type", "application/json");
        setTimeout(() => controller.abort(), timeOutValue);
        let payload = { "driverPhone": driverCell }
        if (!!truckNumber) payload.truckNumber = truckNumber
        var raw = JSON.stringify(payload);

        var requestOptions = {
            method: 'PUT', signal,
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        let response = await fetch("https://api.shippersedge.com/v4/shipment/gps/" + shipmentid + "/phone", requestOptions)
            .then(response => response.text())
            .then(result => result)
            .catch(error => error);
        return response

    }

    validateCell = () => {
        let driverCell = this.state.driverCell
        let nodashes = driverCell.replace(/-/g, "");
        let noDashNospaces = nodashes.replace(/\s/g, '');
        return noDashNospaces
    }

    StatusSetter(text, type) {
        this.setState({ alertText: text })
        this.setState({ alertType: type })
    }


    SubmitPhoneNumber = async () => {
        this.StatusSetter("Submitting Information", "alert - secondary")
        let processedPhone = this.validateCell()
        let truckNumber = this.state.truckNumber
        let result1 = await this.updatePhoneNumber(this.props.id, processedPhone, truckNumber, 10000)
        let response = JSON.parse(result1)
        if (response === "Aborted") {
            let result2 = await this.updatePhoneNumber(this.props.id, processedPhone, truckNumber, 20000)
            let response2 = JSON.parse(result2)
            if (response2 === "Aborted") {
                this.StatusSetter(
                    "Updates failed to update due to network timeout, please refresh your browser and try again.",
                    "alert alert-danger"
                );
            } else if (response2.message.includes('Changed: 1')) {
                this.StatusSetter("GPS Information Updated!", "alert-success");
            }
        } else if (response.message.includes('Changed: 1')) {
            this.StatusSetter("GPS Information Updated!", "alert-success");
        } else {
            this.StatusSetter(
                "Updates failed to update due to network timeout, please refresh your browser and try again.",
                "alert alert-danger"
            );
        }


    }

    handleChangePhone(event) {
        event.preventDefault();
        this.setState({ driverCell: event.target.value });
    }

    handleChangeTruckNumber(event) {
        event.preventDefault();
        this.setState({ truckNumber: event.target.value });
    }

    render() {
        return (
            <div>
                <Modal {...this.props} size="lg" scrollable="true">
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {this.props.title}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {this.props.body}<br /><br />
                        {this.props.displayTruckField ?
                            <div>
                                <div className="input-group mb-3">
                                    <input type="text" name="truckNumber" className="form-control" placeholder="Enter Truck Number Here for ELD/Telematics Tracking" aria-label="Truck Number" aria-describedby="basic-addon1" onChange={this.handleChangeTruckNumber} maxlength="12" />
                                </div>
                            </div> : null}
                        {this.props.displayPhoneField ?
                            <div>
                                <div className="input-group mb-3">
                                    <input type="text" name="driverCell" className="form-control" placeholder={this.props.phonePlaceholderText} aria-label="Driver Cell" aria-describedby="basic-addon1" onChange={this.handleChangePhone} maxlength="12" />
                                </div>
                                <button type="button" className="btn btn-outline-success" onClick={this.SubmitPhoneNumber}>Update</button>

                                <div className={`alert mt-3 ${this.state.alertType}`} role="alert">
                                    {this.state.alertText}
                                </div>
                            </div> : null}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="outline-primary"
                            className="allBtn"
                            onClick={this.props.hideSchuffGPSModal}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default SchuffGPSModal;
