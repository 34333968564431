// ./Pages/Login/Login.js
// Login page here, will pull the API requests back out later and optimize

import React, { Component } from "react";
import history from "../../APIs/History";
import Authentication from "../../APIs/Authentication";
import Alert from "react-bootstrap/Alert";
import BrowserWarning from "../../Components/BrowserWarning/BrowserWarning";
import ForgotPasswordUI from "./Components/ForgotPasswordUI";
import LoggingIn from "./Components/LoggingIn";

class Login extends Component {
  constructor() {
    super();
    this.handleChangeLoginEmail = this.handleChangeLoginEmail.bind(this);
    this.handleChangeLoginPassword = this.handleChangeLoginPassword.bind(this);
    this.handleSubmitLogin = this.handleSubmitLogin.bind(this);
    this.checkForcedLogout = this.checkForcedLogout.bind(this);
    this.clearLoginFailed = this.clearLoginFailed.bind(this);
    this.showLogin = this.showLogin.bind(this);
    this.state = {
      show: "none",
      showLoginForm: true,
      showExpiredLoginMsg: false,
      loginstatus: 'start',
      loginButtonDisabled: false
    };
  }

  componentDidMount() {
    // Using the below log to veryfy last deployment date / to be sure Buddy finished the deployment.
    // console.log("Version, 2020-02-17-a");
    this.checkForcedLogout();
    Authentication.isLoggedIn();
  }

  handleChangeLoginEmail(event) {
    if (this.state.show === "block") {
      this.setState({ show: "none" });
    }
    // console.log(event.target.value);
    this.setState({ email: event.target.value });
  }

  handleChangeLoginPassword(event) {
    if (this.state.show === "block") {
      this.setState({ show: "none" });
    }
    // console.log(event.target.value);
    this.setState({ password: event.target.value });
  }

  showLogin(show) {
    this.setState({ showLoginForm: show });
  }

  checkForcedLogout() {
    if (localStorage.getItem("forceLogoutReason")) {
      this.setState({ showExpiredLoginMsg: true });
      localStorage.removeItem("forceLogoutReason");
    } else {
      this.setState({ showExpiredLoginMsg: false });
    }
  }

  condRenderForcedLogout() {
    if (this.state.showExpiredLoginMsg) {
      // console.log("Forced Logout Exists");
      return (
        <Alert variant="danger">
          Your login session has expired, please login again!
        </Alert>
      );
    } else {
      return null;
    }
  }

  // Login Event
  handleSubmitLogin(event) {
    // event.target.reset();
    event.preventDefault();
    this.setState({ show: "none" });
    this.setState({ loginstatus: 'loggingin' });
    this.setState({ loginButtonDisabled: true })
    let email = this.state.email;
    let password = this.state.password;
    localStorage.removeItem("forceLogoutReason");
    Authentication.login(email, password)
      .then(response => {
        // console.log("login.js, handleSubmitLogin, success", response);
        // this.props.setUserData(response.data);
        this.setState({ loginstatus: 'start' });
        history.push("/Dashboard");

      })
      .catch(err => {
        this.setState({ loginstatus: 'errored' });
        this.setState({ loginButtonDisabled: false })
        // console.log("login.js, handleSubmitLogin, error", err);
        this.setState({ show: "block" });
      });
  }

  clearLoginFailed() {
    if (this.state.show === "block") {
      this.setState({ show: "none" });
    }
  }

  render() {
    const titilliumStyle = {
      // fontFamily: "Open Sans, sans-serif",
      fontFamily: "Titillium Web, Semibold",
      fontWeight: "600",
      fontSize: "2.9rem",
      marginBottom: "2vh"
    };
    const style = {
      display: this.state.show
    };

    return (
      <div className="Login">
        <div className="loginChildLogo">
          <img src="shippersedge-logo.png" alt="logo" height="100px" />
          <br />
        </div>
        <div className="signInTxt">
          <h2 style={titilliumStyle}>Carrier Portal</h2>
        </div>
        {/*
        <div className="signInTxt">Please Sign In</div> */}
        <div className="loginChildLogin">
          <form onSubmit={this.handleSubmitLogin}>
            <div style={{ display: this.state.showLoginForm ?? "block" }}>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  id=""
                  aria-describedby="emailHelp"
                  placeholder="Email Address"
                  value={this.value}
                  onChange={this.handleChangeLoginEmail}
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Password"
                  value={this.value}
                  onChange={this.handleChangeLoginPassword}
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary btn-block loginBtn"
                disabled={this.state.loginButtonDisabled}
              >
                {this.state.loginButtonDisabled ? "Logging In..." : "Sign In"}
              </button>
            </div>
          </form>
          <br />
          <ForgotPasswordUI
            email={this.state.email}
            clearLoginError={this.clearLoginFailed}
            showLogin={this.showLogin}
          />


          <LoggingIn loginstatus={this.state.loginstatus} />


          <div className="loginAlertDiv">
            <Alert variant="danger" style={style}>
              Login Failed!
            </Alert>
            {this.condRenderForcedLogout()}
          </div>
          <div>
            <BrowserWarning />
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
