

const billedCost = {
    calculateTotalCost: (base, discount, fuel) => {
        // base - discount + fuel rounded to 2 decimal places
        const total = Math.round((+base - +discount + +fuel) * 100) / 100;
        return total;
    },
    getBilledCost: async (webquotecontrol) => {
        const url = 'https://api.shippersedge.com/billing/charges/' + webquotecontrol;
        const options = { method: 'GET', headers: { 'x-api-key': 'vdhy8wwjUJrwhxFpfuECTiAW' } };

        try {
            const response = await fetch(url, options);
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('getBilledCost', error);
            return error;
        }
    },
    postBilledCost: async (webquotecontrol, base, discount, fuel, total) => {
        const costObj = {
            chargeStruct: {
                billedCost: {
                    creditDebit: "debit",
                    total: total,
                    details: {
                        base: base,
                        discount: discount,
                        fuel: fuel
                    }
                }
            }
        }
        const url = 'https://api.shippersedge.com/billing/charges/' + webquotecontrol + '?updatecs=true'
        const options = { method: 'POST', headers: { 'x-api-key': 'vdhy8wwjUJrwhxFpfuECTiAW' } };

        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(costObj);
        try {
            const response = await fetch(url, options);
            const data = await response.json();
            return data;
        } catch (error) {
            alert('Error: ' + error)
            return error;
        }

    }

}

export default billedCost;