// ./Components/SearchResults.js

// This Component is for Search Results.

// Search results operates slightly different than the static requests as the search params are passed up from the nav area to Dashboard.js, Dashboard processes the request at Dashboard.js and then as an array down to SearchResults.js, so no filtering required here

import React, { Component } from "react";
// import Collapse from "react-bootstrap/Collapse";
import ShipmentCard2 from "../Components/ShipmentCard2";

class SearchResults extends Component {
  constructor() {
    super();
    this.condrenderCard = this.condrenderCard.bind(this);
    this.state = {
      open: true,
    };
  }

  componentDidMount() {
    let counter = this.props.searchResults;
    this.setState({ count: counter.length });
    this.setState({ propResults: this.props.searchResults });
  }

  //Conditional render as setState in the above componentDidMount is async and does some filtering for us
  condrenderCard() {
    if (this.state.propResults) {
      return (
        <div>
          {this.state.propResults.map((item) => {
            return (
              <ShipmentCard2
                key={item.id}
                shipment={item}
                showFields={this.props.showFields}
                hilightFields={this.props.hilightFields}
                updateSingleShipment={this.props.updateSingleShipment}
              />
            );
          })}
        </div>
      );
    } else {
      return "No Results Found!";
    }
  }

  render() {
    return (
      <div className="SearchResults catagoryDivEnabled">
        {/* <span
          onClick={() => this.setState({ open: !open })}
          aria-controls="collapse-SearchResults"
          aria-expanded={open}
        > */}
        <span className="catagoryTitle">
          <h3>Search Results ({this.state.count})</h3>
        </span>
        {/* </span> */}
        {/* <Collapse in={this.state.open}> */}
        <div id="collapse-SearchResults">{this.condrenderCard()}</div>
        {/* </Collapse> */}
      </div>
    );
  }
}

export default SearchResults;
