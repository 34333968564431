// ./APIs/GetShipments.js
//
// Resolves false if thre is not a duplicate pro nmber, true if the pronumber has been used

import { promised } from "q";
import Authentication from "../APIs/Authentication";

const CheckProNum = {
  CheckProNumv2(webquotecontrol, carrierId, pronum, timeOutValue) {
    let carrierClientID = localStorage.getItem("carrierClientId")
    Authentication.isLoggedIn();
    return new Promise(function (resolve, reject) {
      if (pronum === null || pronum === "" || pronum === undefined) {
        resolve(false);
      } else {
        var myHeaders = new Headers();
        myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW");

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        fetch("https://api.shippersedge.com/pro-number/shipmentIds?proNumber=" + pronum + "&usercode=" + carrierClientID + "&carrierId=" + carrierId, requestOptions)
          .then(response => response.text())
          .then(result => {
            // console.log("CheckProNumv2 result", JSON.parse(result))
            let resultArray = JSON.parse(result)
            let filteredArray = resultArray.filter((item) => {
              return item !== webquotecontrol
            })
            // console.log('CheckProNumv2 filtered', filteredArray)
            if (filteredArray.length === 0) {
              resolve(false)
            } else {
              resolve(true)
            }
          })
          .catch(error => {
            resolve(true)
            console.log('error', error)
          });
      }
    })
  },
  CheckProNum(webquotecontrol, carrierId, pronum, timeOutValue) {
    // console.log("check ProNum", webquotecontrol, pronum);
    Authentication.isLoggedIn();
    return new Promise(function (resolve, reject) {
      if (pronum === null || pronum === "" || pronum === undefined) {
        resolve(false);
      } else {
        // console.log(
        //   "debug CheckProNum.js, CheckProNum()",
        //   webquotecontrol,
        //   carrierId,
        //   pronum,
        //   timeOutValue
        // );
        const carrierClientId = localStorage.getItem("carrierClientId");
        let controller = new AbortController();
        let signal = controller.signal;
        let url =
          "https://www2.shippersedge.com/public/carrier/checkProNumber.cfm?webquotecontrol=" +
          webquotecontrol +
          "&carrierid=" +
          carrierId +
          "&pronum=" +
          pronum +
          "&wsdl";
        let request = new Request(url, { signal });
        setTimeout(() => controller.abort(), timeOutValue);
        fetch(request)
          .then(response => {
            // console.log("debug checkpronum response", response);
            if (!response.ok) {
              console.error(
                "CheckProNum.js error",
                response.statusText,
                response
              );
              throw Error(response.statusText);
            }
            return response.json();
          })
          .then(data => {
            // console.error("debug checkpronum data", data);
            resolve(data.used);
          })
          .catch(err => {
            console.error("Request failed", err);
            resolve("Aborted");
          });
      }
      return promised;
    });
  }
};

export default CheckProNum;
