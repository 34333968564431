import React from 'react'

const AcceptDeclineStatus = (props) => {

    if (props.status === "loading") {
        return (
            <div class="alert alert-secondary" role="alert">
                Submitting, please stand by...
            </div>
        )
    }

    if (props.status === "error") {
        return (
            <div class="alert alert-warning" role="alert">
                An error has occured, please try again.
            </div>
        )
    }

    if (props.status === "success") {
        return (
            <div class="alert alert-success" role="alert">
                Shipment Updated!
            </div>
        )
    } else {
        return null;
    }

}

export default AcceptDeclineStatus
