import React from "react";

const CurrentStatus = ({ render, shipment }) => {
  // const style = {};
  if (render === "hide" || !shipment) {
    return null;
  }
  if (render === "show" && shipment)
    return (
      <>
        Current Status:
        <br />
        {shipment.status.statusId}
        <br />
        <span className="shipCardStatusDescription">({shipment.status.description})</span>
      </>
    );
  else {
    return null;
  }
};

export default CurrentStatus;
