import React from 'react'
import { Form, Col } from 'react-bootstrap'

// This component was created for Foremost Farms, it's a limited date picker for the appointment delivery date

const DELApptDateLimited = ({ render, apptDelDate, shipmentObjUpdateEvent, requestedDeliveryDate, inputFieldsReadOnly }) => {
    if (render && apptDelDate && shipmentObjUpdateEvent) {

        let readOnly = false
        if (!requestedDeliveryDate || inputFieldsReadOnly) {
            readOnly = true
        }

        let minDateStr = ''
        let maxDateStr = ''

        if (!readOnly) {
            const minDate = new Date(requestedDeliveryDate)
            minDate.setDate(minDate.getDate() - 3)
            const maxDate = new Date(requestedDeliveryDate)
            maxDate.setDate(maxDate.getDate() + 3)
            minDateStr = minDate.toISOString().split('T')[0]
            maxDateStr = maxDate.toISOString().split('T')[0]
        }
        return (
            <Form.Group
                as={Col}
                xs
                lg="2"
            >
                <Form.Label>Appt. Delivery:</Form.Label>
                <Form.Control
                    size="sm"
                    name="dates.appointmentDeliveryDate"
                    onChange={(event) => shipmentObjUpdateEvent(event)}
                    className="form-control"
                    type="date"
                    value={apptDelDate}
                    min={minDateStr}
                    max={maxDateStr}
                    readOnly={readOnly}
                />
            </Form.Group>
        )
    } else {
        return null
    }


}

export default DELApptDateLimited