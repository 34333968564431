// ./APIs/UploaFile.js

//
//  March 18 2020 - Upload file's method of retries here is not optimal but it works.  Will convert later to a system more like SubmitUpdate.js with async calls
//

const UploadFile = {
  async UploadNew(formData, timeOutValue) {
    // console.log("UploadFile UploadNew", formData, timeOutValue);

    let controller = new AbortController();
    let signal = controller.signal;
    let url =
      "https://www2.shippersedge.com/public/carrier/uploadFile.cfm?wsdl";
    let request = new Request(url, {
      method: "POST",
      signal,
      body: formData,
    });
    setTimeout(() => controller.abort(), timeOutValue);

    let fetchResponse = await fetch(request)
      .then((response) => {
        // console.log("UploadNew response", response);
        if (!response.ok) {
          console.error("UploadNew error", response.statusText, response);
          throw Error(response.statusText);
        }
        // console.log("UploadNew fetch response", response);

        return response.text();
      })
      .then((data) => {
        // console.log("UploadFile UploadNew data", data);
        return data;
      })
      .catch((err) => {
        console.error("UploadFile UploadNew failed", err);
        if ((err = 'DOMException: "The operation was aborted. "')) {
          return "Aborted";
        } else return "UploadFile UploadNew Fetch Error Unknown Reason";
      });

    return fetchResponse;
  },

  Upload(uploadData) {
    console.log("UploadFile.js Fired");
    console.log(uploadData);
    return new Promise(function (resolve, reject) {
      let controller = new AbortController();
      let signal = controller.signal;
      let url =
        "https://schuff.shippersedge.com/public/carrier/uploadFile.cfm?wsdl";
      let request = new Request(url, {
        method: "POST",
        signal,
        body: uploadData,
      });
      setTimeout(() => controller.abort(), 5000);
      fetch(request)
        .then((response) => {
          console.log("debug UploadFile response", response);
          if (!response.ok) {
            console.error("UploadFile.js error", response.statusText, response);
            throw Error(response.statusText);
          }
          //   console.log("ok");
          return response;
        })
        .then((response) => {
          console.log("UploadFile response is", response);
          resolve(response);
        })
        .catch((err) => {
          console.error("UploadFile API Request failed", err);
          UploadFile.LongUploadRetry(uploadData);
          resolve("Aborted");
        });
    });
  },
  LongUploadRetry(uploadData) {
    console.log("UploadFile.js , LongUploadRetry, Fired");
    console.log(uploadData);
    return new Promise(function (resolve, reject) {
      let controller = new AbortController();
      let signal = controller.signal;
      let url =
        "https://schuff.shippersedge.com/public/carrier/uploadFile.cfm?wsdl";
      let request = new Request(url, {
        method: "POST",
        signal,
        body: uploadData,
      });
      setTimeout(() => controller.abort(), 15000);
      fetch(request)
        .then((response) => {
          console.log("debug LongUploadRetry response", response);
          if (!response.ok) {
            console.error("UploadFile.js error", response.statusText, response);
            throw Error(response.statusText);
          }
          //   console.log("ok");
          return response;
        })
        .then((response) => {
          console.log("LongUploadRetry response is", response);
          resolve(response);
        })
        .catch((err) => {
          console.error("LongUploadRetry API Request failed", err);
          UploadFile.ReallyLongUploadRetry(uploadData);
          resolve("Aborted");
        });
    });
  },
  ReallyLongUploadRetry(uploadData) {
    console.log("UploadFile.js , ReallyLongUploadRetry, Fired");
    console.log(uploadData);
    return new Promise(function (resolve, reject) {
      let controller = new AbortController();
      let signal = controller.signal;
      let url =
        "https://schuff.shippersedge.com/public/carrier/uploadFile.cfm?wsdl";
      let request = new Request(url, {
        method: "POST",
        signal,
        body: uploadData,
      });
      setTimeout(() => controller.abort(), 30000);
      fetch(request)
        .then((response) => {
          console.log("debug ReallyLongUploadRetry response", response);
          if (!response.ok) {
            console.error("UploadFile.js error", response.statusText, response);
            throw Error(response.statusText);
          }
          //   console.log("ok");
          return response;
        })
        .then((response) => {
          console.log("ReallyLongUploadRetry response is", response);
          resolve(response);
        })
        .catch((err) => {
          console.error("ReallyLongUploadRetry API Request failed", err);
          resolve("Aborted");
        });
    });
  },
};

export default UploadFile;
