import React from "react";

const Alert01 = ({ status, message, margin }) => {
  const style = {
    marginTop: margin
  };
  if (status === "hide") {
    return null;
  }
  if (status === "loading") {
    return (
      <div style={style}>
        <div className="spinner-border text-success" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
  if (status === "show")
    return (
      <div style={style}>
        <div className="alert alert-success shadow-sm rounded" role="alert">
          {message}
        </div>
      </div>
    );
  if (status === "error")
    return (
      <div style={style}>
        <div className="alert alert-danger shadow-sm rounded" role="alert">
          We have encountered an error: {message}
        </div>
      </div>
    );
  else {
    return null;
  }
};

export default Alert01;

// Hook code for parent component
// const [alertStatus, setAlertStatus] = React.useState("hide");
// const shipmentUpdatedAlert = () => {
//   setAlertStatus("loading");
//   setTimeout(() => setAlertStatus("show"), 2000);
// };
