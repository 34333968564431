import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const RateRequestSubmitAndStatus = (props) => {
  const [status, useStatus] = React.useState(" ");
  const [alertType, useAlertType] = React.useState("alert alert-light");

  function AlertBox() {
    return (
      <div className={`${alertType}`} role="alert">
        {status}
      </div>
    );
  }

  function ButtonText() {
    if (props.Action === "submitRate") {
      return "Submit";
    }
    if (props.Action === "declineShipment") {
      return "Decline";
    }
  }

  const button = (
    <span>
      <button className={ButtonType()} disabled={props.isDisabled} onClick={sequentialStart}>
        {<ButtonText />}
      </button>{" "}
      <AlertBox />
    </span>
  );


  const tooltip = (
    <Tooltip id="tooltip-disabled">
      Please fill in all required fields before submitting.
    </Tooltip>
  );


  function ButtonType() {
    if (props.Action === "submitRate") {
      return "btn btn-outline-success";
    }
    if (props.Action === "declineShipment") {
      return "btn btn-outline-danger";
    }
  }

  function StatusSetter(text, type) {
    useStatus(text);
    useAlertType(type);
  }

  async function sequentialStart() {
    StatusSetter("Preparing Response...", "alert alert-secondary");
    const formData = await props.buildFormData(props.Action);
    if (props.isOceanInt) {
      const res = await props.SubmitQuoteResponse.postRateRequest(formData);
      if (res.insertId) {
        StatusSetter("Rate Quote Response Submitted Sucessfully!", "alert alert-success");
        return true;
      } else {
        StatusSetter("Failed to submit rate quote response due to network timeout, please refresh your browser and try again.", "alert alert-danger");
        return false;
      }
    } else {
      StatusSetter("Submitting Response...", "alert alert-secondary");
      const submitRateOrDecline1 = await props.SubmitQuoteResponse.Respond(
        formData,
        10000,
      );
      // console.log("submitRateOrDecline", submitRateOrDecline1);
      if (submitRateOrDecline1 === "Network Timeout Controller Aborted") {
        console.error("Network Timeout Controller Aborted", submitRateOrDecline1);
        StatusSetter("Retry Submitting Response (1)", "alert alert-secondary");
        const submitRateOrDecline2 = await props.SubmitQuoteResponse.Respond(
          formData,
          20000,
        );
        if (submitRateOrDecline2 === "Network Timeout Controller Aborted") {
          console.error(
            "Network Timeout Controller Aborted",
            submitRateOrDecline2
          );
          StatusSetter("Retry Submitting Response 2", "alert alert-secondary");
          const submitRateOrDecline3 = await props.SubmitQuoteResponse.Respond(
            formData,
            30000,
          );

          if (submitRateOrDecline3 === "Network Timeout Controller Aborted") {
            console.error(
              "Network Timeout Controller Aborted",
              submitRateOrDecline2
            );
            StatusSetter(
              "Failed to submit rate quote response due to network timeout (tried 3 times), please refresh your browser and try again.",
              "alert alert-danger"
            );
            return false;
          } else if (submitRateOrDecline3.includes("Sucessfully")) {
            StatusSetter(
              "Rate Quote Response Submitted Sucessfully!",
              "alert alert-success"
            );
            return true;
          }
        } else if (submitRateOrDecline2.includes("Sucessfully")) {
          StatusSetter(
            "Rate Quote Response Submitted Sucessfully!",
            "alert alert-success"
          );
          return true;
        }
      } else if (submitRateOrDecline1.includes("Sucessfully")) {
        StatusSetter(
          "Rate Quote Response Submitted Sucessfully!",
          "alert alert-success"
        );
        return true;
      }
    }
  }
  return (
    <div>
      {props.isDisabled ? (
        <span className="d-inline-block">
          <OverlayTrigger placement="top" overlay={tooltip}>
            <span>{button}</span>
          </OverlayTrigger>
        </span>
      ) : (
        <span className="d-inline-block">{button}</span>
      )}
    </div>
  );
};

export default RateRequestSubmitAndStatus;
