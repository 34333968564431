import React, { useEffect } from 'react'

const HalPOCSupport = ({ clientId }) => {
    // useEffect(() => {
        // if (clientId === 'halliburtonPOC') {
        //     // Define the function to add the 3rd-party script
        //     const addThirdPartyScript = (document) => {
        //         console.log('addThirdPartyScript')
        //         var basehref = "https://shippersedge.teamwork.com",
        //             token = "82d81c35-4bfb-11ee-b192-a27ab4fc2d5e";

        //         window.deskcontactwidget = {};
        //         var r = document.getElementsByTagName("script")[0],
        //             c = document.createElement("script");
        //         c.type = "text/javascript";
        //         c.async = true;
        //         c.src = basehref + "/support/v1/contact/main.js?token=" + token;
        //         r.parentNode.insertBefore(c, r);

        //         window.addEventListener("message", function (e) {
        //             var t = e.data[0], a = e.data[1];
        //             switch (t) {
        //                 case "setContactFormHeight":
        //                     document.getElementById("deskcontactwidgetframe").height = Math.min(a, window.innerHeight - 75);
        //             }
        //         }, false);
        //     };

        //     // Execute the function
        //     addThirdPartyScript(document);
        // }
    // }, [clientId]);

    return null 
    // else {
    //     return (
    //         <div>&nbsp;</div>
    //     )
    // }
}

export default HalPOCSupport