import React from 'react'
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'

const Rate = ({ rateid, index }) => {

    const rateQueryClient = new QueryClient()

    const rateUrl = 'https://api.shippersedge.com/v2/rates/' + rateid

    const getRatesHistFunct = fetch(rateUrl, { headers: { 'x-api-key': 'vdhy8wwjUJrwhxFpfuECTiAW' } }).then(res => res.json())

    // Each query needs a different name
    const individualQueryName = 'getIndividualRate' + rateid

    const { isLoading, error, data } = useQuery(individualQueryName, () => getRatesHistFunct)

    if (isLoading) return 'Loading...'

    if (error) return 'An error has occurred: ' + error.message

    const humanReadableDate = (inputDate) => {
        let date = new Date(inputDate)
        let month = date.getMonth() + 1
        let day = date.getDate()
        let year = date.getFullYear()
        date = month + '/' + day + '/' + year
        return date
    }

    // function to capitalize the first letter of each word
    const capitalizeFirstLetterOfEachWord = (string) => {
        if (typeof string !== 'string') return ''
        return string.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase() })
    }

    if (data) {
        return (
            <QueryClientProvider client={rateQueryClient}>
                <tr>
                    <td>
                        {humanReadableDate(data.timestamp)}
                    </td>
                    {/* <td>
                        {data.rate_hist_id}
                    </td> */}
                    <td>
                        {data.webquotecontrol}
                    </td>
                    <td>
                        {data.criteria_ocity ? capitalizeFirstLetterOfEachWord(data.criteria_ocity) : null}
                        {data.criteria_ocity && data.criteria_ozip ? <>,</> : null}
                        {data.criteria_ozip ? <> {data.criteria_ozip}</> : null}
                    </td>
                    <td>
                        {data.criteria_dcity ? capitalizeFirstLetterOfEachWord(data.criteria_dcity) : null}
                        {data.criteria_dcity && data.criteria_dzip ? <>,</> : null}
                        {data.criteria_dzip ? <> {data.criteria_dzip}</> : null}
                    </td>
                    <td>${data.carrier_finalprice}</td>
                    <td>{data.winningcarrierid === 1 && data.winningratehistid === 1 ? "Win" : "Lost"}</td>
                </tr>
            </QueryClientProvider>
        )
    } else {
        return 'No rate information.'
    }
}

export default Rate