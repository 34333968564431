import React, { useState } from "react";
import Email from "../../../SmallComponents/Input01";
import PasswordRecoveryCode from "../../../SmallComponents/Input01";
import Password1 from "../../../SmallComponents/Input01";
import Password2 from "../../../SmallComponents/Input01";
import PasswordRecovery from "../../../APIs/PasswordRecovery";
import { useLocation } from 'react-router-dom';


import Alert from "react-bootstrap/Alert";

function ForgotPassword(props) {
  const [step, setStep] = useState(0);
  const [status, setStatus] = useState("default");
  const [email, setEmail] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [passwordRecoveryCode, setPasswordRecoveryCode] = useState("");

  const location = useLocation();

  React.useEffect(() => {
    ResetPassword();
  }, [props.email]);

  function useQuery() {
    return new URLSearchParams(location.search);
  }

  function ResetPassword() {
    let query = useQuery();
    let resetCode = query.get("code");
    let status = query.get("status");
    let email = query.get("email");
    if (status && resetCode) {
      setStep(2);
      setPasswordRecoveryCode(resetCode);
      setEmail(email);
      props.showLogin('none');
    }
    
  }

  var linkStyle = {
    cursor: "pointer",
    color: "#4D7898",
    textDecoration: "underline"
  };
  var hideStyle = {
    display: "none"
  };

  var outlineStyle = {
    borderRadius: "5px",
    backgroundColor: "#DCDCDC",
    padding: "10px"
  };

  //   Conditional Rendering Instructional UI (alert fields based on status, API returns)
  function errorsAndStatus() {
    if (status === "default") {
      return null;
    }
    if (status === "enterEmail") {
      return (
        <>
          <Alert variant="info">
            Enter your email address and we will Email you a Password Reset Link. <br />
          </Alert>
          <span
            style={linkStyle}
            onClick={event => {
              setStep(0);
              props.showLogin('block');
              setStatus("start");
            }}
          >
            I know my Password. Let me login again.
          </span>
        </>
      );
    }
    if (status === "enterRecoveryCode") {
      return (
        <Alert variant="info">
          Please enter set your new password.
        </Alert>
      );
    }
    if (status === "enterNewPassword") {
      return <Alert variant="info">Enter your new Password.</Alert>;
    }
    if (status === "noEmail") {
      return (
        <Alert variant="warning">
          Uh oh!
          <br />
          We can't find {email} in our records, perhaps you entered it
          incorrectly?
        </Alert>
      );
    }
    if (status === "accountDisabled") {
      return (
        <Alert variant="warning">
          The email address assoicated with that account is disabled. <br />
          Please contact your customer with any questions.
        </Alert>
      );
    }
    if (status === "multipleRecords") {
      return (
        <Alert variant="multipleRecords">
          Please contact support@shippersedge.com for further assistance.
        </Alert>
      );
    }
    if (status === "successCheckEmail") {
      return (
        <Alert variant="success">
          Check your Email!
          <br />
          <br />
          Please use the link in your email to reset your password. If you have not received an email, please check your spam folder.
        </Alert>
      );
    }
    if (status === "noResetCodeMatch") {
      return (
        <Alert variant="warning">
          The Password Recovery Reset code you entered did not match. Please try
          again, or{" "}
          <span style={linkStyle} onClick={() => setStep(1)}>
            request a new code
          </span>
          .
        </Alert>
      );
    }
    if (status === "expiredCode") {
      return (
        <Alert variant="warning">
          The Password Recovery Code has expired. Please request a new Password
          Recovery Code.
        </Alert>
      );
    }
    if (status === "successNewPasswordSet") {
      return <Alert variant="success">Your new password has been set!</Alert>;
    }
  }

  function submitEmail(email) {
    PasswordRecovery.getPasswordRecoveryCode(email).then(response => {
      if (response.status === "noEmail") {
        setStatus("noEmail");
      }
      if (response.status === "accountDisabled") {
        setStatus("accountDisabled");
      }
      if (response.status === "multipleRecords") {
        setStatus("multipleRecords");
      }
      if (response.status === "successCheckEmail") {
        // setStep(2);
        setStatus("successCheckEmail");
      }
    });
  }

  function verifyPasswordMatchAndSubmit(
    email,
    passwordRecoveryCode,
    password1,
    password2
  ) {
    if (email === "") {
      alert("Please fill out the email field.");
    } else if (password1 === "" || password2 === "") {
      alert("Please do not use blank passwords.");
    } else if (password1 !== password2) {
      alert("Both passwords do not match.");
    } else {
      PasswordRecovery.submitPasswordRecoveryCodeAndPassword(
        email,
        passwordRecoveryCode,
        password1,
        password2
      ).then(response => {
        if (response.status === "noResetCodeMatch") {
          setStatus("noResetCodeMatch");
        }
        if (response.status === "expiredCode") {
          setStatus("expiredCode");
          setStep(1);
        }
        if (response.status === "successNewPasswordSet") {
          setStatus("successNewPasswordSet");
          props.showLogin('block');
          setStep(0);
        }
      });
    }
  }

  // If the user has no email auto filling out the email fields and they pick "I have a recovery code", they'll ned to fill out an additional field
  // function additionalEmailField() {
  //   if (email === "") {
  //     return (
  //       <>
  //         <input type="email" style={hideStyle} />
  //         <Email
  //           id="passwordRecoveryEmailFieldAdditional"
  //           value={email}
  //           showHide="show"
  //           type="email"
  //           placeholder="Email Address"
  //           name="email"
  //           onChange={event => setEmail(event.target.value)}
  //         />
  //       </>
  //     );
  //   } else {
  //     return null;
  //   }
  // }

  // ForgotPassword UI
  if (step === 0) {
    return (
      <>
        <span
          style={linkStyle}
          onClick={() => {
            setStep(1);
            setStatus("enterEmail");
            props.showLogin('none');
            props.clearLoginError();
          }}
        >
          Forgot Password?
        </span>

        {errorsAndStatus()}
      </>
    );
  }
  if (step === 1) {
    return (
      <div style={outlineStyle}>
        <Email
          id="passwordRecoveryEmailField"
          value={email}
          showHide="show"
          type="email"
          placeholder="Email Address"
          name="email"
          onChange={event => setEmail(event.target.value)}
        />
        <button
          className="btn btn-secondary btn-block"
          onClick={() => {
            submitEmail(email);
          }}
        >
          Send Reset Password Email
        </button>

        {/* <PrimaryBtn
          btnName="Submit Email"
          showHide="show"
          onClick={() => {
            submitEmail(email);
          }}
        /> */}
        <br />

        {errorsAndStatus()}
      </div>
    );
  }

  if (step === 2) {
    return (
      <div style={outlineStyle}>
        {/* Chrome insists on filling out the first field on ANY input type, in this case it was adding an email to the recovery code field.   Chromme only fills out the first email field, same with password lower, the hidden inputs are meanningless.  */}
        <input type="password" style={hideStyle} />
        <PasswordRecoveryCode
          value={passwordRecoveryCode}
          id="passwordRecoveryCodeField"
          type="password"
          showHide="hide"
          placeholder="Password Recovery Code"
          name="userNameRecovery"
          onChange={event => setPasswordRecoveryCode(event.target.value)}
        />
        <input type="password" style={hideStyle} />
        <Password1
          value={password1}
          showHide="show"
          type="password"
          placeholder="New Password"
          name="passwordRecovery1"
          onChange={event => setPassword1(event.target.value)}
        />
        <Password2
          value={password2}
          showHide="show"
          type="password"
          placeholder="Verify Password"
          name="passwordRecovery2"
          onChange={event => setPassword2(event.target.value)}
        />
        {/* In case someone click's "I have a code" but hasn't entered their email (another browser maybe) */}
        {email === "" ? (
          <>
            <input type="email" style={hideStyle} />
            <Email
              id="passwordRecoveryEmailFieldAdditional"
              value={email}
              showHide="show"
              type="email"
              placeholder="Confirm Email Address"
              name="email"
              onChange={event => setEmail(event.target.value)}
            />
          </>
        ) : null}
        <button
          className="btn btn-secondary btn-block"
          onClick={() => {
            verifyPasswordMatchAndSubmit(
              email,
              passwordRecoveryCode,
              password1,
              password2
            );
          }}
        >
          Set New Password
        </button>
        {/* <PrimaryBtn
          btnName="Verify Password Recovery Code & New Password"
          showHide="show"
          onClick={() => {
            verifyPasswordMatchAndSubmit(
              email,
              passwordRecoveryCode,
              password1,
              password2
            );
          }}
        /> */}
        <br />

        {errorsAndStatus()}
      </div>
    );
  }
  if (step > 2) {
    props.showLogin('block');
    setStep(0);
  }
}

export default ForgotPassword;
