import React from 'react'

const KissnerCustomDelDate = ({ shipment }) => {

    let usercode = shipment.customerId
    let delByImportance = shipment.customFields.filter(customField => customField.fieldId === 10085)

    if (usercode === "kissner" && delByImportance.length) {
        return (
            <div>
                <strong>{delByImportance[0].value} Delivery Date</strong>
            </div>
        )
    } else { return null }
}

export default KissnerCustomDelDate
