import React from 'react'
import Form from "react-bootstrap/Form";

const TrackingInvoiceNumber = ({ showOrHide, clientCode, trackingNumber, shipmentObjUpdateEvent, inputFieldsReadOnly }) => {

    let trackingNumberName = "Invoice / Pro Number:"
    if (clientCode) {
        if (clientCode.includes('kwiktrip') || clientCode.includes('halliburton') || clientCode.includes('fishinco')) {
            trackingNumberName = "Pro Number:"
        }
        if (clientCode === 'Schuff' || clientCode === 'graywolf') {
            trackingNumberName = "Invoice Number:"
        }
    }

    if (showOrHide === "show" || showOrHide === "hilight") {
        return (

            <div className={`d-inline-block ${showOrHide}`}>
                {trackingNumberName}
                <br />
                <Form.Control
                    size="sm"
                    type="text"
                    name="charges.trackingNumber"
                    value={
                        trackingNumber === null
                            ? ""
                            : trackingNumber
                    }
                    onChange={(event) => shipmentObjUpdateEvent(event)}
                    disabled={inputFieldsReadOnly}
                />
            </div>

        )
    } else { return null }
}

export default TrackingInvoiceNumber
