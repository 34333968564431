import React from 'react'

const TruckNumber = ({ shipmentObjUpdateEvent, value, userData, hilightHide }) => {
    let placeholder = "For ELD Tracking"
    let className = "col-2"
    className += " " + hilightHide

    if (userData.clientCode.includes("kwiktrip") || ((userData.clientCode === "Schuff" || userData.clientCode === "graywolf") && userData.hasOwnProperty("gpsTracking") && userData.gpsMethod === "Telematics")) {
        return (
            <div class={className}>
                <label for="truckNumber" class="form-label">Truck Number:</label>
                <input type="text" name="loadDetails.truckNumber" id="truckNumber" class="form-control" placeholder={placeholder} aria-describedby="Truck Number" value={value} onChange={(event) => shipmentObjUpdateEvent(event)}></input>
            </div>
        )
    }
    else if ((userData.clientCode.includes("halliburton"))) {
        return (
            <div class={className}>
                <label for="truckNumber" class="form-label">Truck Number:</label>
                <input type="text" name="loadDetails.truckNumber" id="truckNumber" class="form-control" aria-describedby="Truck Number" value={value} onChange={(event) => shipmentObjUpdateEvent(event)}></input>
            </div>
        )
    }
    else {
        return null
    }
}

export default TruckNumber
