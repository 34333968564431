const CheckBilledVsEstCost = {
  BilledvsEstCost(billedCost, estCost, chargesMessage) {
    // console.log(
    //   "check Billed vs Est Cost",
    //   billedCost,
    //   estCost,
    //   chargesMessage
    // );
    return new Promise(function (resolve, reject) {
      let parseBilledCost = parseFloat(billedCost);
      let parseEstCost = parseFloat(estCost);
      function check() {
        if (billedCost === "") {
          resolve(true)
        }
        if (parseBilledCost === parseEstCost) {
          // console.log("BilledvsEstCost parseBilledCost === parseEstCost")
          resolve(true);
        }
        if (parseBilledCost !== parseEstCost && (!chargesMessage || chargesMessage === "")) {
          // console.log("BilledvsEstCost est and billed cost !== and no message")
          resolve("enterChargesMessage");
        } else {
          console.log("BilledvsEstCost default out true")
          resolve(true);
        }
      }
      check();
    });
  }
};

export default CheckBilledVsEstCost;
