import React, { useState, useEffect } from 'react'
import ErrorBoundary from '../../Helpers/ErrorBoundry/ErrorBoundry'
import { QueryClient, QueryClientProvider, useQuery, useQueryClient } from 'react-query'
import Rate from './Rate/Rate'
import history from "../../APIs/History";


//https://react-query-v3.tanstack.com/quick-start



const queryClient = new QueryClient()

const RatesHistory = () => {




    return (
        <ErrorBoundary>
            <QueryClientProvider client={queryClient} >
                <RatesHist />
            </QueryClientProvider>
        </ErrorBoundary>
    )
}





function RatesHist() {

    const [page, setPage] = useState(1)

    const queryReturnMax = 25

    let queryClient = useQueryClient()

    let carrierid = localStorage.getItem('carrierId')
    let clientName = localStorage.getItem('clientName')
    let clientLogo = localStorage.getItem('clientLogo')


    // carrierid = 'foremost-wowv1'

    let url = 'https://api.shippersedge.com/v2/rates/carrier/history/' + carrierid + '/' + page



    const getRatesHistFunct = fetch(url, { headers: { 'x-api-key': 'vdhy8wwjUJrwhxFpfuECTiAW' } }).then(res => res.json())

    // pagination, have to pass page as a parameter
    const { isLoading, error, data } = useQuery(['getRatesHist', page], () => getRatesHistFunct, { keepPreviousData: true })
    if (data?.length) {

    }
    const refresh = () => {

        queryClient.invalidateQueries('getRatesHist')
    }

    const pageForward = () => {
        if (data.length < queryReturnMax) return
        setPage(page + 1)

        console.log('RatesHist page forward', page + 1)

    }
    const pageBack = () => {
        if (page === 1) return
        setPage(page - 1)
        console.log('RatesHist page back', page - 1)

    }

    if (isLoading) return 'Loading...'

    if (error) return 'An error has occurred: ' + error.message

    if (data) {
        return (
            <div>
                <p>
                    <div class="card mt-5 mb-5">
                        <div class="card-header">

                            {/* <p>{data.length}</p> */}
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h1>Past Bids</h1>
                                </div>

                                <div>
                                    <img src={clientLogo} alt="client logo" height="65px" />
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p>These are bids placed in the last 365 days for {clientName}.</p>
                                </div>

                            </div>
                            <div className="d-flex justify-content-between">


                                {data.length === queryReturnMax || page > 1 ?
                                    <div>
                                        <p>Page: {page}</p>
                                        <nav>
                                            <ul class="pagination">
                                                <li
                                                    class={`page-item ${page === 1 ? 'disabled' : ''}`}
                                                    onClick={() => { pageBack() }}
                                                >
                                                    <a class="page-link" href="#">Previous</a>
                                                </li>
                                                <li
                                                    class={`page-item ${data.length < queryReturnMax ? 'disabled' : ''}`}
                                                    onClick={() => { pageForward() }}
                                                >
                                                    <a class="page-link" href="#">Next</a>
                                                </li>
                                                {/* <li
                                        onClick={() => { refresh() }}>
                                        <a class="page-link" href="#">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                                            </svg>
                                        </a>
                                    </li> */}
                                            </ul>
                                        </nav>
                                    </div>
                                    : (<div> </div>)}
                                <div className='d-flex flex-column justify-content-end'>
                                    <a href="#" onClick={() => { history.push('/Dashboard') }}>Back to Dashboard</a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">

                            <table class="table table-hover">
                                <thead>
                                    <tr>

                                        <th scope="col">Date</th>
                                        {/* <th>RH ID</th> */}
                                        <th scope="col">BOL</th>

                                        <th scope="col">Origin</th>
                                        <th scope="col">Dest</th>
                                        <th scope="col">Bid</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => {
                                        return (
                                            <Rate rateid={item.rate_hist_id} index={index} />
                                        )
                                    })}
                                </tbody>
                            </table>

                        </div>
                    </div>
                    <div>&nbsp;</div>
                </p>

            </div>
        )
    } else {
        return (<div>No data</div>)
    }



}

export default RatesHistory