// ./APIs/CheckPortalVersion.js

import { promised } from "q";

const CheckPortalVersion = {
  Check() {
    return new Promise(function (resolve, reject) {
      // console.log(
      //   "debug CheckPortalVersion.js, CheckPortalVersion()");
      let PortalName = "CarrierPortal";
      let controller = new AbortController();
      let signal = controller.signal;


      let url = "https://api.shippersedge.com/carrierportal/versionCheck?portalName=" + PortalName
      let request = new Request(url, { signal });
      // add header "x-api-key" with value vdhy8wwjUJrwhxFpfuECTiAW
      request.headers.set("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW");
      setTimeout(() => controller.abort(), 20000);
      fetch(request)
        .then(response => {
          // console.log("debug CheckPortalVersion response", response);
          if (!response.ok) {
            console.error(
              "CheckPortalVersion.js error",
              response.statusText,
              response
            );
            throw Error(response.statusText);
          }
          // console.log('debug CheckPortalVersion response', response)
          return response.text();
        })
        .then(data => {
          // console.error("debug CheckPortalVersion data", data);
          resolve(data);
        })
        .catch(err => {
          console.error("Version Check Request Failed", err);
          resolve("error");
        });

      return promised;
    });
  }
};

export default CheckPortalVersion;
