// ./Pages/TableEditItem.js

import React from "react";
import "./TableEditItem.css";
import Form from "react-bootstrap/Form";
import * as moment from "moment";
import InputGroup from "react-bootstrap/InputGroup";
import Authentication from "../../APIs/Authentication";
import UpdateShipment from "../../APIs/UpdateShipment";
import GenericModal from "../../Components/GenericModal";
import SendMessage from "../../APIs/SendMessage";

class TableEditItem extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmitUpdateShipment = this.handleSubmitUpdateShipment.bind(
      this
    );
    this.handleChangeGeneric = this.handleChangeGeneric.bind(this);
    this.checkBilledVsEstCost = this.checkBilledVsEstCost.bind(this);
    this.genericModalShow = this.genericModalShow.bind(this);
    this.genericModalHide = this.genericModalHide.bind(this);
    this.submitTableEditChanges = this.submitTableEditChanges.bind(this);
    this.checkAndSubmitChargesNotes = this.checkAndSubmitChargesNotes.bind(
      this
    );
    this.populateChargeNotes = this.populateChargeNotes.bind(this);
    this.state = {
      chargesMessage: null
    };
  }

  componentDidMount() {
    this.startUpAndUpdateLogic();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.submitTableUpdates) {
      this.submitTableEditChanges();
    }
  }

  submitTableEditChanges() {
    // console.log("submitTableEditChanges fired!");
    this.handleSubmitUpdateShipment();
  }

  startUpAndUpdateLogic() {
    // This is what happens on component did mount and update to acccount for updated data, same processes for both events, consolodating it here
    // Need status and shipent ID set from props as it is required to submit an update and the user may not trigger it on their own
    // Also need a few other status set to check if fields that have to be set at the same time are set
    this.setState({
      shipmentStatus: this.props.tableEditDataItemData.status.statusId,
      shipmentId: this.props.tableEditDataItemData.id,
      pickupDate: this.props.tableEditDataItemData.dates.pickupDate,
      trackingNumber: this.props.tableEditDataItemData.charges.trackingNumber,
      appointmentPickupDate: this.props.tableEditDataItemData.dates
        .appointmentPickupDate,
      appointmentPickupTimeIn: this.props.tableEditDataItemData.dates
        .appointmentPickupTimeIn,
      appointmentDeliveryDate: this.props.tableEditDataItemData.dates
        .appointmentDeliveryDate,
      appointmentDeliveryTimeIn: this.props.tableEditDataItemData.dates
        .appointmentDeliveryTimeIn,
      pickupTimeIn: this.props.tableEditDataItemData.dates.pickupTimeIn,
      pickupTimeOut: this.props.tableEditDataItemData.dates.pickupTimeOut,
      deliveryDate: this.props.tableEditDataItemData.dates.deliveryDate,
      deliveryTimeIn: this.props.tableEditDataItemData.dates.deliveryTimeIn,
      deliveryTimeOut: this.props.tableEditDataItemData.dates.deliveryTimeOut,
      billedCost: this.props.tableEditDataItemData.charges.billedCost,
      signedBy: this.props.tableEditDataItemData.signedBy
    });
    // if (this.props.acceptDeclineDisplay === true) {
    //   this.setState({
    //     showSendUpdates: "hide",
    //     showLoadAcceptDeclineBtns: "show  text-center"
    //   });
    // }
    // if (this.props.shipmentUpdate === true) {
    //   this.setState({
    //     showSendUpdates: "hide",
    //     showLoadChangeAcceptDeclineBtns: "show  text-center"
    //   });
    // }
    this.populateChargeNotes();
    // this.tableEditModeOnOffCard();
  }

  // Update Shipment Data
  handleSubmitUpdateShipment() {
    // console.log("handleSubmitUpdateShipment, fired");
    // Verify if billed and est cost are the same and if not check if they added a note to explain the difference and stop the submit until they do
    if (this.checkBilledVsEstCost()) {
      this.checkAndSubmitChargesNotes();
      const updateData = new FormData();
      // shipmentID, contactEmail, status are required for an update to work
      updateData.append("shipmentID", this.state.shipmentId);
      updateData.append("contactEmail", Authentication.getEmailAddress());
      updateData.append("status", this.state.shipmentStatus);
      //
      if (this.state.trackingNumber)
        updateData.append("pronum", this.state.trackingNumber);
      switch (this.state.shipmentStatus) {
        case "DEL":
          if (this.state.pickupDate === undefined) {
            alert(
              'You need to fill out the "Actual Pickup Date" before marking the load Delivered.'
            );
            return false;
          }
          if (
            this.state.trackingNumber === undefined ||
            this.state.trackingNumber === ""
          ) {
            alert(
              "You need to provide a Invoice/Pro Number before marking the load Delivered."
            );
            return false;
          }
          break;
        default:
        // console.log("default case shipmentStatus occured");
      }
      // Appointment Pickup:
      // Appointment Pickup Date
      if (this.state.appointmentPickupDate)
        updateData.append(
          "appointmentPickupDate",
          this.state.appointmentPickupDate
        );
      // Appointment Pickup Time In
      if (this.state.appointmentPickupTimeIn)
        updateData.append(
          "appointmentPickupDateIn",
          moment(this.state.appointmentPickupTimeIn, "HH:mm:").format(
            "HH:mm:ss"
          )
        );
      // Appointment Pickup Time Out (unused)
      // if (this.state.appointmentPickupTimeOut)
      //   updateData.append(
      //     "appointmentPickupDateOut",
      //     moment(this.state.appointmentPickupTimeOut, "HH:mm:").format("HH:mm:ss")
      //   );

      // Actual Pickup:
      // Actual Pickup Date
      if (this.state.pickupDate)
        updateData.append("pickupDate", this.state.pickupDate);
      // Actual Pickup Time In
      if (this.state.pickupTimeIn)
        updateData.append(
          "pickupTimeIn",
          moment(this.state.pickupTimeIn, "HH:mm:").format("HH:mm:ss")
        );
      // Actual Pickup Time Out
      if (this.state.pickupTimeOut)
        updateData.append(
          "pickupTimeOut",
          moment(this.state.pickupTimeOut, "HH:mm:").format("HH:mm:ss")
        );

      // Appointment Delivery:
      // Appointment Delivery Date
      if (this.state.apptDelDate)
        updateData.append(
          "appointmentDeliveryDate",
          this.state.appointmentDeliveryDate
        );
      // Appointment Delivery Time In
      if (this.state.appointmentDeliveryTimeIn)
        updateData.append(
          "appointmentDeliveryDateIn",
          moment(this.state.appointmentDeliveryTimeIn, "HH:mm:").format(
            "HH:mm:ss"
          )
        );
      // Appointment Delivery Time Out (unused)
      // if (this.state.appointmentDeliveryTimeOut)
      //   updateData.append(
      //     "appointmentDeliveryDateOut",
      //     moment(this.state.appointmentDeliveryTimeOut, "HH:mm:").format(
      //       "HH:mm:ss"
      //     )
      //   );

      // Actual Delivery:
      // Actual Delivery Date
      if (this.state.deliveryDate)
        updateData.append("deliveryDate", this.state.deliveryDate);
      // Actual Delivery Time In
      if (this.state.deliveryTimeIn)
        updateData.append(
          "deliveryTimeIn",
          moment(this.state.deliveryTimeIn, "HH:mm:").format("HH:mm:ss")
        );
      if (this.state.deliveryTimeOut)
        updateData.append(
          "deliveryTimeOut",
          moment(this.state.deliveryTimeOut, "HH:mm:").format("HH:mm:ss")
        );
      if (this.state.signedBy)
        updateData.append("signedBy", this.state.signedBy);
      if (this.state.billedCost)
        updateData.append("billedCost", this.state.billedCost.toString());

      // Debug FormData
      // for (var pair of updateData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }

      UpdateShipment.UpdateStatus(updateData); //add callback for some level of verification

      // Confirmation message that updates were sent
      // this.setState({ shipmentUpdateAlert: "show" });
    }
  }

  // Search the messages lot for any charges messages (anything that includes "ChargesNote: ")
  populateChargeNotes() {
    let chargesMessages = this.props.tableEditDataItemData.log.filter(
      item =>
        item.type === "Message" && item.entryDetails.includes("ChargesNote: ")
    );
    if (chargesMessages.length) {
      this.setState({
        chargesMessage: chargesMessages[0].entryDetails.slice(13)
      });
    }
  }

  // The check at the start of this is to make sure we don't resubmit the same message value each time someone submits a form, if the value is the same, doesn't submit
  // This uses the messages system to handle charges messages (in the past it used a field in carrier settlement, now using messages)
  checkAndSubmitChargesNotes() {
    // console.log("checkAndSubmitChargesNotes fired");
    let checkPropsMessages = [];
    checkPropsMessages = this.props.tableEditDataItemData.log.filter(
      item =>
        item.type === "Message" && item.entryDetails.includes("ChargesNote: ")
    );
    if (checkPropsMessages.length) {
      if (
        this.state.chargesMessage ===
        checkPropsMessages[0].entryDetails.slice(13)
      ) {
        return null;
      } else {
        //If the billed charges note is a new Message
        const prependedMsg = "ChargesNote: " + this.state.chargesMessage;
        const messageData = new FormData();
        messageData.append(
          "shipmentId",
          this.props.tableEditDataItemData.id.toString()
        );
        messageData.append("usercode", "Carrier");
        messageData.append("contactEmail", Authentication.getEmailAddress());
        messageData.append("message", prependedMsg);
        SendMessage.Send(messageData);
      }
    } else {
      //If no billed charges notes exist
      // console.log("checkAndSubmitChargesNotes last else");
      const prependedMsg = "ChargesNote: " + this.state.chargesMessage;
      const messageData = new FormData();
      messageData.append(
        "shipmentId",
        this.props.tableEditDataItemData.id.toString()
      );
      messageData.append("usercode", "Carrier");
      messageData.append("contactEmail", Authentication.getEmailAddress());
      messageData.append("message", prependedMsg);
      SendMessage.Send(messageData);
    }
  }

  // A check to see if billedCost and esttimatedCost are the same, if not pop up a modal asking them to fill out the Charges Notes: if they haven't already
  checkBilledVsEstCost() {
    // console.log("checkBilledVsEstCost fired");
    if (
      this.state.billedCost ===
      this.props.tableEditDataItemData.charges.estimatedCost
    ) {
      // console.log("checkBilledVsEstCost billed === est");
      return true;
    } else if (this.state.chargesMessage === null) {
      // console.log("checkBilledVsEstCost chargesMessage Null");
      this.setState({
        genericModalTitle: "Billed Cost Is Different Than Estimated Cost",
        genericModalBody:
          "If the Billed Cost is different than the Estimated Cost please add a note as to why in the 'Charges Notes' field next to Billed Charges.  Thank you."
      });
      this.genericModalShow();
      return false;
    } else {
      return true;
    }
  }

  genericModalShow() {
    this.setState({
      showGenericModal: true
    });
  }

  genericModalHide() {
    this.setState({ showGenericModal: false });
  }

  // This is a generic change handler that will set state based on the "name" value of the html element and set the value in state, this should work for most fields
  handleChangeGeneric(event) {
    event.preventDefault();
    console.log(
      "handleChangeGeneric",
      event.target.name,
      "-",
      event.target.value
    );
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    return (
      <div className="itemDiv shadow">
        <div className="row rowDiv">
          <div className="col-sm-2">
            BOL:
            <br />
            {this.props.tableEditDataItemData.id}
          </div>
          <div className="col-sm-2">
            Pro Number:
            <br />
            <Form.Control
              size="sm"
              type="text"
              name="trackingNumber"
              value={
                this.state.trackingNumber === null
                  ? ""
                  : this.state.trackingNumber
              }
              onChange={this.handleChangeGeneric}
            />
          </div>
          <div className="col-sm-6">
            Status:
            <br />{" "}
            <button
              name="shipmentStatus"
              onClick={this.handleChangeGeneric}
              value="PU-Out"
              type="button"
              className={
                this.state.shipmentStatus === "PU-Out"
                  ? "btn-sm btn btn-outline-dark statusButtons active"
                  : "btn-sm btn btn-outline-dark statusButtons"
              }
            >
              Picked Up
            </button>{" "}
            <button
              name="shipmentStatus"
              onClick={this.handleChangeGeneric}
              value="IT"
              className={
                this.state.shipmentStatus === "IT"
                  ? "btn-sm btn btn-outline-dark statusButtons active"
                  : "btn-sm btn btn-outline-dark statusButtons"
              }
              type="button"
            >
              In Transit
            </button>{" "}
            <button
              name="shipmentStatus"
              onClick={this.handleChangeGeneric}
              value="IT-DELAYED"
              className={
                this.state.shipmentStatus === "IT-DELAYED"
                  ? "btn-sm btn btn-outline-dark statusButtons active"
                  : "btn-sm btn btn-outline-dark statusButtons"
              }
              type="button"
            >
              Transit Delay
            </button>
            <button
              name="shipmentStatus"
              onClick={this.handleChangeGeneric}
              value="OFD"
              className={
                this.state.shipmentStatus === "OFD"
                  ? "btn-sm btn btn-outline-dark statusButtons active"
                  : "btn-sm btn btn-outline-dark statusButtons"
              }
              type="button"
            >
              Out for Delivery
            </button>{" "}
            <button
              name="shipmentStatus"
              onClick={this.handleChangeGeneric}
              value="DEL"
              className={
                this.state.shipmentStatus === "DEL"
                  ? "btn-sm btn btn-outline-dark statusButtons active"
                  : "btn-sm btn btn-outline-dark statusButtons"
              }
              type="button"
            >
              Delivered
            </button>
          </div>
        </div>
        <div className="row rowDiv">
          <div className="col-sm-2">
            <Form.Label>Appt Pickup:</Form.Label>
            <Form.Control
              name="appointmentPickupDate"
              onChange={this.handleChangeGeneric}
              type="date"
              size="sm"
              value={
                this.state.appointmentPickupDate === null
                  ? ""
                  : moment(this.state.appointmentPickupDate).format(
                    "YYYY-MM-DD"
                  )
              }
            />
          </div>
          <div className="col-sm-2">
            <Form.Label>In:</Form.Label>
            <Form.Control
              name="appointmentPickupTimeIn"
              onChange={this.handleChangeGeneric}
              className="form-control"
              type="time"
              size="sm"
              value={
                this.state.appointmentPickupTimeIn === null
                  ? ""
                  : moment(
                    this.state.appointmentPickupTimeIn,
                    "HH:mm:ss"
                  ).format("hh:mm")
              }
            />
          </div>
          <div className="col-sm-2" />
          <div className="col-sm-2">
            <Form.Label>Appt Del:</Form.Label>
            <Form.Control
              size="sm"
              name="appointmentDeliveryDate"
              onChange={this.handleChangeGeneric}
              className="form-control"
              type="text"
              type="date"
              value={
                this.state.appointmentDeliveryDate === null
                  ? ""
                  : moment(this.state.appointmentDeliveryDate).format(
                    "YYYY-MM-DD"
                  )
              }
            />
          </div>

          <div className="col-sm-2">
            <Form.Label>Del Time In:</Form.Label>
            <Form.Control
              size="sm"
              name="appointmentDeliveryTimeIn"
              onChange={this.handleChangeGeneric}
              className="form-control"
              type="time"
              value={
                this.state.appointmentDeliveryTimeIn === null
                  ? ""
                  : moment(
                    this.state.appointmentDeliveryTimeIn,
                    "HH:mm:ss"
                  ).format("HH:mm")
              }
            />
          </div>
        </div>
        <div className="row rowDiv">
          <div className="col-sm-2">
            <Form.Label>Actual Pickup:</Form.Label>
            <Form.Control
              size="sm"
              name="pickupDate"
              onChange={this.handleChangeGeneric}
              className="form-control"
              type="date"
              value={
                this.state.pickupDate === null
                  ? ""
                  : moment(this.state.pickupDate).format("YYYY-MM-DD")
              }
            />
          </div>
          <div className="col-sm-2">
            {" "}
            <Form.Label>Time In:</Form.Label>
            <Form.Control
              size="sm"
              name="pickupTimeIn"
              onChange={this.handleChangeGeneric}
              className="form-control"
              type="time"
              value={
                this.state.pickupTimeIn === null
                  ? ""
                  : moment(this.state.pickupTimeIn, "HH:mm:ss").format("hh:mm")
              }
            />
          </div>
          <div className="col-sm-2">
            {" "}
            <Form.Label>Time Out:</Form.Label>
            <Form.Control
              size="sm"
              name="pickupTimeOut"
              onChange={this.handleChangeGeneric}
              className="form-control"
              type="time"
              value={
                this.state.pickupTimeOut === null
                  ? ""
                  : moment(this.state.pickupTimeOut, "HH:mm:ss").format("HH:mm")
              }
            />
          </div>
          <div className="col-sm-2">
            {" "}
            <Form.Label>Actual Delivery:</Form.Label>
            <Form.Control
              size="sm"
              name="deliveryDate"
              onChange={this.handleChangeGeneric}
              className="form-control"
              type="text"
              type="date"
              value={
                this.state.deliveryDate === null
                  ? ""
                  : moment(this.state.deliveryDate).format("YYYY-MM-DD")
              }
            />
          </div>
          <div className="col-sm-2">
            <Form.Label>Time In:</Form.Label>
            <Form.Control
              size="sm"
              name="deliveryTimeIn"
              onChange={this.handleChangeGeneric}
              className="form-control"
              type="time"
              value={
                this.state.deliveryTimeIn === null
                  ? ""
                  : moment(this.state.deliveryTimeIn, "HH:mm:ss").format(
                    "HH:mm"
                  )
              }
            />
          </div>

          <div className="col-sm-2">
            <Form.Label>Time Out:</Form.Label>
            <Form.Control
              size="sm"
              name="deliveryTimeOut"
              onChange={this.handleChangeGeneric}
              className="form-control"
              type="time"
              value={
                this.state.deliveryTimeOut === null
                  ? ""
                  : moment(this.state.deliveryTimeOut, "HH:mm:ss").format(
                    "HH:mm"
                  )
              }
            />
          </div>
        </div>
        <div className="row rowDiv">
          <div className="col-sm-2">
            <Form.Label>Billed Charges:</Form.Label>
            <InputGroup className="mb-3" size="sm">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                size="sm"
                name="billedCost"
                type="text"
                className="form-control"
                aria-label="Amount"
                value={this.state.billedCost}
                onChange={this.handleChangeGeneric}
              />
            </InputGroup>
          </div>
          <div className="col-sm-2">
            Estimated Cost:
            <br />${this.props.tableEditDataItemData.charges.estimatedCost}
          </div>
          <div className="col-sm-4">
            <Form.Label>Charges Notes:</Form.Label>
            <InputGroup className="mb-3" size="sm">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">
                  {" "}
                  <i className="far fa-sticky-note" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                size="sm"
                name="chargesMessage"
                type="text"
                className="form-control"
                aria-label="Amount"
                value={this.state.chargesMessage}
                onChange={this.handleChangeGeneric}
              />
            </InputGroup>
          </div>

          <div className="col-sm-4">
            <Form.Label> Signed For By:</Form.Label>
            <InputGroup className="mb-3" size="sm">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-file-signature" />
                </InputGroup.Text>
              </InputGroup.Prepend>

              <Form.Control
                size="sm"
                name="signedBy"
                className="form-control"
                type="text"
                value={this.state.signedBy}
                onChange={this.handleChangeGeneric}
              />
            </InputGroup>
          </div>
        </div>
        {/* GenericModal is placed here for no reason, it has to be somewhere on this page but is not visible on the ShipmentCard */}
        <GenericModal
          show={this.state.showGenericModal}
          title={this.state.genericModalTitle}
          body={this.state.genericModalBody}
          genericModalHide={this.genericModalHide}
        />
      </div>
    );
  }
}

export default TableEditItem;
