import React, { useState } from 'react'

const Stops = ({ shipment }) => {


    const [showStops, setShowStops] = useState(false)

    const showHideStops = (event) => {
        event.stopPropagation()
        if (!showStops) {
            setShowStops(true)
        } else {
            setShowStops(false)
        }
    }



    if (shipment.stops && shipment.stops.length > 2) {
        let stops = shipment.stops
        return (<>
            <button className='mt-2' onClick={(event) => { showHideStops(event) }}>
                Stops ({shipment.stops.length})</button>
            {showStops ?





                <div className="d-flex align-content-stretch flex-wrap">

                    {stops.map((stop, index) => {
                        // console.log('stop', stop)
                        return (
                            <div key={`stopAddrs-${index}`} className="card mt-2 mb-2 ml-2 flex-grow-1">
                                <div className="card-body">
                                    <span className="bold">Stop {index + 1} ({stop.type}):</span>
                                    <br />
                                    {stop.name}
                                    <br />
                                    {stop.address.line1}
                                    <br />
                                    {stop.address.line2 ? (
                                        <div>
                                            {" "}
                                            {stop.address.line2} <br />{" "}
                                        </div>
                                    ) : null}
                                    {stop.address.city} {stop.address.state}{" "}
                                    {stop.address.zip}
                                    <br />
                                    {stop.bolNotes && stop.bolNotes !== "" ? (
                                        <div>
                                            {" "}
                                            <br />
                                            <span className=""> Notes: {stop.bolNotes}</span>
                                            <br />
                                        </div>
                                    ) : null}
                                    {stop.contact.name && stop.contact.name !== "" ? (
                                        <>
                                            {" "}
                                            <br />
                                            <span className="">
                                                {" "}
                                                Name: {stop.contact.name}
                                            </span>
                                        </>
                                    ) : null}
                                    {stop.contact.phone && stop.contact.phone !== "" ? (
                                        <>
                                            {" "}
                                            <br />
                                            <span className="">
                                                {" "}
                                                Phone: {stop.contact.phone}
                                            </span>
                                        </>
                                    ) : null}
                                    {stop.contact.email && stop.contact.email !== "" ? (
                                        <>
                                            {" "}
                                            <br />
                                            <span className="">
                                                {" "}
                                                Email: {stop.contact.email}
                                            </span>
                                        </>
                                    ) : null}
                                    {stop.contact.fax && stop.contact.fax !== "" ? (
                                        <>
                                            {" "}
                                            <br />
                                            <span className="">
                                                {" "}
                                                Fax: {stop.contact.fax}
                                            </span>
                                        </>
                                    ) : null}
                                </div>

                            </div>
                        );
                    })}

                </div>









                : null}
        </>)
    } else { return null }
}

export default Stops