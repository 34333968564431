import React from 'react'
import { Form, Col } from 'react-bootstrap'

// This is 
// masterquotes.estputime
// V2 Shipment object shipment.dates.estimatedDeliveryTime

const DeliveryETA_Time = ({ render, value, onChange, inputFieldsReadOnly }) => {
    if (!render) return null
    return (
        <Form.Group
            as={Col}
            xs
            lg="2"
        >
            <Form.Label>Delivery Time (ETA):</Form.Label>
            <Form.Control
                name="dates.estimatedDeliveryTime"
                onChange={(event) => onChange(event)}
                className="form-control"
                type="time"
                size="sm"
                value={value}
                disabled={inputFieldsReadOnly}
            />
        </Form.Group>
    )
}

export default DeliveryETA_Time