// ./APIs/GetShipments.js

import axios from "axios";
import { promised } from "q";
import Authentication from "../APIs/Authentication";

const GetShipments = {
  getShipmentsByCarrier() {
    //Checking login here as in theory someone could sit at /Dashboard long after their login expired and refresh forever unless we check here too
    Authentication.isLoggedIn();
    return new Promise(function (resolve, reject) {

      let carrierClientID = localStorage.getItem("carrierClientId")


      let carrier = localStorage.getItem("carrierId");
      let limit = 3000;
      const accountConfigs = Authentication.getUserData()
      const accountConfig = accountConfigs?.find((accountConfig) => accountConfig.carrierId === carrier);
      // console.log("accountConfig", accountConfig);

      if (accountConfig) {
        if (accountConfig?.carrierpAccountConfig) {
          const carrierpAccountConfigParsed = JSON.parse(accountConfig.carrierpAccountConfig);
          // console.log("carrierpAccountConfigParsed", carrierpAccountConfigParsed);
          if (carrierpAccountConfigParsed?.limit) {
            limit = carrierpAccountConfigParsed.limit;
          }
        }
      }

      let urlString = "https://api.shippersedge.com/v4/shipment/?limit=" + limit + "&is_trip=Y&sortBy=puavaildt2&carrier=" + carrier + "&include=all,gpsStatus,location,messages,logTypeNotice,documents,dockSchedulerQualification&translate=v2&category2=m.statusedi&operator2=notlike&search21=pend"

      let oldestShipmentMonths = -6
      if (carrierClientID === "laxseed") oldestShipmentMonths = -4
      if (carrierClientID === "kissner") oldestShipmentMonths = -12
      if (carrierClientID === "graywolf") oldestShipmentMonths = -12
      if (carrierClientID === "Schuff") oldestShipmentMonths = -12
      if (carrierClientID === "kwiktrip") oldestShipmentMonths = -12
      if (carrierClientID === "rembrandtfoods") oldestShipmentMonths = -12

      let noShipmentsBefore = new Date().setMonth(new Date().getMonth() + oldestShipmentMonths)
      let dateLimit = new Date(noShipmentsBefore).toISOString().split('T')[0]

      let limitString = "&category1=dtEntered&operator1=greater&search11=" + dateLimit

      urlString = urlString + limitString

      if (localStorage.getItem("carrierClientId") === "worldvision") {
        urlString += "&IgnoreLog=true";
      }
      if (localStorage.getItem("carrierClientId") === "Schuff" || localStorage.getItem("carrierClientId") === "graywolf") {
        // console.log("getshipments am schuff");
        urlString += "&activeOnly=false";
        // console.log("getshipments am schuff", urlString);
      }


      let config = {
        headers: {
          'x-api-key': 'vdhy8wwjUJrwhxFpfuECTiAW'
        }
      }



      // console.log(carrier);
      // In case local storage expires or otherwise isn't there, and someone refreshes /Dashboard and has no carrier defined, prevents and error condition and lets isLoggedIn carry on with a redirect to /Login
      if (!carrier) {
        console.log("No carrier defined.");
      } else {
        axios
          .get(urlString, config)
          .then((response) => {
            // console.log("getShipments response", response.data);

            resolve(response.data)

          })
          .catch((error) => {
            // console.log("getShipments error", error.response.status);
            if (error) {
              reject(error);
            }
          });
      }
      return promised;
    });
  },
  getSingleShipmentByBOL(bol) {

    Authentication.isLoggedIn();

    return new Promise(function (resolve, reject) {
      if (!bol) {
        console.error("GetShipments.js No BOL defined.");
      } else {
        // console.log("single api call bol is", bol);
        axios
          .get(
            "https://api.shippersedge.com/v2/shipment/" + bol + "?advanced=true"
          )
          .then((response) => {
            // console.log("single api call", response);
            resolve(response);
          })
          .catch((error) => {
            if (error) {
              // console.log("single api call", error);
              reject(error);
            }
          });
      }
      return promised;
    });
  },
  delayedGetSingleShipmentByBOL(bol) {
    setTimeout(this.getSingleShipmentByBOL(bol), 70000);
  },
};

export default GetShipments;
