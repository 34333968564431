import React, { useState, useEffect } from 'react'

const GPSInfo = ({ shipment }) => {
    //667577266
    //471964837
    const [componentStatus, setComponentStatus] = useState('loading')
    const [gpsLastCityAndState, setGpsLastCityAndState] = useState(null)
    const [gpsLastTimeStamp, setGpsLastTimeStamp] = useState(null)
    const [googleMapsLink, setGoogleMapsLink] = useState(null)
    const [gpsInconColor, setGpsInconColor] = useState('gray')
    const [gpsStatusDescription, setGpsStatusDescription] = useState(null)
    const [gpsStatusLevel, setGpsStatusLevel] = useState(null)

    useEffect(() => {
        if (shipment?.status?.location?.longitude) {
            setComponentStatus('loaded')
            if (shipment.status.location.address) {
                setGpsLastCityAndState(shipment.status.location.address)
            }
            if (shipment.status.location.timestamp) {
                const humanReadableTimeStamp = new Date(shipment.status.location.timestamp).toLocaleString()
                setGpsLastTimeStamp(humanReadableTimeStamp)
            }
            if (shipment.status.location.latitude && shipment.status.location.longitude) {
                const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${shipment.status.location.latitude},${shipment.status.location.longitude}`
                setGoogleMapsLink(googleMapsLink)
            }
            if (shipment.status.gpsStatus.level === 1) {
                setGpsStatusLevel(1)
                setGpsInconColor('green')
            }
            if (shipment.status.gpsStatus.level === 2) {
                setGpsStatusLevel(2)
                setGpsInconColor('yellow')
            }
            if (shipment.status.gpsStatus.level === 3) {
                setGpsStatusLevel(3)
                setGpsInconColor('red')
            }
            if (shipment.status.gpsStatus.description) {
                setGpsStatusDescription(shipment.status.gpsStatus.description)
            }
            // fetchGPSInfo(shipment)
        } else if (shipment?.status) {
            setComponentStatus('nogps')
        }

    }, [shipment])


    // const fetchGPSInfo = async (shipment) => {
    //     const url = `https://api.shippersedge.com/carrierportal/gpslast/${shipment.id}`
    //     const options = {
    //         method: 'GET',
    //         headers: {
    //             'x-api-key': 'vdhy8wwjUJrwhxFpfuECTiAW'
    //         }
    //     }
    //     try {
    //         const response = await fetch(url, options)
    //         const data = await response.json()
    //         if (data.error) {
    //             console.error('GPSInfo error', data.error)
    //             return
    //         }
    //         // if data.status and data.lastLocation are empty objects then setComponentStatus('nogps')
    //         if (Object.keys(data.status).length === 0 && data.status.constructor === Object && Object.keys(data.lastLocation).length === 0 && data.lastLocation.constructor === Object) {
    //             setComponentStatus('nogps')
    //             setGpsStatusDescription('No GPS Tracking.')
    //             return
    //         }
    //         setComponentStatus('loaded')
    //         if (data.lastLocation.city) { setGpsLastCityAndState(`${data.lastLocation.city}`) }
    //         if (data.lastLocation.state) { setGpsLastCityAndState(`${data.lastLocation.state}`) }
    //         if (data.lastLocation.city && data.lastLocation.state) { setGpsLastCityAndState(`${data.lastLocation.city}, ${data.lastLocation.state}`) }

    //         if (data.lastLocation.created_at) {
    //             const humanReadableTimeStamp = new Date(data.lastLocation.created_at).toLocaleString()
    //             setGpsLastTimeStamp(humanReadableTimeStamp)
    //         }
    //         if (data.lastLocation.latitude && data.lastLocation.longitude) {
    //             const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${data.lastLocation.latitude},${data.lastLocation.longitude}`
    //             setGoogleMapsLink(googleMapsLink)
    //         }
    //         if (data.status.level === 1) {
    //             setGpsStatusLevel(1)
    //             setGpsInconColor('green')
    //         }
    //         if (data.status.level === 2) {
    //             setGpsStatusLevel(2)
    //             setGpsInconColor('yellow')
    //         }
    //         if (data.status.level === 3) {
    //             setGpsStatusLevel(3)
    //             setGpsInconColor('red')
    //         }
    //         if (data.status.description) {
    //             setGpsStatusDescription(data.status.description)
    //         }


    //     } catch (error) {
    //         console.error('GPSInfo error', error)
    //     }
    // }

    const LoadingSpinner = () => {
        return (
            <div class="spinner-grow text-secondary" role="status">

            </div>
        )
    }

    if (!shipment) return null




    return (
        <div className="ml-2">
            <h5>GPS</h5>
            {componentStatus === 'loading' ? <LoadingSpinner /> :
                null
            }
            {componentStatus === 'loaded' ?
                <p title={gpsStatusDescription} style={{ fontSize: '0.8rem' }}>
                    <a href={googleMapsLink} target="_blank" >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16" style={{ color: gpsInconColor }}>
                            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                        </svg>
                    </a>
                    Last Location:
                    {gpsLastCityAndState}
                    &nbsp;
                    ({gpsLastTimeStamp})
                    {!gpsLastCityAndState ? <><span>{gpsStatusDescription}</span></> : null}
                </p>
                : null
            }
            {
                componentStatus === 'nogps' ?
                    <p>No GPS data available</p> : null
            }

        </div >
    )
}

export default GPSInfo