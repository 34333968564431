// 
// AccessorialRequestsByQty is a component that allows the user to request accessorials by quantity (as opposed to dollar amount in AccessorialRequest.js)
// AccessorialRequestsByQty is a mostly self contained component in that it handles its own update and submit functions and doesn't require the user to submit the larger form in Shipment.js to update the shipment
// AccessorialRequestsByQty does take its inital state from the shipment object passed from ShipmentCard.js and the accessorials available to the user from userData.
// If the user updates requests those updates and state are limited to this component
// 

import React, { useState, useEffect } from 'react'
import SubmitAccessorialRequestsByQty from './SubmitAccessorialRequestsByQtyAPICall'
const axios = require('axios');

const AccessorialRequestsByQty = ({ userData, shipment }) => {

    const [newAccesorialRequests, setNewAccesorialRequests] = useState([])
    const [requestableAccessorials, setRequestableAccessorials] = useState(userData.clientAccessorials)
    const [pendingAccessorials, setPendingAccessorials] = useState([])
    const [accessoralID, setAccessoralID] = useState(0)
    const [pricePer, setPricePer] = useState(0)
    const [totalPrice, setTotalPrice] = useState(0)
    const [unitText, setUnitText] = useState("Quantity")
    const [accessoralValue, setAccessoralValue] = useState("")
    const [readOnlyAmountAndRequest, setReadOnlyAmountAndRequest] = useState(true)
    const [existingSubmittedAccessorialRequests, setExistingSubmittedAccessorialRequests] = useState(
        shipment.accessorialRequests ? shipment.accessorialRequests : []
    )
    const [statusAlertMarkup, setStatusAlertMarkup] = useState(<div className="alert alert-warning d-inline-block mt-2">Click "Submit Accessorial Request" to send request(s).</div>)
    const [disableSubmissions, setDisableSubmissions] = useState(false)
    const [refreshMarkUp, setRefreshMarkUp] = useState(
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
        </svg>
    )

    useEffect(() => {
        if (userData?.clientAccessorials) {
            // Dereference the accessorials array to avoid mutating the original
            let accessorialsToBeFiltered = userData.clientAccessorials.map(obj => ({ ...obj }))
            if (shipment?.charges?.billedAccessorials && shipment.charges.billedAccessorials.length > 0) {
                // Create a set of the accessorial ids that have been billed to be used to filter out the billed accessorials from the requestable accessorials
                const billedAccessorialsToFilterOutIds = new Set(shipment.charges.billedAccessorials.map(obj => obj.accessorial))
                accessorialsToBeFiltered = accessorialsToBeFiltered.filter(obj => !billedAccessorialsToFilterOutIds.has(obj.accessorialID))
            }
            if (shipment?.accessorialRequests && shipment.accessorialRequests.length > 0) {
                // Create a set of the accessorial ids that have been requested to be used to filter out the requested accessorials from the requestable accessorials
                const requestedAccessorialsToFilterOutIds = new Set(shipment.accessorialRequests.filter(obj => obj.declined !== 1).map(obj => obj.accessorialId))
                accessorialsToBeFiltered = accessorialsToBeFiltered.filter(obj => !requestedAccessorialsToFilterOutIds.has(obj.accessorialID))
            }
            setRequestableAccessorials(accessorialsToBeFiltered)
        }
    }, [shipment, userData])

    if (!shipment || !userData) {
        return null
    }

    const checkSelectChange = (event) => {
        // console.log('AccessorialRequestsByQty checkSelectChange', event.target.value)
        if (event && (event.target.value !== "")) {
            setReadOnlyAmountAndRequest(false)
            setAccessoralID(event.target.value)
        }
        // get selected accessorial unit of measure
        const accessorialUnit = userData.clientAccessorials.filter((accesorial) => {
            if (parseInt(accesorial.accessorialID) === parseInt(event.target.value)) {
                return accesorial.accessorialUnit
            } else {
                return null
            }
        })
        setPricePer(event.target.selectedOptions[0].dataset.price)
        setTotalPrice(0)
        if (!!accessorialUnit && !!accessorialUnit[0]) {
            // set placeholder text to match the unit of the accessorial
            let thisUnitText = accessorialUnit[0].accessorialUnit
            // capitalize the first letter of the unit and make it plural
            thisUnitText = thisUnitText.charAt(0).toUpperCase() + thisUnitText.slice(1) + "s"
            // event.target.nextElementSibling.style.display = "block"
            setUnitText(thisUnitText)
        } else {
            setUnitText("Quantity")
            // hide the quantity input from display
            // event.target.nextElementSibling.style.display = "none"
        }
    }

    const changeaccessoralValue = (event) => {
        if (event && (event.target.value !== "")) {
            if (!!pricePer) {
                setTotalPrice((event.target.value * pricePer).toFixed(2))
            }

            setAccessoralValue(event.target.value)
        }
    }

    const clearAddedAccessorials = () => {
        setRequestableAccessorials([...requestableAccessorials, ...pendingAccessorials])
        setPendingAccessorials([])
        setNewAccesorialRequests([])
    }

    const addAccessorialRequest = (request) => {
        // console.log('AccessorialRequestsByQty addAccessorialRequest', request)
        setNewAccesorialRequests([...newAccesorialRequests, request])
    }

    const addPendingAccessorialRequest = (request) => {
        let accessorialFormDisplay = userData.clientAccessorials.filter((accesorial) => {
            if (parseInt(accesorial.accessorialID) === parseInt(accessoralID)) {
                return accesorial.accessorialFormDisplay
            } else {
                console.warn("AccessorialRequest, submitNewAccessoralReqeustToArray could not identify the accessorial selected to pick a name.")
                return null
            }
        })
        setPendingAccessorials([...pendingAccessorials, requestableAccessorials.filter((accesorial) => accesorial.accessorialID === parseInt(accessoralID))[0]])
        setRequestableAccessorials(requestableAccessorials.filter((accesorial) => {
            if (parseInt(accesorial.accessorialID) !== parseInt(accessoralID)) {
                return accesorial
            } else {
                return null
            }
        }))
        addAccessorialRequest({
            accessorialId: accessoralID,
            qty: accessoralValue,
            carrier_userId: localStorage.getItem("id"),
            formDisplayName: accessorialFormDisplay[0].accessorialFormDisplay,
            totalPrice: totalPrice,
            unitText: unitText,
            webquotecontrol: shipment.id
        })
        // reset form and state
        // Get closest sibling with id accessorialChargeByQuantity and reset it
        document.getElementById("accessorialChargeByQuantity").value = ""
        setAccessoralID(0)
        setPricePer(0)
        setTotalPrice(0)
        setUnitText("Quantity")
        setAccessoralValue("")
        setReadOnlyAmountAndRequest(true)
    }

    const submitQTYAccessorialRequestsToCPBackEnd = async (event) => {
        if (newAccesorialRequests.length === 0) {
            setStatusAlertMarkup(<div className="alert alert-warning d-inline-block mt-2">Please select an accessorial and enter a quantity.</div>)
            return null
        }
        event.preventDefault();
        setDisableSubmissions(true)
        setStatusAlertMarkup(loadinStatusgMarkup())

        // console.log('AccessorialRequestsByQty submitQTYAccessorialRequestsToCPBackEnd', newAccesorialRequests)
        let submitedAccesorialsRequest = await SubmitAccessorialRequestsByQty(newAccesorialRequests)
        if (submitedAccesorialsRequest === false) {
            setStatusAlertMarkup(errorStatusMarkup())
            getExistingAccessorialRequests()
            setDisableSubmissions(false)
            return null
        }
        setStatusAlertMarkup(successStatusMarkup)
        getExistingAccessorialRequests()
        setDisableSubmissions(false)
    }

    const initalStatusMarkup = (status) => {
        return (
            <div className="alert alert-warning d-inline-block mt-2">Click "Submit Accessorial Request" to send request(s).</div>
        )
    }



    const loadinStatusgMarkup = () => {
        return (
            <div className="alert alert-warning mt-2" role="alert">
                <h4 className="alert-heading">Loading...</h4>
                <div>Accessorial requests are being submitted.
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        )
    }

    const successStatusMarkup = () => {
        return (
            <div className="alert alert-success mt-2" role="alert">
                <h4 className="alert-heading">Success!</h4>
                <p>Accessorial requests submitted successfully.</p>
            </div>
        )
    }

    const errorStatusMarkup = () => {
        return (
            <div className="alert alert-danger mt-2" role="alert">
                <h4 className="alert-heading">Error!</h4>
                <p>Accessorial requests failed to submit.</p>
            </div>
        )
    }

    const refreshSpinner = () => {
        return (
            <div class="spinner-border spinner-border-sm" role="status"></div>
        )
    }

    const refreshNormal = () => {
        return (<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
        </svg>)
    }

    let webquotecontrol = shipment.id

    const getExistingAccessorialRequests = async (e) => {

        setRefreshMarkUp(
            refreshSpinner()
        )


        if (e) {
            e.preventDefault()
        }
        const url = 'https://api.shippersedge.com/carrierportal/accessorialRequests/' + webquotecontrol
        const headers = {
            'x-api-key': 'vdhy8wwjUJrwhxFpfuECTiAW'
        };

        await axios.get(url, { headers })
            .then(response => {
                // console.log('AccessorialRequestsByQty getExistingAccessorialRequests', response.data);
                setExistingSubmittedAccessorialRequests(response.data.accessorialRequests)
                setRefreshMarkUp(refreshNormal())
            })
            .catch(error => {
                console.error('AccessorialRequestsByQty getExistingAccessorialRequests', error);
                setRefreshMarkUp(refreshNormal())
            });
    }

    // console.log('AccessorialRequestsByQty, webquotecontrol', webquotecontrol, newAccesorialRequests)

    // Request all accessorials by the shipment id

    return (
        <fieldset id="accesorialRequestsByQuantity" className="border p-2 d-inline-block">
            <legend className="w-auto">Accessorial Requests</legend>
            <div className=" mb-3 d-flex flex-row">
                {/* Request */}
                <div className="">
                    <div >
                        <label>Request Additional Accessorial Charge(s):</label>
                    </div>
                    <div className="input-group">
                        <select className="mr-2 select form-control-sm" id="accessorialChargeByQuantity"
                            onChange={(event) => checkSelectChange(event)}
                        >
                            <option selected value="">Choose...</option>

                            {requestableAccessorials.filter(requestableAccessorial => !!requestableAccessorials).map((accesorial, index) => {
                                return (<option key={index} data-price={accesorial.accessorialUnit ? accesorial.accessortialPerUnit : accesorial.accessorialMinCharge || 1} data-unit={accesorial.accessorialUnit || '$'} value={accesorial.accessorialID}>{accesorial.accessorialFormDisplay}</option>)
                            })}
                        </select>
                        <input type="number" className="mr-2 form-control form-control-sm" placeholder={unitText}
                            readOnly={readOnlyAmountAndRequest}
                            style={{ width: '100px' }}
                            min="0"
                            onChange={(event) => changeaccessoralValue(event)}
                            value={accessoralValue}
                        />

                        <button className="mr-2 btn btn-outline-secondary btn-sm" type="button" id="accessorialChargeAddByQuantity"
                            disabled={readOnlyAmountAndRequest}
                            onClick={() => addPendingAccessorialRequest()}
                        >Add Request</button>

                    </div>
                    <div>Total Cost for Request: ${totalPrice}</div>

                    {existingSubmittedAccessorialRequests.length ?
                        (

                            <React.Fragment>
                                <div className=" mt-3 card">
                                    <div className="card-body">
                                        <label>Past Accessorial Requests:</label>
                                        <table class="table table-sm">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Reason</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {/* new array with the reverse the order of existingSubmittedAccessorialRequests */}



                                                {existingSubmittedAccessorialRequests.map((request, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{request.accessorialFormDisplay}</td>
                                                            <td>${request.amount}</td>
                                                            <td>
                                                                {request.accepted === 1 ? "Accepted" : null}
                                                                {request.declined === 1 ? "Declined" : null}
                                                                {request.declined === 0 && request.accepted === 0 ? "Pending" : null}
                                                            </td>
                                                            <td>{request.declineReason}</td>
                                                        </tr>)
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                        : null
                    }
                </div>
                {/* Selected Accessorial Requests  */}
                {newAccesorialRequests.length ?
                    (
                        <div className="mt-3 ml-4" >

                            <label>Pending Accessorial Requests:</label>
                            <div className="card">

                                <div className="card-body">
                                    <ul>
                                        {newAccesorialRequests.map((request, index) => {
                                            return (
                                                <React.Fragment key={index}><li>{request.formDisplayName}, {request.unitText}: {request.qty}, Total: ${request.totalPrice}</li></React.Fragment>
                                            )
                                        })}
                                    </ul>

                                </div>
                                <div className="card-footer bg-transparent d-flex justify-content-between">
                                    {/* submit button */}
                                    <button id="" className="btn btn-success btn-sm mr-2"
                                        onClick={(event) => submitQTYAccessorialRequestsToCPBackEnd(event)}
                                        disabled={disableSubmissions}
                                    >Submit Accessorial Request</button>



                                    <button className="btn btn-outline-secondary btn-sm"
                                        onClick={() => clearAddedAccessorials()}
                                    >Reset</button>
                                </div>
                            </div>
                            <div id="status">
                                {statusAlertMarkup}
                            </div>

                        </div>
                    )
                    : null}
                <div id="refresh" onClick={(e) => getExistingAccessorialRequests(e)}>
                    {refreshMarkUp}
                </div>
            </div >
        </fieldset>
    )
}

export default AccessorialRequestsByQty