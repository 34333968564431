// ./APIs/GetRateQuoteRequests.js

import axios from "axios";
import { promised } from "q";
import Authentication from "./Authentication";

const GetRateQuoteRequests = {
  getRateRequestsByCarrier() {
    //Checking login here as in theory someone could sit at /Dashboard long after their login expired and refresh forever unless we check here too
    Authentication.isLoggedIn();
    return new Promise(function (resolve, reject) {
      let carrier = localStorage.getItem("carrierId");
      // console.log(carrier);
      // In case local storage expires or otherwise isn't there, and someone refreshes /Dashboard and has no carrier defined, prevents and error condition and lets isLoggedIn carry on with a redirect to /Login
      if (!carrier) {
        console.log("No carrier defined.");
      } else {
        let urlString = 'https://api.shippersedge.com/v4/shipment/?getQuotesForCarrier=' + carrier + '&context=' + carrier + '&include=all&limit=2000'

        let config = {
          headers: {
            'x-api-key': 'vdhy8wwjUJrwhxFpfuECTiAW'
          }
        }

        axios
          .get(urlString, config)
          .then(response => {
            let data = response.data.data
            resolve(data);
          })
          .catch(error => {
            if (error) {
              reject(error);
            }
          });
      }
      return promised;
    });
  }
};

export default GetRateQuoteRequests;
