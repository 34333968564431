//Icons for shipment cards based on the accessorials. Example over weight, over length, etc

import React from "react";

const AccessorialIcons = ({ data, render, shipment }) => {
  // console.log('AccessorialIcons', data, render, shipment)
  // quick fix for an issue where accesorials were repeating
  let appointmentDisplayed = false
  if (render === "hide" || data === null) {
    return null;
  } else {
    // console.log('AccessorialIcons')
    return (
      <div className="mt-2">

        {data.map((item, index) => {

          if (["34"].includes(item)) {
            return (
              <span key={index}>
                <img
                  src="/icons/long.png"
                  height="20px"
                  alt="Long"
                  title="Long"
                />
              </span>
            );
          } else if (["38"].includes(item)) {
            return (
              <span key={index}>
                <img
                  src="/icons/permit.png"
                  height="20px"
                  alt="Permit"
                  title="Permit"
                />
              </span>
            );
          } else if (["36"].includes(item)) {
            return (
              <span key={index}>
                <img
                  src="/icons/tall.png"
                  height="20px"
                  alt="Tall"
                  title="Tall"
                />
              </span>
            );
          } else if (["35"].includes(item)) {
            return (
              <span key={index}>
                <img
                  src="/icons/wide.png"
                  height="20px"
                  alt="Wide"
                  title="Wide"
                />
              </span>
            );
          } else if (["37"].includes(item)) {
            return (
              <span key={index}>
                <img
                  src="/icons/overWeight_32px.png"
                  height="20px"
                  alt="Over Weight"
                  title="Over Weight"
                />
              </span>
            );
          } else if (["3"].includes(item) && appointmentDisplayed === false) {
            appointmentDisplayed = true
            return (
              <span key={index} alt="Appointment" title="Appointment" className="ml-2">
                <strong>
                  {shipment?.customerId === "glorybee" ? <span className="mr-2">Appointment Required</span> : ""}
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-calendar3" viewBox="0 0 16 16">
                    <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                    <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                  </svg>

                </strong>
              </span>
            );
          } else if (["24"].includes(item)) {
            // console.log('AccessorialIcons YES')
            appointmentDisplayed = true
            return (
              <span key={index} alt="Round Trip" title="Round Trip" className="ml-2 font-weight-bold">
                <strong>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-repeat" viewBox="0 0 16 16">
                    <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                    <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                  </svg>
                </strong>
              </span>
            );
          } else {
            return null;
          }
        })}
      </div>
    );

    // return <span>woah</span>;
    // {data.map(item => {
    //   //If the item ID matches any of the array of accessorial IDs
    //   if (([46,47,48].includes(item.id))) {
    //     return <div>Over Length</div>
    //   } else {
    //     return null
    //   }
    //   if (([49,50,51,52].includes(item.id))) {
    //     return <div>Over Width</div>
    //   }
    //   else return null
    // })}
  }
};
export default AccessorialIcons;
