import * as moment from "moment";

export const TimeFormatter = time => {
  if (time === null || time === "0001-01-01T00:00:00") {
    return "";
  } else {
    time = moment(time, "HH:mm:ss").format("HH:mm");
    return time;
  }
};

export default TimeFormatter;
