// ./APIs/AcceptShipment.js

const AcceptShipment = {
  async AcceptShipment(shipmentId, secureId, userEmail, userName) {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW");

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    let url = "https://api.shippersedge.com/v4/shipment/" + shipmentId + "/acceptTender?secureId=" + secureId;
    if (userEmail) {
      url += "&userEmail=" + encodeURIComponent(userEmail);
    }
    if (userName) {
      url += "&userName=" + encodeURIComponent(userName);
    }
    console.log('accept url', url)
    let fetchResult = await fetch(url, requestOptions)
      .then(response => response.json())
      .then(result => result)
      .catch(error => console.warn('AcceptShipment error', error))

    return fetchResult;
  }
};
export default AcceptShipment;


