import React from 'react'

const BrowserWarning = () => {
    if (window.navigator.userAgent.indexOf("Edge") > -1) {
        return (
            <div className="alert alert-warning" role="alert">
                <p>It looks like you are browsing using Microsoft Edge or Internet Explorer.</p>
                <p>At this time only <a href="https://www.google.com/chrome/"> Chrome</a> or <a href="https://www.mozilla.org/firefox/">Firefox</a> are supported for use with the Carrier Portal.</p>
                <p>Microsoft Edge will be supported at a later date.</p>
            </div>
        )
    } else { return null }
}

export default BrowserWarning
