// ./APIs/MarkMessageRead.js

import axios from "axios";
import { promised } from "q";

const MarkMessageRead = {
  MarkAllMessagesRead(shipmentID) {
    console.log("MarkMessageRead.js Fired", shipmentID);
    return new Promise(function(resolve, reject) {
      axios
        .get(
          "https://schuff.shippersedge.com/public/carrier/markAllMessagesRead.cfm?&notices=true&wsdl&i=" +
            shipmentID
        )
        .then(response => {
          // console.log("MarkMessageRead.js, response:", response);
          resolve(response);
        })
        .catch(error => {
          if (error) {
            reject("MarkMessageRead.js, error:", error);
          }
        });
      return promised;
    });
  }
};

export default MarkMessageRead;
