// ./APIs/GetShipments.js

import { promised } from "q";
import Authentication from "../APIs/Authentication";

const CheckInvoiceNum = {
    CheckInvoiceNum(webquotecontrol, carrierId, invoiceNum, timeOutValue) {
        // console.log("check invoiceNum", webquotecontrol, invoiceNum);
        Authentication.isLoggedIn();
        return new Promise(function (resolve, reject) {
            if (invoiceNum === null || invoiceNum === "" || invoiceNum === undefined) {
                resolve(false);
            } else {
                // console.log(
                //   "debug CheckInvoiceNum.js, CheckInvoiceNum()",
                //   webquotecontrol,
                //   carrierId,
                //   invoiceNum,
                //   timeOutValue
                // );
                let controller = new AbortController();
                let signal = controller.signal;
                let url =
                    "https://www2.shippersedge.com/public/carrier/checkInvoiceNumber.cfm?webquotecontrol=" +
                    webquotecontrol +
                    "&carrierid=" +
                    carrierId +
                    "&invoiceNum=" +
                    invoiceNum +
                    "&wsdl";
                let request = new Request(url, { signal });
                setTimeout(() => controller.abort(), timeOutValue);
                fetch(request)
                    .then(response => {
                        // console.log("debug CheckInvoiceNum response", response);
                        if (!response.ok) {
                            console.error(
                                "CheckInvoiceNum.js error",
                                response.statusText,
                                response
                            );
                            throw Error(response.statusText);
                        }
                        return response.json();
                    })
                    .then(data => {
                        // console.error("debug CheckInvoiceNum data", data);
                        resolve(data.used);
                    })
                    .catch(err => {
                        console.error("Request failed", err);
                        resolve("Aborted");
                    });
            }
            return promised;
        });
    }
};

export default CheckInvoiceNum;
