import React from 'react'

const CF10161 = ({ shipment }) => {
    let exportPackersApptConfNum
    if (shipment.customFields) {
        let customField10161 = shipment.customFields.find(cf => cf.fieldId === 10161)
        if (customField10161 && customField10161.value !== "") {
            exportPackersApptConfNum = customField10161.value
        }
    }
    if (exportPackersApptConfNum) {
        return (
            <div class="alert alert-info mt-3 border-dark rounded" role="alert">Appt Conf. #:<br /> {exportPackersApptConfNum}</div>
        )
    }
    return null
}

export default CF10161