import React from 'react'

const PickupRequestDoc = ({ shipment, render }) => {

    if (shipment && render === "show") {
        return (
            <div>
                <a
                    href={
                        "https://www2.shippersedge.com/includes/cfm/static_other.cfm?d=pickuprequest&i=" +
                        shipment.id +
                        "&print&wsdl"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Open Pickup Request In New Tab"
                >
                    Pickup Request
                </a>
            </div>
        )
    } else { return null }
}

export default PickupRequestDoc
