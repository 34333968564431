

const CheckDockScheduleQualification = async (shipment) => {
    if (shipment) {

        if (shipment.status.statusId === 'DEL' || shipment.settlementId !== null) {
            return false
        }

        if (!!shipment.loadDetails?.dockSchedulerQualification) {
            // console.log('DockSchedulerLink2', shipment.loadDetails?.dockSchedulerQualification?.qualified)
            return shipment.loadDetails.dockSchedulerQualification?.qualified
        }
        // console.log('CheckDockScheduleQualification', shipment)

        var myHeaders = new Headers();
        myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW");
        myHeaders.append("Content-Type", "application/json");

        let body = {
            webquotecontrol: shipment.id,
            usercode: shipment.customerId,
            shipperid: shipment.shipper.id,
            consigneeid: shipment.consignee.id,
            pickupDate: shipment.dates?.availableDate2,
            deliveryDate: shipment.dates?.destAvailableDate2,
            deliveryDate2: shipment.dates?.requestedDeliveryDate,
            debug: false
        }

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(body)
        };

        let qualified = false
        qualified = await fetch("https://api.shippersedge.com/schedule/qualification/", requestOptions)
            .then(response => response.json())
            .then((result) => {
                return result.qualified
            })
            .catch(error => console.warn('AcceptShipment error', error))

        // console.log('CheckDockScheduleQualification', qualified)
        return qualified
    }
    else {
        return false
    }
}

export default CheckDockScheduleQualification;