import DateFormatter from "./DateFormatter";
import TimeFormatter from "./TimeFormatter";
import NullCheck from "./NullCheck";

export const ShipmentsDataNormalization = shipments => {

  let newShipments = []

  shipments.forEach(shipment => {
    // Charges
    // console.log('shipment', shipment)

    try {
      shipment.charges.trackingNumber ?
        shipment.charges.trackingNumber = NullCheck(
          shipment.charges.trackingNumber
        ) : shipment.charges.trackingNumber = null

      shipment.charges.invoiceNumber = NullCheck(shipment.charges.invoiceNumber);
      shipment.charges.invoiceDate = DateFormatter(shipment.charges.invoiceDate);
      shipment.charges.billedCost = NullCheck(shipment.charges.billedCost);
      if (shipment.charges.rateQuote !== null) {
        shipment.charges.rateQuote.carrierId = NullCheck(
          shipment.charges.rateQuote.carrierId
        );
        shipment.charges.rateQuote.secureId = NullCheck(
          shipment.charges.rateQuote.secureId
        );
      }
      shipment.charges.settlementDate = DateFormatter(
        shipment.charges.settlementDate
      );
      // Requested Delivery
      shipment.dates.requestedDeliveryDate = DateFormatter(
        shipment.dates.requestedDeliveryDate
      );

      // Appt Pickup
      shipment.dates.appointmentPickupDate = DateFormatter(
        shipment.dates.appointmentPickupDate
      );
      shipment.dates.appointmentPickupTimeIn = TimeFormatter(
        shipment.dates.appointmentPickupTimeIn
      );
      shipment.dates.appointmentPickupTimeOut = TimeFormatter(
        shipment.dates.appointmentPickupTimeOut
      );

      // Appt Del
      shipment.dates.appointmentDeliveryDate = DateFormatter(
        shipment.dates.appointmentDeliveryDate
      );
      shipment.dates.appointmentDeliveryTimeIn = TimeFormatter(
        shipment.dates.appointmentDeliveryTimeIn
      );
      shipment.dates.appointmentDeliveryTimeOut = TimeFormatter(
        shipment.dates.appointmentDeliveryTimeOut
      );

      // Available Date
      shipment.dates.availableDate1 = DateFormatter(
        shipment.dates.availableDate1
      );
      shipment.dates.availableDate2 = DateFormatter(
        shipment.dates.availableDate2
      );

      // Actual Pickup
      shipment.dates.pickupDate = DateFormatter(shipment.dates.pickupDate);
      shipment.dates.pickupTimeIn = TimeFormatter(shipment.dates.pickupTimeIn);
      shipment.dates.pickupTimeOut = TimeFormatter(shipment.dates.pickupTimeOut);

      // Estimated Delivery
      shipment.dates.estimatedDeliveryDate = DateFormatter(
        shipment.dates.estimatedDeliveryDate
      );
      // Actual Delivery
      shipment.dates.deliveryDate = DateFormatter(shipment.dates.deliveryDate);
      shipment.dates.deliveryTimeIn = TimeFormatter(
        shipment.dates.deliveryTimeIn
      );
      shipment.dates.deliveryTimeOut = TimeFormatter(
        shipment.dates.deliveryTimeOut
      )
      newShipments.push(shipment)

    } catch {
      console.log('ShipmentsDataNormalization.js ShipmentsDataNormalization catch', shipment)
    }
  });

  return newShipments;
};

export default ShipmentsDataNormalization;
