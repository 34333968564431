import React from "react";

export const GuaranteedDeliveryIcon = ({ render, shipment }) => {
  if (render === "show" && shipment && shipment.loadDetails.guaranteed === 1) {
    return (
      <span style={{ color: "red" }}>
        <i
          className="fas fa-stopwatch"
          alt="Time Sensitive Delivery"
          title="Time Sensitive Delivery"
        ></i>
        <br />
        Firm Delivery Date
      </span>
    );
  } else {
    return null;
  }
};

export default GuaranteedDeliveryIcon;
