import React from "react";

const BillingThirdPartyCollect = ({ render, billTo }) => {
  if (render === "show" && billTo) {
    if (billTo.type === "COLLECT" || billTo.type === "THIRD PARTY") {
      return (
        <>
          <br />
          Billing: <b>{billTo.type}</b>
          <br />
          <span style={{ fontSize: "0.9em" }}>
            {billTo.name ? (
              <>
                {billTo.name} <br />{" "}
              </>
            ) : null}
            {billTo.address.line1 ? (
              <>
                {billTo.address.line1} <br />{" "}
              </>
            ) : null}
            {billTo.address.line2 ? (
              <>
                {billTo.address.line2} <br />{" "}
              </>
            ) : null}
            {billTo.address.city ? <>{billTo.address.city}, </> : null}
            {billTo.address.state ? <>{billTo.address.state} </> : null}
            {billTo.address.zip ? <>{billTo.address.zip} </> : null}
          </span>
        </>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export default BillingThirdPartyCollect;
