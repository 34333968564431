import React from 'react'
import NumberFormat from "react-number-format";
import PaymentStatus from "./PaymentStatus";
import { getShipmentV4 } from '../../../APIs/Helpers';

const ChargesV1 = ({ shipment, paymentStatusHide, shipmentV4 }) => {
    if (!shipment || !shipmentV4) { return null }
    if (shipment && shipmentV4) {
        return (
            <div>
                <span className='cardBodyRow1'>Charges:</span>
                {shipmentV4?.details?.accessorialDetails?.length ?

                    <div>
                        Accessorials:
                        <ul>
                            {shipmentV4.details.accessorialDetails.map((accessorial) => {
                                return (
                                    <li key={accessorial.id}>
                                        {accessorial.formDisplay}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>

                    : null}
                <div className={`${paymentStatusHide}`}>
                    Payment Status: <PaymentStatus shipment={shipment} />
                </div>
                {shipment.charges.billedCost ? 
                    <div>
                    Billed:{" "}
                        <NumberFormat
                            value={shipment.charges.billedCost}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                        />
                    </div> : null}
                

                <div>
                    Est. Cost:{" "}
                    {shipment?.chargesList && shipment.chargesList.length ?
                        shipment.chargesList.map((charge, index) => {
                            if (charge.type === "estimatedCost") {
                                return (
                                    <NumberFormat
                                        key={index}
                                        value={charge.total}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"$"}
                                    />
                                )
                            } else {
                                return null
                            }

                        })

                        : null}

                </div>
            </div>
        )
    }
}

export default ChargesV1
