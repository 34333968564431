// ./APIs/SendMessage.js

import axios from "axios";
import { promised } from "q";

const SendMessage = {
  Send(messageData) {
    // console.log("SendMessage.js Fired");
    return new Promise(function (resolve, reject) {
      axios
        .post(
          "https://www2.shippersedge.com/public/carrier/newMessage.cfm?wsdl",
          messageData
        )
        .then((response) => {
          // console.log("SendMessage.js, response:", response);
          resolve(response);
        })
        .catch((error) => {
          if (error) {
            reject("SendMessage.js, error:", error);
          }
        });
      return promised;
    });
  },
};

export default SendMessage;
