import React, { useState } from 'react'
import { Col } from 'react-bootstrap'

const BilledCostBreakdownReadOnly = ({ render, userData, shipment }) => {

    const [show, setShow] = useState(false);

    if (!render) return null
    if (!shipment) return null
    if (!userData) return null
    if (!shipment?.newBilling?.cost?.billed) return null









    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const billedCost = shipment.newBilling.cost.billed
    const accessorialRequests = shipment.newBilling.cost.accessorialRequests

    const Base = ({ billedCost }) => {
        if (!billedCost) return null
        if (!billedCost?.base) return null
        return (
            <li class="list-group-item d-flex justify-content-between align-items-center">
                Base
                <span class="font-weight-bold">$ {billedCost.base}</span>
            </li>
        )
    }

    const Fuel = ({ billedCost }) => {
        if (!billedCost) return null
        if (!billedCost?.fuel) return null
        return (
            <li class="list-group-item d-flex justify-content-between align-items-center">
                Fuel
                <span class="font-weight-bold">$ {billedCost.fuel}</span>
            </li>
        )
    }

    const Accessorials = ({ billedCost, accessorialRequests }) => {
        if (!billedCost) return null
        if (!billedCost?.accessorials) return null
        return (
            <>
                {billedCost.accessorials.map((accessorial, index) => {
                    const derivedAccessorialName = accessorialRequests.find(accessorialRequest => accessorialRequest.accessorialId === accessorial.accessorial)?.accessorialName;
                    return (
                        <li class="list-group-item d-flex justify-content-between align-items-center" key={index}>
                            {derivedAccessorialName ? derivedAccessorialName : <span>Accessorial {accessorial.accessorial}</span>}
                            <span class="font-weight-bold">$ {accessorial.total}</span>
                        </li>
                    )
                })}
            </>
        )
    }




    if (!show) {

        return (
            <Col xs lg="3">




                <span className="btn btn-outline-dark mb-2 mt-2" onClick={() => handleShow()}>
                    Billing Detail 🧾
                </span>

            </Col>

        )
    } else {

        return (
            <Col xs lg="6">
                <div class="mt-2 mb-2">
                    <div class="card shadow">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="card-title">Charges Breakdown</h5>
                                <button type="button" class="close align-self-start" aria-label="Close" onClick={() => handleClose()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <ul class="list-group">
                                <Base billedCost={billedCost} />

                                <Fuel billedCost={billedCost} />

                                <Accessorials billedCost={billedCost} accessorialRequests={accessorialRequests} />
                            </ul>

                            <div class="mt-3">
                                <hr />
                                <div class="d-flex justify-content-between align-items-center">
                                    <h6>Total</h6>
                                    <span class="font-weight-bold">
                                        {billedCost?.total ?
                                            (<span>$ {billedCost.total}</span>) :
                                            (<span>Not Available</span>)}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        )
    }
}

export default BilledCostBreakdownReadOnly