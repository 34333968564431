import React from "react";

const Input01 = ({
  name,
  showHide,
  type,
  extraclassName,
  size,
  value,
  onChange,
  onBlur, 
  readOnly,
  id,
  step,
  min,
  placeholder,
  symbol,
  onFocus,
  autocomplete,
  isRequired = false

}) => {
  let inputSize = "";
  if (size === "small") {
    inputSize = "input-group-sm";
  }
  if (size === "large") {
    inputSize = "input-group-lg";
  }

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, ""); // remove non-numeric characters
    onChange({ target: { name, value: numericValue } });
  };

  const handlePhoneBlur = (e) => {
    const value = e.target.value;
    const formattedValue = value
      .replace(/\D/g, "") // remove non-numeric characters
      .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"); // format with hyphens
    e.target.value = formattedValue;
  };

  if (showHide === "show") {
    return (
      <span>
        <div className={`input-group mb-3 ${inputSize}`}>
          {symbol ? (
            <div className="input-group-prepend">
              <span className="input-group-text">{symbol}</span>
            </div>
          ) : null}
          <input
            readOnly={readOnly}
            id={id}
            type={type}
            step={step}
            value={value}
            min={min}
            placeholder={placeholder}
            className="form-control"
            name={name}
            onChange={type === "tel" ? handlePhoneChange : onChange} 
            onFocus={onFocus}
            autoComplete={autocomplete}
            onBlur={type === "tel" ? handlePhoneBlur : onBlur}
            pattern={type === "tel" ? "[0-9]*" : undefined}
            required={isRequired} 
          />
        </div>{" "}
      </span>
    );
  } else {
    return null;
  }
};
export default Input01;
