// Ocasionally the API sends some "0001-01-01T00:00:00" date/times and the carrier portal takes those and displays them, then when the customer updates they're submitted back to the DB.  Filtering them out there both before displaying and before

// DateChecker returns true if the date isn't a bad date, false if it is a bad date
export const DateChecker = date => {
  if (date === "0001-01-01T00:00:00") {
    return false;
  } else {
    return true;
  }
};

export default DateChecker;
