import React, {useState} from 'react'

const NavNotifcations = () => {

    const [renderVideoIconFull, setRenderVideoIconFull] = useState(
        localStorage.getItem('navNotifications') === null ? "true" : localStorage.getItem('navNotifications')
    )

    const onClickSetLocalStorageFalse = () => {
        localStorage.setItem('navNotifications', 'false')
        setRenderVideoIconFull('false')
    }

    if (renderVideoIconFull === "true") {
        return (
            <span className="mr-3 pointer" onClick={()=> onClickSetLocalStorageFalse()} title="YouTube Carrier Portal Tutorials">
                <a href="https://www.youtube.com/playlist?list=PLIZk-HGiRViR8SstYjNFk52NEzbiuPOfP" target='_blank'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="green" className="bi bi-collection-play-fill" viewBox="0 0 16 16">
                    <path d="M2.5 3.5a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-11zm2-2a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7zm6.258-6.437a.5.5 0 0 1 .507.013l4 2.5a.5.5 0 0 1 0 .848l-4 2.5A.5.5 0 0 1 6 12V7a.5.5 0 0 1 .258-.437z"/>
                    </svg>
                </a>
            </span>
        )
    }
    if (renderVideoIconFull === "false") {
        return (
            <span className="mr-3 pointer" onClick={()=> onClickSetLocalStorageFalse()} title="YouTube Carrier Portal Tutorials">
                <a href="https://www.youtube.com/playlist?list=PLIZk-HGiRViR8SstYjNFk52NEzbiuPOfP" target='_blank'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="black" className="bi bi-collection-play" viewBox="0 0 16 16">
                    <path d="M2 3a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 0-1h-11A.5.5 0 0 0 2 3zm2-2a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7A.5.5 0 0 0 4 1zm2.765 5.576A.5.5 0 0 0 6 7v5a.5.5 0 0 0 .765.424l4-2.5a.5.5 0 0 0 0-.848l-4-2.5z"/>
                    <path d="M1.5 14.5A1.5 1.5 0 0 1 0 13V6a1.5 1.5 0 0 1 1.5-1.5h13A1.5 1.5 0 0 1 16 6v7a1.5 1.5 0 0 1-1.5 1.5h-13zm13-1a.5.5 0 0 0 .5-.5V6a.5.5 0 0 0-.5-.5h-13A.5.5 0 0 0 1 6v7a.5.5 0 0 0 .5.5h13z"/>
                    </svg>
                </a>
            </span>
        )
    } else {
        return (
            null
        )
    }   
}

export default NavNotifcations