// ./APIs/DeclineShipment.js

const DeclineShipment = {
  async DeclineShipment(shipmentId, secureId, userEmail, userName) {
    // console.log('DeclineShipment', shipmentId, secureId)
    var requestOptions = {
      method: 'GET'
    };

    let url = "https://app.shippersedge.com/public/carrier/declineTender.cfm?asAPI=true&i=" + shipmentId + "&secid=" + secureId;

    if (userEmail) {
      url += "&userEmail=" + encodeURIComponent(userEmail);
    }
    if (userName) {
      url += "&userName=" + encodeURIComponent(userName);
    }

    let fetchResult = await fetch(url, requestOptions)
      .then(response => response.json())
      .then(result => result)
      .catch(error => console.warn('DeclineShipment error', error))

    return fetchResult;
  }
};
export default DeclineShipment;
