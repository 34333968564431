//Icons for shipment cards based on the accessorials. Example over weight, over length, etc

import React, { useState } from "react";

const Packages = ({ render, shipment }) => {
  const carrierClientId = localStorage.getItem("carrierClientId");

  // console.log('Packages', shipment.packages)

  const [showAllHUs, setShowAllHUs] = useState(false)


  if (render === "hide" || shipment === null || !shipment.packages || !shipment.packages.length) {
    return null;
  }

  let handlingUnits = shipment.packages
  // console.log('handlingUnits', handlingUnits)
  const showHideHandlingUnits = () => {
    if (!showAllHUs) {
      setShowAllHUs(true)
    } else {
      setShowAllHUs(false)
    }
  }



  // Schuff requires dimensions in feet
  if (carrierClientId === "Schuff" || carrierClientId === "graywolf") {
    handlingUnits.forEach(hu => {
      hu.dimensionLength = hu.dimensionLength / 12
      hu.dimensionWidth = hu.dimensionWidth / 12
      hu.dimensionHeight = hu.dimensionHeight / 12
    });
  }

  if (render === "show")
    return (
      <div>
        <button
          className="mt-2 btn btn-outline-secondary btn-sm"
          onClick={() => { showHideHandlingUnits() }}>
          Handling Units ({handlingUnits.length}):
        </button>
        {showAllHUs ?
          <>
            <table class="table table-striped table-bordered table-sm  mt-2">
              <tbody>
                {handlingUnits.map((item, key) => {
                  return (
                    <tr>
                      <td>
                        <span className="ml-1" key={item.id}>
                          {item.quantity} {item.packaging.charAt(0).toUpperCase() + item.packaging.slice(1)}:
                          <br />
                          <span className="ml-2">Dimensions {item.dimensionLength} x{" "}
                            {item.dimensionWidth} x {item.dimensionHeight}</span>
                          <br />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>

          : null

        }


      </div>
    );

};
export default Packages;
