// This is based off of:
// https://gist.github.com/MostlyFocusedMike/e3b8e38ed6d525235f5205d4cc905a5a
// https://itnext.io/how-to-build-a-dynamic-controlled-form-with-react-hooks-2019-b39840f75c4f

import React from "react";

const Input03 = ({
  idx,
  accessorialState,
  handleAccessorialChange,
  // accessorialName,
  symbol
}) => {
  const accessorialId = `price-${idx}`;
  return (
    <span>
      <div key={`price-${idx}`} className="input-group mb-3 input-group-sm">
        {symbol ? (
          <div className="input-group-prepend">
            <span className="input-group-text">{symbol}</span>
          </div>
        ) : null}
        <input
          type="number"
          step="0.01"
          min="0"
          placeholder="0.00"
          step=".01"
          name="price"
          data-idx={idx}
          id={accessorialId}
          className="form-control"
          value={accessorialState[idx].price}
          onChange={handleAccessorialChange}
        />
      </div>
    </span>
  );
};

export default Input03;
