import React from 'react'
import { Form, Col } from 'react-bootstrap'

// This is 
// masterquotes.estpudate
// V2 Shipment object shipment.dates.estimatedPickup

const PickupETA = ({ render, value, onChange, shipment, inputFieldsReadOnly }) => {
    if (!render) return null
    return (
        <Form.Group
            as={Col}
            xs
            lg="2"
        >
            <Form.Label>Pickup Date (ETA):</Form.Label>
            <Form.Control
                size="sm"
                name="dates.estimatedPickup"
                onChange={(event) => onChange(event)}
                className="form-control"
                type="date"
                value={value}
                disabled={inputFieldsReadOnly}

            />
        </Form.Group>
    )
}

export default PickupETA