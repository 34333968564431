import React from "react";

const PaperClipIconAttachments = ({ onClick, numDocuments }) => {
  if (numDocuments === 0) {
    return null;
    //   <>
    //     <font color="#AAAFB4">
    //       <i
    //         className="fas fa-paperclip"
    //         title={`${numDocuments} Documents Attached`}
    //         onClick={onClick}
    //       ></i>
    //     </font>
    //   </>
  } else {
    return (
      <>
        <i
          className="fas fa-paperclip"
          title={`${numDocuments} Documents Attached`}
          onClick={onClick}
        ></i>
      </>
    );
  }
};

export default PaperClipIconAttachments;
