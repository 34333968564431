import React from 'react'
import { GoogleMapsLink } from '.'
import CopyToClipboard from '../../CopyToClipboard/CopyToClipboard'
import ConsigeeDocs from "../../ShipmentCard/Components/ConsigeeDocs";

const DeliveryAddress = ({ shipment }) => {
    if (shipment) {

        let fullAddress = shipment.consignee.name + " " + shipment.consignee.address.line1
        if (shipment.consignee.address.line2 && shipment.consignee.address.line2 !== "") {
            fullAddress = fullAddress + " " + shipment.consignee.address.line1
        }
        fullAddress = fullAddress + " " + shipment.consignee.address.city + " " + shipment.consignee.address.state + " " + shipment.consignee.address.zip

        // console.log('fulldel', fullAddress)

        return (
            <div>
                {shipment.stops && shipment.stops.length > 2 ? (
                    (<><span className='cardBodyRow1 font-weight-bold'>Final Delivery:</span> <br /></>)
                ) : (
                    (<><span className='cardBodyRow1 font-weight-bold' style={{}}>Delivery:</span><br /></>)
                )}
                {shipment.consignee.name}<br />
                {shipment.consignee.address.line1}<br />
                {shipment.consignee.address.line2 && shipment.consignee.address.line2 !== "" ?
                    <>
                        {shipment.consignee.address.line2} <br />
                    </>

                    : null}

                {shipment.consignee.address.city},{" "}
                {shipment.consignee.address.state}{" "}
                {shipment.consignee.address.zip}
                {shipment.customerId.includes("halliburton") && shipment.shipper?.coordinates?.latitude && shipment.shipper?.coordinates?.longitude ?
                    <div>
                        Coordinates: {shipment.shipper.coordinates.latitude}, {shipment.shipper.coordinates.longitude}
                    </div>
                    : null}
                <div className='d-flex'>
                    <CopyToClipboard text={fullAddress} color={"#4c8bf5"} message={'Copied Delivery Address to Clipboard'} />
                    <div className='mr-1'></div>
                    <GoogleMapsLink address={fullAddress} />
                </div>
                <div>
                    <ConsigeeDocs shipment={shipment} />
                </div>
            </div>
        )
    } else {
        return null
    }
}

export default DeliveryAddress
