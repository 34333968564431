export const SubmitAccessorialRequests = async (accessorialsArray) => {
    console.log('SubmitAccessorialRequests', accessorialsArray)
    // Retries here are for bad internet connections and timeouts when updates are sent.   
    let success = true
    for (let accessorial of accessorialsArray) {
        console.log('yo i am submitting accessorial ', accessorial)

        // Submit Requests

        let firstTry = await sendUpdate(accessorial, 10000)
        console.log('SubmitAccessorialRequests first', firstTry)
        if (firstTry.result === 'success') {
            success = true
        } else {

            let secondTry = await sendUpdate(accessorial, 20000)
            console.log('SubmitAccessorialRequests second', secondTry)
            if (secondTry.result === 'success') {
                success = true
            } else {
                let thridTry = await sendUpdate(accessorial, 45000)
                console.log('SubmitAccessorialRequests third', thridTry)
                if (thridTry.result === 'success') {
                    success = true
                } else {
                    console.warn("SubmitAccessorialRequests saw an api call fail 3x")
                    success = false
                }
            }
        }


        async function sendUpdate(accessorial, timeOutValue) {
            // console.log('SubmitAccessorialRequests sendUpdate', accessorial, timeOutValue)
            return new Promise(function (resolve, reject) {
                let controller = new AbortController();
                var signal = controller.signal;
                let url =
                    "https://api.shippersedge.com/billing/accessorial-requests/" + accessorial.webquotecontrol

                let myHeaders = new Headers();
                myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW");
                myHeaders.append("content-type", "application/json");
                var requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    redirect: "follow",
                    signal: signal,
                    body: JSON.stringify(accessorial)
                };

                let request = new Request(url, requestOptions);

                if (timeOutValue) {
                    setTimeout(() => controller.abort(), timeOutValue);
                }
                fetch(request)
                    .then((response) => {
                        // console.log(
                        //   "debug SubmitAccessorialRequests.js response",
                        //   response
                        // );
                        if (!response.ok) {
                            console.error(
                                "SubmitAccessorialRequests.js error",
                                response.statusText,
                                response
                            );
                            throw Error(response.statusText);
                        }
                        // console.log("SubmitAccessorialRequests.js", response);
                        return response.json();
                    })
                    .then((response) => {

                        resolve(response);
                    })
                    .catch((err) => {
                        console.error("SubmitAccessorialRequests API Request failed", err);
                        resolve(err);
                    });
            })
        }
    }
    return success
};

export default SubmitAccessorialRequests