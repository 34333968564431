import React from 'react'
import { Form, Col } from 'react-bootstrap'

// This is 
// masterquotes.estdeldate
// V2 Shipment object shipment.dates.estimatedDelivery

const DeliveryETA = ({ render, name, onChange, value, inputFieldsReadOnly }) => {
    if (!render) return null
    return (
        <Form.Group
            as={Col}
            xs
            lg="2"
        >
            <Form.Label>Delivery Date (ETA):</Form.Label>
            <Form.Control
                size="sm"
                name={name}
                onChange={(event) => onChange(event)}
                className="form-control"
                type="date"
                value={value}
                disabled={inputFieldsReadOnly}
            />
        </Form.Group>
    )
}

export default DeliveryETA