// ./Pages/TableEditView.js

import React from "react";
import TableEditItem from "../Components/TableEditItem/TableEditItem";
import Alert from "react-bootstrap/Alert";

class TableEditView extends React.Component {
  constructor(props) {
    super(props);
    this.submitTableEdits = this.submitTableEdits.bind(this);
    this.state = {
      sendTableEditUpdates: false,
      updatedAlertClass: "hide"
    };
  }

  // sendTableEditUpdates is sent as a prop to the table items, that changes here and then inside the table item triggers a "componentDidUpdate" event where they submit the updates in each update item
  submitTableEdits() {
    this.setState(
      {
        sendTableEditUpdates: true,
        updatedAlertClass: "show"
      },
      () => this.setState({ sendTableEditUpdates: false })
    );
  }

  render() {
    return (
      <div>
        {/* Table Edit View<br/> */}
        {this.props.tableEditData.map(item => (
          <TableEditItem
            key={item.id}
            tableEditDataItemData={item}
            submitTableUpdates={this.state.sendTableEditUpdates}
          />
        ))}
        <button
          type="button"
          className="btn btn-outline-success shadow"
          onClick={this.submitTableEdits}
        >
          Submit Changes
        </button>
        <span className={this.state.updatedAlertClass}><Alert variant="success">Shipments Updated!</Alert></span>
      </div>
    );
  }
}

export default TableEditView;
