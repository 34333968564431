import React from "react";

const ShipperConsSummary = ({
  render,
  shipment,
  mode
}) => {


  if (render === "hide" || !shipment) {
    return null;
  }



  if (render === "show" && mode === "ship") {
    let shipCity = shipment.shipper.address.city
    let shipState = shipment.shipper.address.state
    return (
      <>
        {shipCity}, {shipState}
      </>
    );
  }

  if (render === "show" && mode === "cons") {
    let consCity = shipment.consignee.address.city
    let constate = shipment.consignee.address.state
    return (
      <>
        {consCity}, {constate}
      </>
    );
  }
  else {
    return null;
  }
};

export default ShipperConsSummary;
