// ./APIs/SearchShipments.js

import ShipmentsDataNormalization from '../Helpers/ShipmentsDataNormalization'

const SearchShipments = {
  async Search(term, val) {

    if (term === "allnums") {
      let categoryFields = ['m.webquotecontrol', 'cs.pronum', 'cs.invoiceNum', 'm.usercontrol', 'm.shipord1', 'm.shipord2', 'm.shipord3', 'm.shipord4', 'm.shipord5', 'm.shipord6']
      let operator = "like"
      let searchVal = val
      let searchResults = await SearchShipments.SearchAllTheFields(categoryFields, operator, searchVal)

      return (searchResults)
    }

    if (term === "pudate") {

      let categoryFields = ['m.pudate', 'm.apptPUDate', 'm.puavaildt1', 'm.puavaildt2']
      let operator = "equals"
      let searchVal = val
      let searchResults = await SearchShipments.SearchAllTheFields(categoryFields, operator, searchVal)

      return (searchResults)
    }

    if (term === "deldate") {

      let categoryFields = ['m.estdeldate', 'm.requested_del_date', 'm.deldate', 'm.destavaildt1', 'm.destavaildt2', 'm.apptDelDate']
      let operator = "equals"
      let searchVal = val
      let searchResults = await SearchShipments.SearchAllTheFields(categoryFields, operator, searchVal)

      return (searchResults)
    }

    // if (term === "settledate") {
    //   let categoryFields = ['masterquotes_payments.checkDate']
    //   let operator = "equals"
    //   let searchVal = val
    //   let searchResults = await SearchShipments.SearchAllTheFields(categoryFields, operator, searchVal)

    //   return (searchResults)

    // }

    if (term === "pucity") {
      let categoryFields = ['m.shipcity']
      let operator = "equals"
      let searchVal = val
      let searchResults = await SearchShipments.SearchAllTheFields(categoryFields, operator, searchVal)

      return (searchResults)
    }

    if (term === "delcity") {
      let categoryFields = ['m.conscity']
      let operator = "equals"
      let searchVal = val
      let searchResults = await SearchShipments.SearchAllTheFields(categoryFields, operator, searchVal)

      return (searchResults)
    }

    if (term === "status") {
      let categoryFields = ['m.statusedi']
      let operator = "equals"
      let searchVal = val
      let searchResults = await SearchShipments.SearchAllTheFields(categoryFields, operator, searchVal)

      return (searchResults)
    }

  },

  async SearchAllTheFields(categoryFields, operator, searchVal) {
    // console.log('searchResult SearchAllTheFields', categoryFields, operator, searchVal)
    let carrier = localStorage.getItem("carrierId");
    let promises = []
    const categoryFieldsList = categoryFields.join(',')
    // console.log('searchResult category', category)
    let searchResultLimit = 100
    let limit = "limit=" + searchResultLimit
    let carrierID = "&carrier=" + carrier
    let include = "&include=all,gpsStatus,location,messages,logTypeNotice,documents"
    let translate = "&translate=v2"
    let category1 = "&category1=" + categoryFieldsList
    let operator1 = "&operator1=" + operator
    let search1 = "&search11=" + searchVal

    let url = "https://api.shippersedge.com/v4/shipment/?category2=m.statusedi&operator2=notlike&search21=pend&" + limit + carrierID + include + translate + category1 + operator1 + search1

    // console.log('searchResult, url', url)

    let fetchConfig = {
      headers: {
        'x-api-key': 'vdhy8wwjUJrwhxFpfuECTiAW'
      }
    }

    let promise = fetch(url, fetchConfig)
      .then(response => response.json())
      .then(data => {
        // console.log('searchResult data', data)
        return data;
      });

    promises.push(promise)


    // console.log('searchResult, promises', promises)
    let shipmentsFound = []
    await Promise.allSettled(promises).then(searchResponses => {
      // console.log('searchResult allSettled', searchResponses)
      searchResponses.forEach(searchResponse => {
        if (searchResponse.status === 'fulfilled' && typeof searchResponse.value !== 'undefined') {
          if (searchResponse.value?.data?.length) {
            shipmentsFound.push(...searchResponse.value.data)
          }
        } else {
          console.log('searchResult allSettled Error', searchResponse)
          // throw new Error(searchResponse.reason)
        }
      })
    })

    // De dup duplicate responses
    const ids = shipmentsFound.map(o => o.id)
    const shipmentsNoDuplicates = shipmentsFound.filter(({ id }, index) => !ids.includes(id, index + 1))

    // let shipmentsDeduped = uniqueArray(shipmentsFound)

    let shipments = ShipmentsDataNormalization(shipmentsNoDuplicates)

    // console.log('searchResult shipments', shipmentsFound, shipments)
    return shipments

  }


};

export default SearchShipments;

