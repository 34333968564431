import React from 'react'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const ShipperDocs = ({ shipment }) => {

    if (shipment.shipperDocuments && shipment.shipperDocuments.length) {
        return (
            <Row className="mb-3 mt-3">
                <Col>
                    <span className="bold">Shipping Documents:</span><br />
                    {shipment.shipperDocuments.map((document, index) => <a href={document.url} target="_blank" rel="noopener noreferrer " key={index}><span className='text-capitalize'>{document.name}</span></a>)}
                </Col>
            </Row>
        )
    } else { return null }
}

export default ShipperDocs