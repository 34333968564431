import React from 'react'

const BillOfLading = ({ shipment, render }) => {
    let showHide = 'hide'
    if (render === true) {
        showHide = 'show'
    }

    if (shipment && showHide === 'show') {
        return (
            <div>
                <a
                    href={
                        "https://www2.shippersedge.com/includes/cfm/static_other.cfm?d=bol&i=" +
                        shipment.id +
                        "&print&wsdl"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Open Bill of Lading In New Tab"
                >
                    Bill of Lading
                </a>
            </div>
        )
    } else { return null }
}

export default BillOfLading
