import React from 'react'

const RateAgreementDoc = ({ shipment, render }) => {
    if (shipment && render === 'show') {
        return (
            <div>
                <a
                    href={
                        "https://www2.shippersedge.com/includes/cfm/static_other.cfm?d=rateagreement&i=" +
                        shipment.id +
                        "&print&wsdl"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Open Rate Agreement In New Tab"
                >
                    Rate Agreement
                </a>
            </div>
        )
    } else { return null }
}

export default RateAgreementDoc
