import Authentication from "../APIs/Authentication";
import SendMessage from "../APIs/SendMessage";

const CheckAndSubmitChargesNotes = {
  CheckAndSubmitChargesNotes2(shipmentLog, chargesMessage, shipmentId) {
    // console.log("CheckAndSubmitChargesNotes", shipmentLog, chargesMessage);
    return new Promise(function (resolve, reject) {
      function check() {
        // console.log("CheckAndSubmitChargesNotes, check()");
        let checkPropsMessages = [];
        checkPropsMessages = shipmentLog.filter(
          (item) =>
            item.type === "Message" &&
            item?.entryDetails?.includes("ChargesNote: ")
        );
        // console.log("CheckAndSubmitChargesNotes", checkPropsMessages);
        if (checkPropsMessages.length) {
          // Filtering the last message in Charges Notes so that we do not resubmit it a second time.   Filtering some text here that used to accidentally submitted into the database due to a past technical issue.  These probabbly aren't frequent (if at all), but still filtering here just in case.   Neither of these should need to be filtered with changes made to the Carrier Portal as of March 2020 that prevent resubmittng data not changed and no longer submit "null" and other values.
          const checkPropsMessagesSorted = checkPropsMessages.sort(
            (a, b) => b.id - a.id
          );
          if (
            chargesMessage ===
            checkPropsMessagesSorted[0].entryDetails.slice(13) ||
            chargesMessage === "Reason for charges here" ||
            chargesMessage === null ||
            chargesMessage === "null" ||
            chargesMessage === "ChargesNote: null" ||
            chargesMessage === ""
          ) {
            // console.log("the billed charges note NOT a new Message");
            resolve(true);
          } else {
            // console.log("the billed charges note IS a new Message");
            //If the billed charges note is a new Message
            const prependedMsg = "ChargesNote: " + chargesMessage;
            const messageData = new FormData();
            messageData.append("shipmentId", shipmentId.toString());
            messageData.append("usercode", "Carrier");
            messageData.append(
              "contactEmail",
              Authentication.getEmailAddress()
            );
            messageData.append("message", prependedMsg);
            SendMessage.Send(messageData);
            resolve(true);
          }
        } else {
          if (
            chargesMessage === "Reason for charges here" ||
            chargesMessage === null ||
            chargesMessage === "null" ||
            chargesMessage === "ChargesNote: null" ||
            chargesMessage === ""
          ) {
            resolve(true);
          } else {
            const prependedMsg = "ChargesNote: " + chargesMessage;
            const messageData = new FormData();
            messageData.append("shipmentId", shipmentId.toString());
            messageData.append("usercode", "Carrier");
            messageData.append(
              "contactEmail",
              Authentication.getEmailAddress()
            );
            messageData.append("message", prependedMsg);
            SendMessage.Send(messageData);
            resolve(true);
          }
        }
      }
      check();
    });
  },
};

export default CheckAndSubmitChargesNotes;
