import React, { useState } from 'react'
import Items from './Items'

const HandlingUnits = ({ shipment }) => {
    // console.log('HandlingUnits')
    const [showAllHUs, setShowAllHUs] = useState(false)
    const [buttonTitle, setButtonTitle] = useState('Show')

    if (!shipment.handlingUnits) { return null }




    const showHideHandlingUnits = (event) => {
        event.stopPropagation()
        if (!showAllHUs) {
            setShowAllHUs(true)
            setButtonTitle('Hide')
        } else {
            setShowAllHUs(false)
            setButtonTitle('Show')
        }
    }



    if (shipment.handlingUnits) {
        return (
            <div>
                <button
                    className="mt-2 mb-2"
                    onClick={(event) => { showHideHandlingUnits(event) }}>
                    {buttonTitle} Shipment Details
                </button>
                {
                    showAllHUs ?
                        <div className="d-flex flex-row" >
                            <div data-cy="handling-units-container">
                                <h4>Handling Units</h4>
                                <table className="table-sm table-striped table-bordered table-hover">

                                    <thead>
                                        <tr>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">Packaging</th>
                                            <th scope="col">L</th>
                                            <th scope="col">W</th>
                                            <th scope="col">H</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {shipment.handlingUnits.map((handlingUnit, index) => {



                                            // console.log(item.description)
                                            return (<React.Fragment key={index}>
                                                <tr>
                                                    <td>{handlingUnit.quantity}</td>
                                                    <td>{handlingUnit.packaging}</td>
                                                    <td>{handlingUnit.dimensions.length}</td>
                                                    <td>{handlingUnit.dimensions.width}</td>
                                                    <td>{handlingUnit.dimensions.height}</td>
                                                </tr>
                                            </React.Fragment>)

                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <Items shipment={shipment} />

                        </div>

                        : null
                }
            </div>

        )
    } else { return null }
}

export default HandlingUnits